import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* onPOST(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);

    if (response.code == 1) {
      
      

      if(response?.data?.loan){
        yield put({ type: actionTypes.LOAN_SAVE, data: response.data.loan });
      }

      if(response?.data?.user){

        response.data.user.token = response.access_token;

        yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
        
      }

      if(response?.data?.finance){
        yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      }

      if(response?.data?.settings){
        yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onGET(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    if (response.code == 1) {
      yield put({ type: actionTypes.LOAN_SAVE, data: response.data.loan });
      // response.data.user.token = response.access_token;
      // yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      // yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      // yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings });
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}




function* watchOnPOST() {
  yield takeEvery(actionTypes.LOAN_POST, onPOST);
}



function* watchOnGET() {
  yield takeEvery(actionTypes.LOAN_GET, onGET);
}




export default function* loanSagas() {
  yield all([watchOnPOST(), watchOnGET()]);
}
