import React, {Component, useState} from "react";
import SiteWide from "../../utility/customComponents/SiteWide";
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import WrapUpDashboard from '../layout/WrapUpDashboard'

    

function News(){

    const site_settings = useSelector(allRemoteSettingsSelect);


    return (
        <WrapUpDashboard pageTitle={"News"}>


            <div className="row">
                
                {site_settings?.news?.map((item, index) => {

                    return <div className="col-xxl-12 col-xl-12">
                        <div className="card">
                                
                                <div className="card-header">
                                    <h4 className="card-title">{site_settings?.news?.[0]?.subject ?? ''}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="bance">
                                        <p>{parse(site_settings?.news?.[0]?.message ?? '')}</p>
                                    </div>
                                </div>
                        </div>
                    </div>

                })}

            </div>

             
        </WrapUpDashboard>
    );

}

export default News;