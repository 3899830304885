import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'


import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../../redux/selectors/index';
import SiteWide from "../../../utility/customComponents/SiteWide";
import TimeFormatter from "../../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {byToDaysDate, selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../../utility/Helper1';

import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import Balance from "../Fragments/Balance";
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../../redux/actions';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../../utility/customComponents/Ticker1";
import Ticker2 from "../../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer } from "react-icons/md";
import DashboardFooter1 from '../../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import MatchesLists from '../../layout/MatchesLists';

import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import useLanguage from '../../../utility/config/hooks/useLanguage';




const Search = () => {

    const usingLanguage = useLanguage()
    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);
    const [matchResultLoading, setMatchResultLoading] = useState(false)
    const [searchInput, setSearchInput] = useState('')

    const searchInputToUse = searchInput?.trim()
    const searchResult = searchInputToUse === ''
        ? [] : footballData?.fixtures?.filter(

              item => {

                return item?.teams_home_name?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.teams_away_name?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.fixture_id?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.league_name?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.league_country?.match(new RegExp(searchInputToUse, "i"))

            }
        )

    return ( <>
        <WrapUpFootballArea pageTitle={"Fixture betting"}>


            <div className="main__body__wrap left__right__space" style={{paddingLeft: 6, paddingRight: 6, marginTop: 15}}>

                <div className="live__heightlight mb__30 dark_bg_5" style={{marginBottom: 6}}>
                    
                    <div className="section__title" style={{border: 'none', marginTop: 20}}>
                        <div className="">


                            <input placeholder={usingLanguage?.['page']?.['search']?.['match_detail']} maxLength="120" onChange={(e) => setSearchInput(e.target.value)} value={searchInput} 
                            style={{borderRadius: 50, paddingLeft: 20, background: 'red', width: '100%', border: 'none', height: 50, fontSize: 25}} type={"text"} />
                            
                            <div className="dark_bg_2" style={{backgroundColor: 'red', width: 70, height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', 
                                borderRadius: 80, position: 'absolute', right: 20, top: 28}}>
                                <AiOutlineSearch className="unchanged-white-color-1" size={30} />
                            </div>

                        </div>
                    </div>


                    <div className="height__table">
                        <div className="tab-content" id="nav-tabContentheight">

                            <div className="tab-pane fade text-white show active" id="height1" role="tabpanel" aria-labelledby="nav-home-tabpre" tabindex="0">
                                <div className="main__table" style={{width: '100%'}}>

                                    
                                    <MatchesLists title={searchInput === ''?usingLanguage?.['page']?.['search']?.['match_detail']:null} data={searchResult} />



                                </div>
                            </div>


                        </div>
                    </div>
                </div>






            </div>



        </WrapUpFootballArea>

        </>
    );
};

export default Search;