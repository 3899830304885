export default {
  menu1: {
    deposit:        '存款',
    wallet:         '錢包',
    invitation:     '邀請',
    logout:         '登出',
    profile:        '個人資料',
    settings:       '設定',
    sign_in:        '登入',
    sign_up:        '註冊',
    referral:       '推薦',
    earn_salary:    '賺取薪水',
    top_up:         '充值',
    faq:            '常見問題',
    terms:          '條款與條件',
    privacy:        '隱私政策',
  },
  menu2: {
    home:           '首頁',
    search:         '搜索',
    today:          '今天',
    tomorrow:       '明天',
    my_league:      '我的聯賽',
    bonus_odd:      '額外賠率',
    live:           '直播',
    cancelled:      '已取消',
    results:        '結果',
  },
  headlines:{
    match_highlights:   '比賽亮點',
    general_info:       '一般資訊',
    trending_results:   '熱門結果',
    matches:            '比賽',
    live_account:       '實時賬戶',
    league:             '聯賽',
    nations:            '國家',
    betslip:            '投注單',
    slip:               '單據',
    my_bets:            '我的投注',
    fund:               '基金',
  },
  page: {
    sign_in:{
      page_title:               '登入您的賬戶',
      username:                 '用戶名',
      password:                 '密碼',
      remember_me:              '記住我',
      forgot_password:              '忘記密碼',
      dont_have_account:              '還沒有賬戶',
    },
    sign_up:{
      page_title:               '創建賬戶',
      username:                 '用戶名',
      password:                 '密碼',
      email:              '電子郵件',
      country:              '國家',
      select_country:              '選擇國家',
      phone:              '電話',
      account_security_pin:              '賬戶安全密碼',
      agree_to_the_terms_and_conditions: '我同意條款和條件',
    },
    index:{
      page_title:              '',
      match_detail:       '比賽詳情',
      trending_results:    '熱門結果',
    },
    search:{
      title:              '搜索結果',
      match_detail:       '比賽詳情',
      enter_team_name:    '輸入球隊名稱、投注ID或聯賽資訊',
    },

  },
  random_text: {
    betslip:{
      empty_slip_note: '您的投注單是空的。<br />請進行一個或多個選擇以進行投注。',
      multiple_bet_note: '多重投注是分開下注的，勝利/失敗是分別處理的',
      place_bet: '下注',
      total_odds: '總賠率',
      total_bet_amount: '總投注金額',
      possible_winnings: '可能的贏利',
    },
    empty_data: {
      no_data: '沒有可用的數據。',
    }
  }
};
