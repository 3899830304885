import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'


import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../../redux/selectors/index';
import SiteWide from "../../../utility/customComponents/SiteWide";
import TimeFormatter from "../../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {byTomorrowsDate, selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../../utility/Helper1';

import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import Balance from "../Fragments/Balance";
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../../redux/actions';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../../utility/customComponents/Ticker1";
import Ticker2 from "../../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import DashboardFooter1 from '../../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import ShowMatches from '../../layout/ShowMatches';

import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";




const Results = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);
    const [loadingData, setLoadingData] = useState(false)
    const [data, setData] = useState(null)

    const onLoadCancelledMatches = () => {

        setLoadingData(true)
        dispatch(footballActions.onLoadMatchResults("football/load-results", response => {

            setLoadingData(false)
            setData(footballData?.results)

        }))

    }

    useEffect(() => {
        onLoadCancelledMatches()
    }, [])


    return ( <>
        <WrapUpFootballArea pageTitle={"Results"}>
            <ShowMatches showLoading={loadingData} heroTitle="Results" data={data} />
        </WrapUpFootballArea>

        </>
    );
};

export default Results;