import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../redux/selectors/index';

  

// handle the private routes
function UnverifiedMailRoute({children}) {
  const users = useSelector(userSelect);


   if (!(((users?.emailVerification ?? 1) == 0) || ((users?.phoneVerification ?? 1) == 0))) {
    return <Navigate to={'/signin'} replace />;
  }


  return children ? children : <Outlet />;  

}

export default UnverifiedMailRoute;