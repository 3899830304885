import React, {useState, useEffect} from 'react';
import DonutChart from '../../../charts/donut'
import qrImg from '../../../../images/qr.svg'



import { Link } from 'react-router-dom';
import {depositActions} from '../../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../../utility/Helper1';
import LoadingIcon from "../../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiFillInfoCircle, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpDashboard from '../../../layout/WrapUpDashboard'
import {withdrawalActions} from '../../../../redux/actions';
import QRCode from "react-qr-code";
import PinAuth from "../../../../utility/customComponents/PinAuth";
import NoData from "../../../../utility/customComponents/NoData";
import Balance from "../../Fragments/Balance";
import WalletWrapper from './WalletWrapper'
import { BiEditAlt } from "react-icons/bi";




const PaymentAddresses = () => {

    const finance = useSelector(homeScreenStatSelect);
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [securityObj, setSecurityObj] = useState({})
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const op_se = site_settings.operational_settings
    const loadHistoryData = useSelector(allWithdrawalSelect);


    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [withdrawalIsActive, setWithdrawalIsActive]   = useState(false);
    const [selectedWallet, setSelectedWallet]   = useState(0);
    const [withdrawalHistoryLoading, setWithdrawalHistoryLoading]   = useState(false);

    const dispatch = useDispatch();

    const walletSources = [
                            {title: 'Main Balance', column: 'balance', id: 0, useThis: true}, 
                            {title: 'Referral Commission', column: 'commission', id: 1, useThis: Number(op_se?.useReferralBonus) === 1}, 
                            {title: 'Rebate Commission', column: 'rebateCommission', id: 2, balanceKey: 'rebateCommission', useThis: Number(op_se?.useRebateBonus) === 1},
                            {title: 'Deposit Bonus', column: 'depositBonus', id: 3, balanceKey: 'depositBonus', useThis: Number(op_se?.useDepositBonus) === 1},
                            {title: 'Salary Wallet', column: 'salary', id: 4, balanceKey: 'salary', useThis: Number(site_settings?.salary_settings?.useSalary) === 1}
                        ]

    const processWithdrawal = (e) => {
        
        e.preventDefault();

        if(withdrawalIsActive){ return; }

        if((userData?.usdttrc20wallet?.length ?? 0) < '5'){ 
            notify('Please bind your wallet details before withdrawal.');
            return false;
        }

        if((isNaN(amount)) || (amount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        setWithdrawalIsActive(true);

        let walletChosenColumn = walletSources?.[selectedWallet]?.['column']
        
        dispatch(withdrawalActions.onCreateWithdrawal({amount: amount, ...(securityObj ?? {}), currency: currencySelected?.type, walletAddress: ''}, `withdrawal/new/${walletChosenColumn}`, response => {

            notify(response.msg, response.code)
            if(response.code == 1){
                setAmount('');
            }

            setWithdrawalIsActive(false);

        }))
    }



    const updateCurrencySelected = (typeOfSelected) => {

        let selected = finance?.filter((ite, inde) => ite.type == typeOfSelected)?.[0] ?? []
        setCurrencySelected(selected)

    }

    const changeSelectedWallet = (value) => {
        setSelectedWallet(value)
    }



    


    const styles = {
        tableResponsive: {
          height: 590, // Default height for desktop
        },
    }

    if (window.innerWidth < 768) {
        styles.tableResponsive.height = 'auto'; // Set height to auto for mobile
      }



    const loadAllWithdrawal = () => {

        setWithdrawalHistoryLoading(true)
        dispatch(withdrawalActions.onLoadAllWithdrawals("withdrawal/load", response => {

            if(response.code == 1){
                setWithdrawalHistoryLoading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllWithdrawal()
    }, [])

    const availablePayments = userData?.bankdetails?.filter(e => e.value != null)

    return (<WalletWrapper pageTitle={"Payment Information"}>

        

        <div className="row">




            <div className="col-12">
                <div className="card">
                    <div className="card-header flexify">
                        <h4 className="card-title">Available Payment Addresses </h4>
                        <Link to={'/settings-payment-method'}><BiEditAlt className="white-color-1" size={20} /></Link>
                    </div>
                    <div className="card-body">

                        <div className="table-responsive wallet-address-list" ssstyle={{height: 590}}>
                            <table className="table table-striped responsive-table">
                                
                                {availablePayments?.length > 0 && <thead>
                                    <tr>
                                        <th>Coin Name</th>
                                        <th>Address</th>
                                        <th>QR</th>
                                    </tr>
                                </thead>}

                                <tbody>

                                    {availablePayments?.map((item, detail) => {
                                        if(item?.value?.trim() == ''){ return <></>}

                                        return <tr>
                                            <td>
                                                <div className="coin-name">
                                                    <img style={{width: 25}} className="qr-img" src={item.image} alt={item.name} width="40" />
                                                    <span>{item?.abbr}</span>
                                                </div>
                                            </td>
                                            <td>{item?.value}</td>
                                            <td>
                                                {item?.value !='' && <QRCode size={25} value={item?.value ?? ''} />}
                                            </td>
                                        </tr>
                                    })}
                                
                                </tbody>

                                {availablePayments?.length > 0 && <tfoot>
                                    <tr>
                                        <th>Coin Name</th>
                                        <th>Address</th>
                                        <th>QR</th>
                                    </tr>
                                </tfoot>}

                                {(availablePayments?.length ?? 0) == 0 && <div>

                                    <NoData title="You have set no withdrawal address" />

                                </div>}
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>

           


        </div>

    </WalletWrapper>);
};

export default PaymentAddresses;