import React, {useState, useRef, useCallback} from 'react';
import ProfileWrapper from './ProfileWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, base64ToBlob, base64ToFile, moneyStatus, usefulColors} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import {Camera} from "react-camera-pro";
import Webcam from "react-webcam";


const Settingsprofile = () => {

    const camera                                                = useRef(null);
    const dispatch                                              = useDispatch();
    const userData                                              = useSelector(userSelect);
    const [uploadingKYC, setUploadingKYC]                       = useState(false);
    const [postalCode, setPostalCode]                           = useState(userData?.postalCode ?? '');
    
    const [passport, setPassport]                               = useState(null);
    const [liveSelfie, setLiveSelfie]                               = useState(null);
    const [kycFileFront, setkycFileFront]                       = useState(null);
    const [kycFileBack, setkycFileBack]                         = useState(null);
    const [startScreenShot, setStartScreenShot]                         = useState(false);
    const AllMyKYC                  = userData?.kyc;

    const videoConstraints = {
      width: 120,
      height: 120,
      facingMode: "user",
    };
    const webcamRef = useRef(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setLiveSelfie(imageSrc);
    }, [webcamRef, setLiveSelfie]);


    const startTheScreenshotNow = () => {
        setStartScreenShot(el => !el)
    }

    const allDocUploads = [
                            
                            {

                                title:      'Valid Document Front',
                                desc:      'eg passport, driver license, voter\'s card, National ID',
                                state:      kycFileFront,
                                setState:   setkycFileFront,
                                id:         'kycFileFront',
                                defImage:   require('../../../images/profile/id-front.png'),
                                gridClass:  'col-xxl-6 col-xl-6 col-lg-6',
                            },
                            {

                                title:      'Valid Document Back',
                                desc:      'eg passport, driver license, voter\'s card, National ID',
                                state:      kycFileBack,
                                setState:   setkycFileBack,
                                id:         'kycFileBack',
                                defImage:   require('../../../images/profile/id-back.png'),
                                gridClass:  'col-xxl-6 col-xl-6 col-lg-6',
                            },
                            {
                                title:      'Passport',
                                desc:       'Passport Photograph',
                                state:      passport,
                                setState:   setPassport,
                                id:         'passport',
                                defImage:   require('../../../images/profile/passport.png'),
                                gridClass:  'col-xxl-12 col-xl-12 col-lg-12',
                            },
                            {

                                title:      'live Selfie',
                                desc:       'Live selfie',
                                state:      liveSelfie,
                                setState:   setLiveSelfie,
                                id:         'liveSelfie'
                            }
                        ]


    

    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        if(file.type == undefined){
            output.src = file;
        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
              URL.revokeObjectURL(output.src) // free memory
            }
        }
    }

    const whenImageSelected = (file, index) => {

            let fileUploading = allDocUploads?.[index]
            fileUploading.setState(file)
            instantUploadPreview(file, fileUploading.id)
    }


    const uploadKYC = (file) => {

        allDocUploads?.forEach((eachFileUploads, indexOfEach) => {
            if(!eachFileUploads){ notify('A valid document yet to be selected', 0); return; }
        })

        setUploadingKYC(true);

        let selfie = base64ToFile(liveSelfie);


        dispatch(authActions.onLogin("kyc/upload", {passport, kycFileFront, kycFileBack, liveSelfie: selfie}, response => {

                setUploadingKYC(false)
                notify(response.msg, response.code)
                if(response.code == 1){
                    // setSex(0)
                }
            }),
        )
    }

    return (
        <ProfileWrapper pageTitle={"KYC"}>
            
        {userData?.address !== '' && <div className="card-body padding-0">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <div className="card ">

                        <div className="card-header">
                            <h4 className="card-title">KYC</h4>
                        </div>
                        
                        {AllMyKYC?.length > 0 && <div className="card-body">
                            <div className={`alert alert-${usefulColors(AllMyKYC?.[0]?.status)}`}>
                                Your current KYC status is <span className="text-info">{moneyStatus()[AllMyKYC?.[0]?.status]}</span>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>}




        {userData?.address === '' && <div className="card-body padding-0">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <div className="card ">

                        <div className="card-header">
                            <h4 className="card-title">KYC Update Information</h4>
                        </div>
                        
                        <div className="card-body">
                            <div className={``}>
                                Please fully update your profile's personal information to proceed with KYC verification.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>}


        {userData?.address !== '' && <>

            <div className="card-body padding-0">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card ">

                            <div className="row g-3">
                                {allDocUploads?.slice(0, 2)?.map((item, index) => <div className={item?.gridClass}>
                                    <div className="card-body">

                                        <div className="row g-3">
                                            
                                            <div className="col-xxl-12">
                                                <div className="d-flex align-items-center">
                                                    
                                                    <img id={item?.id} src={item?.defImage} className="mr-3 ounded-circle mr-0 mr-sm-3" width="55" height="55" alt={"PREVIEW"} />


                                                    <div className="media-body ">

                                                        <h4 className="mb-0">{item.title}</h4>
                                                        <p className="mb-0">{item.desc}</p>
                                                        <p className="text-muted h6">Allowed file types - jpg, jpeg or png. Max file size is 4MB</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xxl-12">
                                                <div className="form-file">
                                                    <input onChange={(e) => whenImageSelected(e.target.files[0], index)} accept=".jpg, .gif, .png, .jpeg" type="file" className="form-file-input"
                                                        id={item?.id}/>
                                                    <label className="form-file-label" for={item?.id}>
                                                        <span className="form-file-text">Choose file...</span>
                                                        <span className="form-file-button">Browse</span>
                                                    </label>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    
                                    </div>

                                </div>)}

                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="card-body padding-0">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card ">

                            <div className="row g-3">
                                {allDocUploads?.slice(2, 3)?.map((item, index) => <div className={item?.gridClass}>
                                    <div className="card-body">

                                        <div className="row g-3">
                                            
                                            <div className="col-xxl-12">
                                                <div className="d-flex align-items-center">
                                                    
                                                    <img id={item?.id} src={item?.defImage} className="mr-3 ounded-circle mr-0 mr-sm-3" width="55" height="55" alt={"PREVIEW"} />


                                                    <div className="media-body ">

                                                        <h4 className="mb-0">{item.title}</h4>
                                                        <p className="mb-0">{item.desc}</p>
                                                        <p className="text-muted h6">Allowed file types - jpg, jpeg or png. Max file size is 4MB</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xxl-12">
                                                <div className="form-file">
                                                    <input onChange={(e) => whenImageSelected(e.target.files[0], 2)} accept=".jpg, .gif, .png, .jpeg" type="file" className="form-file-input"
                                                        id={item?.id}/>
                                                    <label className="form-file-label" for={item?.id}>
                                                        <span className="form-file-text">Choose file...</span>
                                                        <span className="form-file-button">Browse</span>
                                                    </label>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    
                                    </div>

                                </div>)}

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="card-body padding-0">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card ">

                            <div className={"col-xxl-12 col-xl-12 col-lg-12"}>
                                
                                <div className="card-body">

                                    <div className="row g-3">
                                        
                                        <div className="media-body ">

                                            <h4 className="mb-0">{"Selfie Capture"}</h4>

                                        </div>

                                        <div className="col-xxl-12">
                                            <div className="text-center">
                                                

                                                {startScreenShot && !liveSelfie &&  <Webcam
                                                    audio={false}
                                                    ref={webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={videoConstraints}
                                                    minScreenshotWidth={300}
                                                    minScreenshotHeight={300}
                                                    width={300}
                                                    height={300}
                                                />}


                                                {!startScreenShot && <img src={require('../../../images/profile/selfies.png')} style={{width: 300, height: 300}} className="m" alt={"PREVIEW"} />}
                                                {startScreenShot && liveSelfie && <img src={liveSelfie} style={{width: 300, height: 300}} className="m" alt={"PREVIEW"} />}


                                                <div className="form-file " style={{marginTop: 10}}>

                                                    {!startScreenShot && <span className="pointer" onClick={startTheScreenshotNow}>Start Selfie</span>}
                                                    {startScreenShot && !liveSelfie && <span className="pointer" onClick={capture}>Take shot</span>}
                                                    {startScreenShot && liveSelfie && <span style={{marginLeft: 5}} className="pointer" onClick={() => setLiveSelfie(null)}>Retake</span>}

                                                </div>

                                            </div>
                                        </div>



                                        
                                    </div>
                                
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="col-xxl-12">
                <button onClick={uploadKYC} type="submit" className="btn btn-success waves-effect">
                    
                    Save 

                    <LoadingIcon color={"#000"} active={uploadingKYC} />

                </button>
            </div>
            <br />
        </>}












        </ProfileWrapper>
    );
};

export default Settingsprofile;