import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { store, persistor } from './redux/store.js';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider maxSnack={10} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}

        style= {{ zIndex: 999999999999999999999999999999 }}
        className="Toastify__toast-container"
        TransitionProps={{ style: { zIndex: 999999999999999999999999 } }}

        >
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
