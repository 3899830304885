import React, {useState, useEffect} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'


import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import SiteWide from "../../../utility/customComponents/SiteWide";
import parse from 'html-react-parser';
import Helper1, {moneyStatus, notify, money, subStr, addUrlToPath} from '../../../utility/Helper1';

import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import Balance from "../Fragments/Balance";
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../../utility/customComponents/PinAuth";
import {authActions} from '../../../redux/actions';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../../utility/customComponents/Ticker1";
import Ticker2 from "../../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer } from "react-icons/md";
import DashboardFooter1 from '../../layout/dashboard-footer-1'
import BannerHeadMenu from './BannerHeadMenu'


    // main slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };




const Dashboard = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [loadingDownlines, setLoadingDownlines]     = useState(false)


    const avatarImg = ((userData?.image ?? null) != null)?addUrlToPath(userData?.image ?? ''):require('../../../images/profile/user.png')


    const textee = site_settings?.text_settings?.[0];

    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.user_dashboard_base_url+'/signup?ref='+ myRefCode

    const copyRefCode = (copythis) => {
        copy(copythis)
        notify('Copied', 1)
    }

    const sliders = site_settings?.slider_settings

    return (
        <WrapUpFootballArea hideEventPlace={"true"} pageTitle={"Banner"}>

            <BannerHeadMenu />

            <div className="tab-content" style={{paddingLeft: 25, paddingRight: 25}}>

                <Slider arrows={false} {...settings}>

                    {sliders?.map((item, index) => <img
                        key={index} // Make sure to include a unique key when mapping components
                        style={{
                          width: '100%',
                          height: '200px', // Set a fixed height
                          objectFit: 'cover', // Maintain aspect ratio and cover the container
                        }}
                        src={item?.image}
                        alt={`slider-image-${index}`} // Use a unique alt text
                      />)}

                </Slider>

            </div>
            

        </WrapUpFootballArea>
    );
};

export default Dashboard;