import React from 'react';
import WrapUpFootballArea from '../../../layout/WrapUpFootballArea'

import SubMenu from '../../../layout/SubMenu';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../../../redux/selectors/index';
import Balance from "../../Fragments/Balance";



const WalletWrapper = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const menu = [
            {
                title: 'My Address',
                url: '/payment-address',
                show: true
            },
            {
                title: 'Cash out',
                url: '/wallet',
                show: true

            },
            {
                title: 'History',
                url: '/withdrawal-history',
                show: true

            }
        ]

    return (
        

        <WrapUpFootballArea hideEventPlace={"true"} pageTitle={props?.pageTitle}>

            <SubMenu data={menu} />

            <div className="tb-content" style={{paddingLeft: 6, paddingRight: 6}}>

                <div className="col-xxl-12 col-xl-12 col-lg-12">
                
                    <Balance />
                    <br />
                </div>

                {props.children}

            </div>
            

        </WrapUpFootballArea>


    );
};

export default WalletWrapper;