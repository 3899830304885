

export default {
  menu1: {
    deposit:        'Deposit',
    wallet:         'Wallet',
    invitation:     'Invitation',
    logout:         'logout',
    profile:        'Profile',
    settings:       'Settings',
    sign_in:        'Sign in',
    sign_up:        'Sign up',
    referral:       'Referral',
    earn_salary:    'Earn salary',
    top_up:         'Top up',
    faq:            'Faq',
    terms:          'Terms & Conditions',
    privacy:        'Privacy Policy',
  },
  menu2: {
    home:           'Home',
    search:         'Search',
    today:          'Today',
    tomorrow:       'Tomorrow',
    my_league:      'My league',
    bonus_odd:      'Bonus odd',
    live:           'Live',
    cancelled:      'Cancelled',
    results:        'Results',
  },
  headlines:{
    match_highlights:   'Match highlights',
    general_info:       'General info',
    trending_results:   'Trending results',
    matches:            'Matches',
    live_account:       'Live account',
    league:             'League',
    nations:            'Nations',
    betslip:            'Betslip',
    slip:               'Slip',
    my_bets:            'My bets',
    fund:               'Fund',
  },
  page: {
    sign_in:{
      page_title:               'Sign in to account',
      username:                 'Username',
      password:                 'Password',
      remember_me:              'Remember me',
      forgot_password:              'Forgot Password',
      dont_have_account:              'Don\'t have an account',
    },
    sign_up:{
      page_title:               'Create an account',
      username:                 'Username',
      password:                 'Password',
      email:              'Email',
      country:              'Country',
      select_country:              'Select country',
      phone:              'Phone',
      account_security_pin:              'Account Security Pin',
      agree_to_the_terms_and_conditions: 'I agree to the terms and conditions',
    },
    index:{
      page_title:              '',
      match_detail:       'Match detail',
      trending_results:    'Trending Results',
    },
    search:{
      title:              'Search result',
      match_detail:       'Match detail',
      enter_team_name:    'Enter team name, bet ID or league info',
    },

  },
  random_text: {
    betslip:{
      empty_slip_note: 'Your betslip is empty.<br />Please make one or more selections in order to place a bet.',
      multiple_bet_note: 'Multiple bets are place separately and wins/loses are processed individually',
      place_bet: 'Place Bet',
      total_odds: 'Total odds',
      total_bet_amount: 'Total bet amount',
      possible_winnings: 'Possible winnings',
    },
    empty_data: {
      no_data: 'No data is available.',
    }
  }


};