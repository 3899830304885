import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import BuyModal from '../../../element/buy-modal'

import {depositActions} from '../../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allDepositSelect } from '../../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../../utility/Helper1';
import LoadingIcon from "../../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiOutlineReload, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import NoData from "../../../../utility/customComponents/NoData";
import FundWrapper from './FundWrapper'



const FundHistory = () => {
    const [show, setShow] = useState(false);


    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);

    const [deleting, setDeleting]   = useState(false);
    const [loading, setloading]   = useState(false);
    const [deletingInv, setDeletingInv]   = useState(false);
    

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const allDepositData = useSelector(allDepositSelect);
    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [reloadingPendingPayments, setReloadingPendingPayments]   = useState(false);
        
    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }

    const updateCurrencySelected = (typeOfSelected) => {

        let selected = finance?.filter((ite, inde) => ite.type == typeOfSelected)?.[0] ?? []
        setCurrencySelected(selected)

    }


    useEffect(() => {
        getPendingPayments()
    }, [])

        


    const getPendingPayments = () => {

        setReloadingPendingPayments(true)
        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            // notify(response.msg, response.code)
            setReloadingPendingPayments(false)

        }))

    }


     const uploadSelectedPayment = (file) => {
        
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }




    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);
    const getConfirmedPayment = allDepositData?.filter((item, index) => item.status >= 2);


    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        setloading(true)


        dispatch(depositActions.onCreateDeposit({amount, currency: currencySelected.type}, "deposit/new", response => {
            
            if(response?.code === 1){
                setAmount('')
            }else{
                
            }

            notify(response.msg, response.code)

            setloading(false)
            
        }))
    }


    const deleteInv = (txn_id) => {

        if(getPendingPayment?.[0]?.status != 0){
            notify('Cannot be deleted as payment is being processed.', 0)
            return;
        }

        setDeletingInv(true)

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setDeletingInv(false)
        }))

    }



    const fundUiLength = getPendingPayment?.length > 0 ? 6:12




    // for the pending payment part
    let displayableAmount = getPendingPayment?.[0]?.useCryptoValue == 1?`${getPendingPayment?.[0]?.amountEnforcedToPay} ${getPendingPayment?.[0]?.abbr}`:`${getPendingPayment?.[0]?.amountEnforcedToPay} USD`
    let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
    let perfectWalletobj;
    let eachParam;


    if((getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && (getPendingPayment?.[0]?.autoConfirm == 2)){
        let pmAddress = JSON.parse(getPendingPayment?.[0]?.address);
        eachParam = Object.keys(pmAddress.param);
        perfectWalletobj = pmAddress
    }   

    const copyThis = (copythis) => {
        copy(copythis)
        notify('Copied', 3)
    }

    return (
        <FundWrapper pageTitle={"Credit account"}>



            <div className="row">

                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Fund History</h4>
                        </div>
                        <div className="card-body">

                            {(getConfirmedPayment?.length ?? 0) !== 0 && <div className="table-responsive">
                                
                                <table className="table table-striped responsive-table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Coin Name</th>
                                            <th>Time</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        

                                        {getConfirmedPayment?.map((item, index) => {

                                            let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                                        return <tr>
                                                    <td><span className="danger-arrow"><i className="icofont-arrow-down"></i>
                                                        Fund</span>
                                                    </td>
                                                    <td className="coin-name">
                                                        <img style={{width: 30, height: 30}} src={image?.[0]?.image} alt="img" /> {image?.[0]?.name}
                                                    </td>
                                                    <td>
                                                        {item?.timestamp}
                                                    </td>
                                                    <td className="text-danger">
                                                        {money(item?.amountEnforcedToPay, item?.currency)}
                                                    </td>
                                                    <td>
                                                        <span className={`text-${usefulColors(item?.status)}`}>{moneyStatus()[item?.status]}</span>
                                                    </td>
                                        </tr>})}
                                        
                                        {(getConfirmedPayment?.length ?? 0) == 0 && loading && <tr>
                                            <td colSpan={5} className="text-center">
                                                <LoadingIcon color={"green"} active={loading} />
                                            </td>
                                        </tr>}
                                
                                    </tbody>
                                </table>

                            </div>}

                            {(getConfirmedPayment?.length ?? 0) == 0 && !loading && <NoData title="No record available" />}
                        
                        </div>
                    </div>
                </div>

            </div>
                


        </FundWrapper>
    );
};

export default FundHistory;