import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {useNavigate} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import AuthHolder from "./AuthHolder";
import Countdown from 'react-countdown';
import ReCAPTCHA from "../../../utility/customComponents/ReCAPTCHA";

import { AiFillCloseCircle, AiOutlineUser, AiOutlineMail, AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineGlobal } from "react-icons/ai";


const Reset = () => {

    const [emailInput, setEmailInput] = useState('') 
    const [newPassword, setNewPassword] = useState('') 
    const [confirmNewPassword, setConfirmNewPassword] = useState('') 

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [emailCode, setEmailCode] = useState('')
    const [requestOTPRetObj, setRequestOTPRetObj] = useState({})
    const [uiLevel, setUiLevel] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    
    const [loadingText, setLoadingText]         = useState("");
    const [loading, changeLoading]         = useState(false);

    const navigate                                       = useNavigate();
    const [captcha, setCaptcha]                       = useState(null);

    const captchaHasChanged = (data) => {
        setCaptcha(data)
    }

    const requestOTP = async (e) => {
        
        e.preventDefault();

        if(loading){ return; }

        setFullPageLoading(true);
        changeLoading(true)

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password_pin", {email: emailInput});

        if(getPost?.code == 1){
            setUiLevel(1)
            setRequestOTPRetObj(getPost?.data)
        }

        changeLoading(false)
        notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);

    }


   

    const changePassword = async (e) => {

        e.preventDefault();

        if(loading){ return; }
        
        if(!captcha && (Number(site_settings?.site_settings?.use_google_reCaptcha) == 1)){ notify('Invalid captcha response', 0); return; }

        if((newPassword != confirmNewPassword) || (newPassword == '')){
            notify("Passwords must match", 0)
            return;
        }

        changeLoading(true)
        setLoadingText("Please wait...")
        
        setFullPageLoading(true);

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password", {captcha, email: emailInput, password:newPassword, passwordResetCode:emailCode});

        if(getPost?.code == 1){
            setUiLevel(0)
            notify("Password reset successful. You are being redirected to login page", 1)
            
            setTimeout(() => {
                navigate('/signin', { replace: true });
            }, 3000)

        }else{
            notify(getPost?.msg ?? 'error', getPost?.code ?? 0)
        }

        changeLoading(false)
        setLoadingText("")

        // notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);


    }

    
    const handleReload = () => {
        window.location.reload();
    }

    return (
        <AuthHolder title={uiLevel === 0?'Reset Password':'Update your password'}>
            

                    <form name="myform" className="signin_validate row g-3">
                        
                        <h2 className="text-center">Reset Password</h2>

                        <div onClick={() => navigate(-1)} className={"pointer"} style={{position: 'absolute', top: -34, right: -370}}>
                            <AiFillCloseCircle className="unchanged-white-color-1" size={30} />
                        </div>

                        {uiLevel == 0 && <>
                            

                            <label className="single-input-wrap">
                                <label className=" white-color-1 hide-label" for="email-id">Email ID*</label>
                                <AiOutlineMail className="auth-icon-color" size={20} />
                                <input id="email-id" onChange = {e => setEmailInput(e.target.value) } required type="email" className="auth-input form-control" placeholder="hello@example.com"/>
                            </label>

                            <div className="col-12"></div>

                            <div className="text-center">
                                <button onClick={requestOTP} type="submit" className="btn btn-primary btn-block">
                                    Reset Password

                                    <LoadingIcon color={"#fff"} active={loading} />

                                </button>
                            </div>


                        </>
                        }



                        {uiLevel == 1 && <>
                            
                            
                            <label className="single-input-wrap">
                                <label className=" white-color-1 hide-label" for="new-password-1">New Password*</label>
                                <AiOutlineLock className="auth-icon-color" size={20} />
                                <input id="new-password-1" onChange={e => setNewPassword(e.target.value) } required type="password" className="auth-input form-control" placeholder="New Password"/>
                            </label>


                            <label className="single-input-wrap">
                                <label className=" white-color-1 hide-label" for="new-password-2">Confirm Password*</label>
                                <AiOutlineLock className="auth-icon-color" size={20} />
                                <input id="new-password-2" onChange={e => setConfirmNewPassword(e.target.value) } required type="password" className="auth-input form-control" placeholder="Confirm Password"/>
                            </label>


                            <label className="single-input-wrap">
                                <label className=" white-color-1 hide-label" for="new-password-3">Emailed Token*</label>
                                <AiOutlineLock className="auth-icon-color" size={20} />
                                <input id="new-password-3" onChange={e => setEmailCode(e.target.value) } required type="password" className="auth-input form-control" placeholder="Emailed Token"/>
                            </label>



                            <div className="col-12">

                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        date={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <span className="design-text-for-click">Action Expired. <span style={{cursor: 'pointer', color: '#7589ea'}} onClick={handleReload}>Refresh</span> to try again</span>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <span>Expires in {minutes}:{seconds}</span>;
                                          }
                                        }}
                                    />


                            </div>


                            <div className="col-12">
                                <ReCAPTCHA 
                                    onChange={captchaHasChanged}
                                />
                            </div>

                            <div className="text-center">
                                <button onClick={changePassword} type="submit" className="btn btn-primary btn-block">
                                    Change Password

                                    <LoadingIcon color={"#fff"} active={loading} />

                                </button>
                            </div>


                        </>
                        }




                    </form>


        </AuthHolder>
    );
};

export default Reset;