import * as actionTypes from "./actionTypes";

export const onManageLocalSettings = (param, callback = () => {}) => {
  return {
    param,
    type: actionTypes.ACTION_LOCAL_SITE_SETTINGS,
    callback,
  };
};

