import { useState } from 'react';
import { allLocalSettingsSelect } from '../../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {allLanguages} from '../../Helper1';
import Languages from '../lang';

const useLanguage = (key, initialValue) => {

  const local_settings                                 = useSelector(allLocalSettingsSelect);


  // State to track the current value
  // const [value, setValue] = useState(initial);

  const currentLanguageObj = local_settings?.activeLanguageObject ?? allLanguages()?.[0]

  return Languages?.[currentLanguageObj?.country]

};

export default useLanguage;