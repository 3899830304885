import React, {Component, useState} from "react";
import SiteWide from "../../../utility/customComponents/SiteWide";
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import Faqo from "react-faq-component";

    

function WrapUpPolicies(props){

    const site_settings = useSelector(allRemoteSettingsSelect);

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "#fff",
        rowTitleColor: "#fff",
        rowContentColor: '#fff',
        arrowColor: "#fff",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
    };

    const data = {    
        title: "",
        rows: site_settings?.faq?.map(function(item, index){ return {title: item.question, content: item.answer} })

    };


    return (
        <WrapUpDashboard hideMobileFooter={true} pageTitle={props?.pageTitle}>


            <div className="tb-content" style={{paddingLeft: 6, paddingRight: 6, marginTop: 165}}>

                        <div className="card dark_bg_2">
                                
                                <div className="card-header">
                                    <h4 className="card-title">{props?.pageTitle}</h4>
                                </div>
                                <div style={{minHeight: '100vh'}} className="card-body dark_text_4">
                                    {props?.children}                                    
                                </div>
                        </div>
            </div>




             
        </WrapUpDashboard>
    );

}

export default WrapUpPolicies;