import React, {useState, useEffect} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'


import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import SiteWide from "../../../utility/customComponents/SiteWide";
import parse from 'html-react-parser';
import Helper1, {moneyStatus, notify, money, subStr, addUrlToPath} from '../../../utility/Helper1';

import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import BannerHeadMenu from './BannerHeadMenu'
import { AiOutlineCopy } from "react-icons/ai";




const Dashboard = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [loadingDownlines, setLoadingDownlines]     = useState(false)


    const avatarImg = ((userData?.image ?? null) != null)?addUrlToPath(userData?.image ?? ''):require('../../../images/profile/user.png')


    const textee = site_settings?.text_settings?.[0];

    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.user_dashboard_base_url+'/signup?ref='+ myRefCode

    const copyCode = (copythis) => {
        copy(copythis)
        notify('Copied', 1)
    }



    const promotions = site_settings?.promotional_settings
    let theUserRef = userData?.myrefid ?? '';
    let ref = site_settings?.site_settings?.homepage_url+'/'+(theUserRef!=''?'signup?ref='+theUserRef:'');

    
    const link = (image) => {
        
        return '<a href="' + ref + '" ><img src="' + image + '" /></a>';
    }


    return (
        <WrapUpFootballArea pageTitle={"Promotion"}>

            <BannerHeadMenu />

            <div className="tab-content" id="pills-tabContentworldsss100" style={{padding: 10}}>

                {promotions?.map((item, index) => {

                            return <div className="" style={{marginBottom: 120}}>

                                <div className="flexify" style={{}}>
                                    <div>
                                        <h2 className="white-color-1">
                                            {item?.title}
                                        </h2>

                                        <p className="dark_text_4" style={{paddingBottom: 20}}>
                                            {item?.desc}
                                        </p>
                                    </div>
                                    <div className="pointer" onClick={() => copyCode(link(item?.image))}>
                                        <AiOutlineCopy size={20} color="#fff"/>
                                    </div>
                                </div>


                                <div className="world__thumb__wrap">
                                    <div className="thumb">
                                        <img src={item?.image} alt="virtuals" />
                                    </div>
                                </div>

                                <div className="dark_bg_2 dark_text_4" style={{marginTop: 20, padding: 10}}>
                                    {link(item?.image)}
                                </div>

                            </div>

                        })}

            </div>


        </WrapUpFootballArea>
    );
};

export default Dashboard;