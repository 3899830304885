import React, {useState, useEffect} from 'react';
import MultiStep from 'react-multistep'
import { ViewFlow, Step } from 'react-view-flow'



import { Link } from 'react-router-dom';
import {depositActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiFillInfoCircle, AiOutlineCheck, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import {tradeActions} from '../../../redux/actions';
import QRCode from "react-qr-code";
import PinAuth from "../../../utility/customComponents/PinAuth";
import NoData from "../../../utility/customComponents/NoData";
import TradeSubMenu from "./Trade-sub-menu";
import  "./pricing-style/pricing-table.css";
import { useNavigate } from "react-router-dom";




const Wallet = () => {

    const navigate = useNavigate();
    const defaultTenure = 0
    const finance = useSelector(homeScreenStatSelect);
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const op_se = site_settings.operational_settings
    const loadHistoryData = useSelector(allWithdrawalSelect);


    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [withdrawalIsActive, setWithdrawalIsActive]   = useState(false);
    const [selectedWallet, setSelectedWallet]   = useState(0);
    const [withdrawalHistoryLoading, setWithdrawalHistoryLoading]   = useState(false);
    const [tenure, setTenure]   = useState(defaultTenure);
    const [holdDuration, setHoldDuration]   = useState('');


    const filterPlanMother = site_settings?.plans?.filter(item => item.mother.type == (currencySelected?.type ?? finance?.[0]?.type))

    const [selectedPlan, setSelectedPlan]   = useState(filterPlanMother?.[0]?.childs?.filter(item => Number(item.tenure) === Number(tenure))?.[0]);
    const [investingIsActive, setInvestingIsActive]   = useState(false);
    const selectedTenurePlans = filterPlanMother?.[0]?.childs?.filter(item => Number(item.tenure) === Number(tenure))



    const dispatch = useDispatch();

    const updateCurrencySelected = (typeOfSelected) => {

        let selected = finance?.filter((ite, inde) => ite.type == typeOfSelected)?.[0] ?? []
        setCurrencySelected(selected)

    }




    



    const updateTenure = (shortOrLong) => {
        setTenure(shortOrLong)
    }


    


    const stepOne = () => {

        return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Select Currency</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}>

                                <select style={{borderWidth: 1, borderColor: '#33c3f0'}} onChange={e => updateCurrencySelected(e.target.value)} className="form-select">
                                    {finance?.map((item, index) => <>
                                        <option key={`ghgvhbj${index}`} value={item.type}>{item?.abbr}</option>
                                    </>)}
                                </select>

                            </div>


                        </div>

                    </div>
                </div>
    }


    const stepTwo = () => {

        const theTenures = [
                {
                    title: 'SHORT TERM',
                    subTitle: 'Market Position'
                },
                {
                    title: 'LONG TERM',
                    subTitle: 'Asset Holdings'
                }
            ]

            return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Select Tenure</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            {theTenures?.map((item, index) => <div onClick={() => updateTenure(index)} className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6`}>
                                <div className={`flexify pointer chart-stat ${tenure == index?'active-tenure':''}`}>
                                    <div>
                                        <h4>{item?.title}</h4>
                                        <p className="mb-1">{item?.subTitle}</p>
                                    </div>
                                    {tenure == index && <AiOutlineCheck size={20} />}
                                </div>
                            </div>)}

                        </div>

                    </div>

                    

                </div>
    }


    const updateSelectedPlan = (item) => {
        setSelectedPlan(item)
    }

    const stepThree = () => {

        return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Packages</h4>
                    </div>
                    <div className="card-body">

                        <section className="row pricing-table">

                            {selectedTenurePlans?.map((item, index) => {

                                let roi = item?.roi?.split(',')
                                

                                return <div key={`ghj${index}`} onClick={() => updateSelectedPlan(item)} className={`col-xxl-4 col-xl-4 col-lg-4 col-md-6 cardio ${item.type==selectedPlan.type?'active-cardio':''}`}>
                                    <h6 className="type text-whie">{item?.name?.toUpperCase()}</h6>
                                    <div className="price text-black">{roi?.[0]}<sup>%</sup></div>
                                    <p className="text-dark">{item.desc}</p>
                                    <h5 className="plan text-whit">Plan</h5>
                                    <ul className="details text-center">
                                      <li className="text-white">Minimum<br /><strong>{money(item?.minimumParticipation, currencySelected.type)}</strong></li>
                                      <li className="text-white">Maximum<br /><strong>{money(item?.maximumParticipation, currencySelected.type)}</strong></li>
                                    </ul>
                                    <div className="buy-button">

                                      <h3 className="btn" style={{display: 'flex', alignItems: 'center'}}>

                                        <span>{item.type==selectedPlan.type?'Selected':'Subscribe'}</span>

                                        {item.type==selectedPlan.type && <AiOutlineCheck size={20} />}

                                      </h3>
                                    </div>
                                </div>})}

                            

                        </section> 


                    </div>
                </div>
    }



    const submitInvestmentRequest = (e) => {

        e.preventDefault();

        if(investingIsActive){ return; }


        if(isNaN(amount) || (amount <= 0)){
            notify('Enter amount', 0);
            return;
        }

        if((isNaN(holdDuration) || (holdDuration <= 0)) && (tenure == 1)){
            notify('Enter valid long term duration', 0);
            return;
        }


        const payLoad = {amount, currency:selectedPlan?.type, holdDuration};

        
        setInvestingIsActive(true)

        dispatch(tradeActions.onPOSTTheTrades(payLoad, "trade/new", response => {
                setInvestingIsActive(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    navigate('/trade-online')
                }
                
            }),
        )

    }

    const stepFour = () => {

        return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Commit</h4>
                    </div>
                    <div className="card-body">

                        <form onSubmit = {submitInvestmentRequest} className="currency_validate trade-form row g-3">



                            <h4>{selectedPlan.investment_note_headline}</h4>
                            <p className="mb-0">{selectedPlan.investment_note_body}</p>


                            <div className="col-12">
                                <label className="form-label">Enter amount</label>
                                <div className="input-group">
                                    
                                    <input autoComplete="new-password" type="text" onChange={(e) => setAmount(e.target.value)} value={amount} name="currency_amount" className="form-control"
                                        placeholder="0.00" />

                                </div>
                            </div>


                            {tenure == 1 && <div className="col-12">
                                <label className="form-label">Long term assets duration</label>
                                <p className="">For how long in days would you like to hold this trade?</p>
                                <div className="input-group">
                                    
                                    <input autoComplete="new-password" type="text" onChange={(e) => setHoldDuration(e.target.value)} value={holdDuration} className="form-control"
                                        placeholder="0" />

                                </div>
                            </div>}


                            <button disabled={investingIsActive} type="submit" name="submit" className="btn btn-success btn-block">
                                Submit
                                <LoadingIcon color={"#fff"} active={investingIsActive} />
                            </button>

                        </form>

                    </div>
                </div>
    }





    const steps = [
              {title: 'Currency', component: stepOne()},
              {title: 'Tenure', component: stepTwo()},
              {title: 'Package', component: stepThree()},
              {title: 'Commit', component: stepFour()},

            ];




    return (<WrapUpDashboard pageTitle={"New Trade"}>

        <div className="card-header">
            <TradeSubMenu pageTitle={'New Trade'}/>
        </div>


        <div className="row">

           



            <div className="col-xxl-12 col-xl-12 col-lg-12">

                <MultiStep showNavigation={true} steps={steps} activeStep={0} prevButton={{title: 'Back', style: { background: 'transparent', color: '#33c3f0', borderWidth: 1, borderColor: '#33c3f0' }}} nextButton={{title: 'Next', style: { background: 'transparent', color: '#33c3f0', borderWidth: 1, borderColor: '#33c3f0', marginLeft: 10 }}} />

                <br/><br/>

            </div>


        </div>

    </WrapUpDashboard>);
};

export default Wallet;