import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* onManageLocalSettings(action) {
  try {
    
      yield put({ type: actionTypes.SAVE_LOCAL_SITE_SETTINGS, data: action.param }); 

    return action.callback?.(true);
  } catch (error) {

    return action.callback?.(false);
  }
}




function* watchOnManageLocalSettings() {
  yield takeEvery(actionTypes.ACTION_LOCAL_SITE_SETTINGS, onManageLocalSettings);
}



export default function* settingsSagas() {
  yield all([watchOnManageLocalSettings()]);
}
