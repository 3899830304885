export default {
  menu1: {
    deposit:        'Depozito',
    wallet:         'Cüzdan',
    invitation:     'Davetiye',
    logout:         'Çıkış yap',
    profile:        'Profil',
    settings:       'Ayarlar',
    sign_in:        'Giriş yap',
    sign_up:        'Kayıt ol',
    referral:       'Referans',
    earn_salary:    'Maaş kazan',
    top_up:         'Yükleme yap',
    faq:            'SSS',
    terms:          'Şartlar ve Koşullar',
    privacy:        'Gizlilik Politikası',
  },
  menu2: {
    home:           'Ana Sayfa',
    search:         'Ara',
    today:          'Bugün',
    tomorrow:       'Yarın',
    my_league:      'Benim Ligi',
    bonus_odd:      'Bonus Oran',
    live:           'Canlı',
    cancelled:      'İptal Edildi',
    results:        'Sonuçlar',
  },
  headlines:{
    match_highlights:   'Maç Özetleri',
    general_info:       'Genel Bilgi',
    trending_results:   'Trend Sonuçlar',
    matches:            'Maçlar',
    live_account:       'Canlı Hesap',
    league:             'Lig',
    nations:            'Uluslar',
    betslip:            'Bahis Kuponu',
    slip:               'Fiş',
    my_bets:            'Bahislerim',
    fund:               'Fon',
  },
  page: {
    sign_in:{
      page_title:               'Hesaba Giriş Yap',
      username:                 'Kullanıcı Adı',
      password:                 'Şifre',
      remember_me:              'Beni Hatırla',
      forgot_password:          'Şifremi Unuttum',
      dont_have_account:        'Hesabın yok mu',
    },
    sign_up:{
      page_title:               'Hesap Oluştur',
      username:                 'Kullanıcı Adı',
      password:                 'Şifre',
      email:                    'E-posta',
      country:                  'Ülke',
      select_country:           'Ülke Seç',
      phone:                    'Telefon',
      account_security_pin:     'Hesap Güvenlik PIN\'i',
      agree_to_the_terms_and_conditions: 'Şartlar ve Koşulları kabul ediyorum',
    },
    index:{
      page_title:              '',
      match_detail:       'Maç Detayı',
      trending_results:    'Trend Sonuçları',
    },
    search:{
      title:              'Arama Sonucu',
      match_detail:       'Maç Detayı',
      enter_team_name:    'Takım adı, bahis ID\'si veya lig bilgisi girin',
    },
  },
  random_text: {
    betslip:{
      empty_slip_note: 'Bahis kuponunuz boş.<br />Bir bahis yapmak için lütfen bir veya daha fazla seçim yapın.',
      multiple_bet_note: 'Çoklu bahisler ayrı ayrı yerleştirilir ve kazançlar/zararlar bireysel olarak işlenir',
      place_bet: 'Bahis Yap',
      total_odds: 'Toplam Oranlar',
      total_bet_amount: 'Toplam Bahis Miktarı',
      possible_winnings: 'Olası Kazançlar',
    },
    empty_data: {
      no_data: 'Veri mevcut değil.',
    }
  }
};
