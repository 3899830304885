import React, {useState, useEffect} from 'react';

import { Link } from 'react-router-dom';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../redux/selectors/index';
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import WrapUpDashboard from '../layout/WrapUpDashboard'

import { useSelector, useDispatch } from 'react-redux';
import {footballActions} from '../../redux/actions';
import Helper1, {byNextDate, subStr, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../../utility/Helper1';
import { FaRegClock } from "react-icons/fa";
import NoData from "../../utility/customComponents/NoData";
import { Modal, Button } from 'react-bootstrap'


const Settings = () => {

        const [pageLoading, setPageLoading] = useState(true);
        const [notiData, setNotiData] = useState([]);
        const [newPageNumber, setNewPageNumber] = useState(1);
        const [loadingMore, setLoadingMore] = useState(false);
        const [show, setShow] = useState(false);
        const [focusedActivity, setFocusedActivity] = useState({});

        const dispatch = useDispatch();


        const handleClose = () => setShow(false);

        const handleShow = () => setShow(true);


        const getPageData = (pageNumber = 1, callback = () => {}) => {

            setPageLoading(true)

            dispatch(footballActions.onGetRecents(`user/notifications?page=${pageNumber}`, response => {

                setPageLoading(false)

                let newData;

                if(pageNumber > (notiData?.currentPage ?? 1)){
                    newData = {...response?.data, data: [...notiData?.data, ...response?.data?.data]}
                }else{
                    newData = response.data
                }

                callback?.()

                setNotiData({...newData})

            }))
        }

        useEffect(() => {
            getPageData()
        }, [])


        const showNext = () => {
            let newPage = newPageNumber + 1
            setNewPageNumber(newPage)
            setLoadingMore(true)

            getPageData(newPage, () => {
                setLoadingMore(false)
            })
        }


        const renderLoadMoreButton = () => {
            if (notiData?.currentPage < notiData?.totalPages) {
              return (
                <div className="card">
                        
                    <div className="card-body">
                        <button disabled={loadingMore} className="btn btn-info" onClick={showNext}>
                            
                            Load More

                            <LoadingIcon color={"#fff"} active={loadingMore} />

                        </button>
                    </div>

                </div>
              );
            }
        };


    const bringActivityModal = (item) => {
        setFocusedActivity(item)
        handleShow()
    }


    return <WrapUpDashboard pageTitle={"Account Activities"}>

            <div className="row">
                <div className="col-xxl-12">
                    
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Account Activities</h4>

                            <small>Recent activities on your account.</small>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Timestamp</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {notiData?.data?.map((item, index) => {

                                        return <tr>
                                            <td>
                                                <p className="pointer" onClick={() => bringActivityModal(item)}>
                                                    {subStr(item.message, 45)}
                                                </p>
                                            </td>
                                            <td>
                                                <Link to={"#"}>{formatDate(new Date(item.timestamp * 1000))} {formatTime(new Date(item.timestamp * 1000))}</Link>
                                            </td>
                                        </tr>

                                    })}
                                    
                                </tbody>
                            </table>
                        </div>

                        {(notiData?.data?.length ?? 0) == 0 && <NoData subTitle="activity" />}

                    </div>



                    {renderLoadMoreButton()}

                </div>

            </div>




            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    


                    <div className="table-responsive">



                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <p className="coin-name">
                                {focusedActivity.message}
                              </p>
                            </div>

                            

                            <div className="col-md-12">
                              <p className="text-info">
                                {formatDate(new Date(focusedActivity.timestamp * 1000))} {formatTime(new Date(focusedActivity.timestamp * 1000))}
                              </p>
                            </div>
                          </div>
                        </div>

                    </div>





                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </WrapUpDashboard>
        
};

export default Settings;