import React, {useState, useEffect} from 'react';
import SettingsWrapper from './SettingsWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import PinAuth from "../../../utility/customComponents/PinAuth";
import Countdown from 'react-countdown';

const ChangePin = () => {

    const dispatch = useDispatch();
    const userData = useSelector(userSelect);
    
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');

    const [securityObj, setSecurityObj] = useState({})
    const [updatingPin, setUpdatingPin]   = useState(false);
    

    const doWithdrawalPinChange = (e) => {

        if(updatingPin){ return; }
        e.preventDefault();

        setUpdatingPin(true);

        dispatch(authActions.onLogin(`account/withdrawal-pin/change`, {emailOTP, withdrawalPin}, response => {
            notify(response.msg, response.code)
            setUpdatingPin(false);
            if(response.code == 1){  setWithdrawalPin(''); }
        }),
        );

    }


    const getNewEmailOTP = () => {

        dispatch(authActions.onLogin(`account/send-email-otp/withdrawal-pin`, {}, response => {

        }),
        );

    }
    
    // useEffect(() => {
    //     getNewEmailOTP();
    // }, [])


    return (
        <SettingsWrapper pageTitle={"Update Pin"}>
            
            <div className="card-body padding-0">
                <div className="row">

                   


                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card ">
                            <div className="card-header">
                                <h4 className="card-title">Update Pin</h4>
                            </div>
                            <div className="card-body ">

                                <form onSubmit={doWithdrawalPinChange}>

                                    <div className="row g-4">

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">New pin</label>
                                            <div className="ioup">
                                                <input onChange={(e) => setWithdrawalPin(e.target.value)} type="password" value={withdrawalPin} className="form-control"
                                                placeholder="New PIN"/>
                                            </div>
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Mailed OTP</label>
                                            <div className="ioup">
                                                <input onChange={(e) => setEmailOTP(e.target.value)} type="password" value={emailOTP} className="form-control"
                                                placeholder="Mailed OTP"/>
                                            </div>
                                        </div>

                                        
                                        <div className="col-xxl-12">
                                            <button disabled={updatingPin} type="submit" className="btn btn-success waves-effect">
                                                
                                                Submit 

                                                <LoadingIcon color={"#fff"} active={updatingPin} />

                                            </button>

                                        </div>


                                        <div className="col-xxl-12">

                                            <Countdown
                                                zeroPadTime={2}
                                                key={new Date(userData?.withdrawalPinResetTime ?? 0)}
                                                date={new Date(userData?.withdrawalPinResetTime ?? 0)}
                                                renderer={({ hours, minutes, seconds, completed }) => {
                                                  if (completed) {
                                                    return <span className="text- pointer pull-right " onClick={getNewEmailOTP}>Request OTP</span>
                                                  } else {
                                                    return <span className="text- pull-right ">Resend OTP after {minutes}:{seconds} seconds</span>;
                                                  }
                                                }}
                                            />

                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </SettingsWrapper>
    );
};

export default ChangePin;