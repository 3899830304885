import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import BuyModal from '../../../element/buy-modal'

import {depositActions} from '../../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allDepositSelect } from '../../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../../utility/Helper1';
import LoadingIcon from "../../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiOutlineDelete, AiOutlineCopy, AiOutlineReload, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import NoData from "../../../../utility/customComponents/NoData";
import FundWrapper from './FundWrapper'



const Trade = () => {
    const [show, setShow] = useState(false);


    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);

    const [deleting, setDeleting]   = useState(false);
    const [loading, setloading]   = useState(false);
    const [deletingInv, setDeletingInv]   = useState(false);
    

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const allDepositData = useSelector(allDepositSelect);
    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [reloadingPendingPayments, setReloadingPendingPayments]   = useState(false);
        
    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }

    const updateCurrencySelected = (typeOfSelected) => {

        let selected = finance?.filter((ite, inde) => ite.type == typeOfSelected)?.[0] ?? []
        setCurrencySelected(selected)

    }


    useEffect(() => {
        getPendingPayments()
    }, [])

        


    const getPendingPayments = () => {

        setReloadingPendingPayments(true)
        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            // notify(response.msg, response.code)
            setReloadingPendingPayments(false)

        }))

    }


     const uploadSelectedPayment = (file) => {
        
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }




    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);
    const getConfirmedPayment = allDepositData?.filter((item, index) => item.status >= 2);


    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        setloading(true)


        dispatch(depositActions.onCreateDeposit({amount, currency: currencySelected.type}, "deposit/new", response => {
            
            if(response?.code === 1){
                setAmount('')
            }else{
                
            }

            notify(response.msg, response.code)

            setloading(false)
            
        }))
    }


    const deleteInv = (txn_id) => {

        if(getPendingPayment?.[0]?.status != 0){
            notify('Cannot be deleted as payment is being processed.', 0)
            return;
        }

        setDeletingInv(true)

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setDeletingInv(false)
        }))

    }



    const fundUiLength = getPendingPayment?.length > 0 ? 12:12




    // for the pending payment part
    let displayableAmount = getPendingPayment?.[0]?.useCryptoValue == 1?`${getPendingPayment?.[0]?.amountEnforcedToPay} ${getPendingPayment?.[0]?.abbr}`:`${getPendingPayment?.[0]?.amountEnforcedToPay} USD`
    let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
    let perfectWalletobj;
    let eachParam;


    if((getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && (getPendingPayment?.[0]?.autoConfirm == 2)){
        let pmAddress = JSON.parse(getPendingPayment?.[0]?.address);
        eachParam = Object.keys(pmAddress.param);
        perfectWalletobj = pmAddress
    }   

    const copyThis = (copythis) => {
        copy(copythis)
        notify('Copied', 3)
    }

    return (
        <FundWrapper pageTitle={"Credit account"}>



                        {getPendingPayment?.length > 0 && <div className="col-xxl-12 col-xl-12 col-lg-12">
                            <div className="card welcome-profil">
                                <div className="card-body padding-0">


                                    


                                    <div className="flexify" style={{padding: 10}}>

                                        <img style={{width: 40, height: 40}} src={curDetails?.[0]?.image} alt="img" />

                                        <div style={{flex: 1, marginLeft: 10}}>
                                            <h4>{displayableAmount}</h4>
                                            {/*<p>Deposit exactly {displayableAmount} to this address</p>*/}
                                        </div>

                                        {getPendingPayment?.[0]?.qrCode!= '' && <div className="pointer" onClick={handleShow} style={{backgroundColor: '#fff'}}>
                                            <img style={{fill: "#fff", width: 60, height: 60}} src={getPendingPayment?.[0]?.qrCode} />
                                        </div>}

                                        <div className="flexify" style={{alignItems: 'center', justifyContent: 'center'}}>
                                            {!reloadingPendingPayments && <AiOutlineReload onClick={getPendingPayments} size={15} className="white-color-1 pointer" />}
                                            <LoadingIcon color={"#fff"} active={reloadingPendingPayments} />

                                            <span className="pointer" dstyle={{}} onClick={() => window.confirm('Click OK to confirm delete') && deleteInv(getPendingPayment?.[0]?.txn_id)}>
                                                <AiOutlineDelete color ='#000' size={15} />

                                                <LoadingIcon color={"#fff"} active={deletingInv} />

                                            </span>
                                        </div>
                                    </div>




                                    <ul>
                                        <li className="flexify" onClick={() => copyThis(getPendingPayment?.[0]?.txn_id)}>
                                            
                                            <a className="white-color-1" to={"/settings-payment-method"}>
                                                <span className="verified"><i className="icofont-check-alt"></i></span>
                                                TXN ID
                                            </a>

                                            <div>
                                                {getPendingPayment?.[0]?.txn_id} <AiOutlineCopy className="white-color-1 pointer" />
                                            </div>

                                        </li>


                                        {getPendingPayment?.[0]?.autoConfirm == 1 && <>
                                            <li className="flexify" onClick={() => {

                                                if(getPendingPayment?.[0]?.redirectToPaymentGatewayUrl == ''){
                                                    copyThis(getPendingPayment?.[0]?.address)
                                                }else{
                                                    // window.location.href=getPendingPayment?.[0]?.redirectToPaymentGatewayUrl
                                                }

                                            }}>
                                                
                                                <a>
                                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                                    Wallet Address: {getPendingPayment?.[0]?.address}
                                                </a>

                                                {getPendingPayment?.[0]?.redirectToPaymentGatewayUrl != '' && <a target={'_blank'} style={{textDecoration: 'underline', color: '#fff'}} className="pointer white-color-1" href={getPendingPayment?.[0]?.redirectToPaymentGatewayUrl}>
                                                    Make payment here
                                                </a>}

                                                {getPendingPayment?.[0]?.redirectToPaymentGatewayUrl == '' && <AiOutlineCopy className="white-color-1 pointer" />}

                                            </li>

                                            <li className="flexify" onClick={() => copyThis(getPendingPayment?.[0]?.amountEnforcedToPay)}>
                                                <a className="white-color-1">
                                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                                    Total sum
                                                </a>

                                                <div>
                                                    {displayableAmount} <AiOutlineCopy className="white-color-1 pointer" />
                                                </div>

                                            </li>

                                            {(getPendingPayment?.[0]?.creationCryptoMerchant == 3 || getPendingPayment?.[0]?.timeout !='') && <li className="flexify">
                                                
                                                <Countdown
                                                    date={new Date(getPendingPayment?.[0]?.timeout)}
                                                    renderer={({ hours, minutes, seconds, completed }) => {
                                                      if (completed) {
                                                        return <span>Payment timeout</span>
                                                      } else {
                                                        return <span>{hours}:{minutes}:{seconds}</span>;
                                                      }
                                                    }}
                                                />

                                            </li>}

                                            {getPendingPayment?.[0]?.paymentMessage && getPendingPayment?.[0]?.paymentMessage !== '' && <li>
                                                {getPendingPayment?.[0]?.paymentMessage}
                                            </li>}

                                        </>}


                                        {getPendingPayment?.[0]?.autoConfirm == 0 && <>



                                            <li className="flexify">

                                                <a className="white-color-1">
                                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                                    {curDetails?.[0]?.bankNameText}: {curDetails?.[0]?.bankName}
                                                </a>

                                            </li>


                                            <li className="flexify">

                                                <a className="white-color-1" to={"/settings-payment-method"}>
                                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                                    {curDetails?.[0]?.accountNameText}: {curDetails?.[0]?.accountName}
                                                </a>

                                            </li>


                                            <li className="flexify" onClick={() => copyThis(curDetails?.[0]?.accountNumber)}>

                                                <a className="white-color-1" to={"/settings-payment-method"}>
                                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                                    {curDetails?.[0]?.accountNumberText}: {curDetails?.[0]?.accountNumber}
                                                </a>

                                            </li>

                                        </>}

                                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 0 && getPendingPayment?.[0]?.autoConfirm == 2 && <li className="flexify">

                                            <form action={perfectWalletobj?.form_url} method="POST" style={{display: 'inline'}}>
                                                {eachParam.map((eachkey, index) => (
                                                        <input name={eachkey} value={perfectWalletobj.param[eachkey]} type="hidden" />
                                                    ))}
                                                <button style={{textDecoration: 'underline'}} type="submit">Pay Now</button>
                                            </form>

                                        </li>}




                                        <li className="flexify">

                                            <a className="white-color-1" to={"/settings-payment-method"}>
                                                <span className="verified"><i className="icofont-info"></i></span>
                                                {moneyStatus()[getPendingPayment?.[0]?.status]}
                                            </a>

                                            

                                        </li>

                                        <li style={{borderWidth: 0}}></li>
                                        <li style={{borderWidth: 0}}></li>

                                        

                                    </ul>

                                    


                                    <div style={{marginTop: 20}}>

                                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 1 && <span>
                                            <span className="h5 flexify" onClick={() => progClickPaymentUpload()}  style={{cursor: 'pointer'}}>
                                                <AiOutlineCloudUpload size={18} /> <span style={{marginLeft: 4}}>{(getPendingPayment?.[0]?.image == null)?'Upload':'Re-upload'} Payment</span>
                                            </span>
                                            <input style={{display: 'none'}} ref={uploadPayment} onChange={(e) => uploadSelectedPayment(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                                        </span>}

                                    </div>



                                </div>
                            </div>
                        </div>}




                        <div className={`col-xxl-${fundUiLength} col-xl-${fundUiLength} col-lg-${fundUiLength}`}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Account Fund </h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit = {processDeposit} className="currency_validate trade-form row g-3">


                                        <h4>{currencySelected?.payment_note_headline}</h4>
                                        <p className="mb-0">{currencySelected?.payment_note_body}</p>

                                        {finance?.length > 1 && <div className="col-12">
                                            <label className="form-label">Wallet</label>
                                            <div className="input-group">

                                                <select onChange={e => updateCurrencySelected(e.target.value)} className="form-select" name="method">
                                                    {finance?.map((item, index) => <>
                                                        <option value={item.type}>{item?.abbr}</option>
                                                    </>)}
                                                </select>
                                                
                                            </div>
                                        </div>}

                                        <div className="col-12">
                                            <label className="form-label">Fund Account in {currencySelected?.symbol}</label>
                                            <div className="input-group">
                                                
                                                <input autoComplete="new-password" type="text" onChange={(e) => setAmount(e.target.value)} value={amount} name="currency_amount" className="form-control"
                                                    placeholder="0.00" />

                                            </div>
                                        </div>


                                        <button disabled={loading} type="submit" name="submit" className="btn btn-success btn-block">
                                            Fund Now
                                            <LoadingIcon color={"#fff"} active={loading} />
                                        </button>

                                    </form>

                                </div>
                            </div>
                        </div>









            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Crypto Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    




                    <div className="table-responsive">

                        <div className="text-center">
                            <img style={{fill: "#fff", width: 180, height: 180}} src={getPendingPayment?.[0]?.qrCode} />
                        </div>


                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <h5>Coin Name</h5>
                              <p className="coin-name">
                                {getPendingPayment?.[0]?.abbr}
                              </p>
                            </div>

                            <div className="col-md-12 text-center">
                              <h5>Wallet</h5>
                              <p>
                                {getPendingPayment?.[0]?.address}

                                <span style={{marginLeft: 5}} onClick={() => copyThis(getPendingPayment?.[0]?.address)}>
                                    <AiOutlineCopy className="text- pointer" />
                                </span>

                              </p>
                            </div>

                            <div className="col-md-12 text-center">
                              <h5>Amount</h5>
                              <p className="text-info">
                                {displayableAmount}
                              </p>
                            </div>
                          </div>
                        </div>

                    </div>





                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>



        </FundWrapper>
    );
};

export default Trade;