import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'


import { Link, Outlet } from 'react-router-dom';
import Slider from "react-slick";

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../../redux/selectors/index';
import SiteWide from "../../../utility/customComponents/SiteWide";
import TimeFormatter from "../../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {displayTime, selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../../utility/Helper1';

import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import Balance from "../Fragments/Balance";
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../../redux/actions';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../../utility/customComponents/Ticker1";
import Ticker2 from "../../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer } from "react-icons/md";
import DashboardFooter1 from '../../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";
import MatchesLists from '../../layout/MatchesLists';
import useLanguage from '../../../utility/config/hooks/useLanguage';


    // main slider
    const quickMatchSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };


    // match result
    const matchResultSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };




const Dashboard = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);
    const usingLanguage = useLanguage()
    
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [loadingDownlines, setLoadingDownlines]     = useState(false)
    const [recentBetsLoading, setRecentBetsLoading] = useState(false)
    const [matchResultLoading, setMatchResultLoading] = useState(false)

    const EACHHIGHLIGHT_LIMIT = 10

    const [matchData, setMatchData]     = useState(byNextDate(footballData?.fixtures)?.slice(0, EACHHIGHLIGHT_LIMIT))
    const [currentMatchDataLength, setCurrentMatchDataLength] = useState(EACHHIGHLIGHT_LIMIT)

    const [homeFixture, setHomeFixture]   = useState(byNextDate(footballData?.fixtures));

    useEffect(() => {
        setHomeFixture(byNextDate(footballData?.fixtures));
        // setMatchData(byNextDate(footballData?.fixtures)?.slice(0, EACHHIGHLIGHT_LIMIT))
    }, [footballData?.fixtures])

    const showAllOdds = (thisFixture) => {
        
        dispatch(footballActions.onToggleBetSlip({openAllOddsModal: true, oddsDataBeingViewd: thisFixture}, response => {

            }),
        )

    }

    const addFixtureToCart = (thisFixture, thisOdds) => {

        dispatch(footballActions.onAddFixtureToBetSlip({...thisFixture, selectedOdd: thisOdds}, response => {
                notify('Bet added to slip', 3)
            }),
        )

    }

    

    const loadRecentBets = () => {

        if((site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 0){ return; }

        setRecentBetsLoading(true)
        dispatch(footballActions.onGetRecents("football/home-recent", response => {

            setRecentBetsLoading(false)

        }))

    }



    const onLoadMatchResults = () => {

        setMatchResultLoading(true)
        dispatch(footballActions.onLoadMatchResults("football/load-results", response => {

            setMatchResultLoading(false)

        }))

    }

    useEffect(() => {
        onLoadMatchResults()
    }, [])

    
    const quickMatches = byNextDate(selectRandomObjects(footballData?.fixtures, 20, 'hjvhbjnkmhbejndk'))

    return ( <>

        <WrapUpFootballArea pageTitle={"Fixture betting"}>



            <div className="match__fixing__wrap top__bottom__space left__right__space" style={{overflowY: 'none', marginRight: 10, marginLeft: 10, paddingBottom: 6 }}>

                <Slider arrows={false} {...quickMatchSettings}>

                        {quickMatches?.map((thisFixture, index) => {

                            let randomOdds = selectRandomObjects(thisFixture?.exactScoreOdd, 2, `${thisFixture?.teams_home_name}${thisFixture?.teams_away_name}`);

                            return <div key={thisFixture?.fixture_id}>
                                <div className="match__fixing__items dark_bg_5">
                                    <div className="match__head">
                                        <div className="match__head__left">
                                            <span className="icons">
                                                <img height={20} src={thisFixture?.league_logo} alt={thisFixture?.league_name} />
                                            </span>
                                            <span>
                                                {subStr(thisFixture?.league_name, 12)}
                                            </span>
                                        </div>

                                        <TimeFormatter className="today" inputTime={thisFixture?.fixture_datetime} />

                                    </div>
                                    <div className="match__vs">
                                        <div className="match__vs__left">
                                            <span className="no-wrapping" title={thisFixture?.teams_home_name}>
                                                
                                                {thisFixture?.teams_home_name}

                                            </span>
                                            <span className="flag">
                                                <img height={30} src={thisFixture?.teams_home_logo} alt={thisFixture?.teams_home_name} />
                                            </span>
                                        </div>

                                        <span className="vs">
                                            Vs
                                        </span>

                                        <div className="match__vs__left">
                                            <span className="flag">
                                                <img height={30} src={thisFixture?.teams_away_logo} alt={thisFixture?.teams_away_name} />
                                            </span>
                                            <span className="no-wrapping" title={thisFixture?.teams_away_name}>

                                                {thisFixture?.teams_away_name}

                                            </span>
                                        </div>
                                    </div>
                                    <div className="match__result red-bg-1">
                                        <span className="matchborder red-bg-1 "></span>
                                        <span className="match__text red-bg-1 unchanged-white-color-1">
                                            Outcomes
                                        </span>
                                    </div>

                                    <ul className="match__point">

                                        {randomOdds?.map(eachOdds => {
                                            let addedStyle = {}

                                            let testExistenceInSlip = footballData?.bet_slip?.betcart?.some(item => item?.selectedOdd?.uniqueId == eachOdds?.uniqueId)
                                            if(testExistenceInSlip){
                                                addedStyle = {backgroundColor: '#ff6600'}
                                            }

                                            return <li style={addedStyle} key={eachOdds?.uniqueId} className="pointer" onClick={() => addFixtureToCart(thisFixture, eachOdds)}>
                                                <span>{`${eachOdds?.['home-scores']}:${eachOdds?.['away-scores']}`}</span>
                                                <span>{Number(eachOdds?.['percentage-winning'])?.toFixed(1)}%</span>
                                            </li>

                                        })}
                                        
                                        <li className="pointer red-bg-1" onClick={() => showAllOdds(thisFixture)}>
                                            <span>More</span>
                                            <span>>></span>
                                        </li>

                                    </ul>

                                </div>
                            </div>

                        })}
                    
                </Slider>

            </div>



            <div className="main__body__wrap left__right__space" style={{paddingLeft: 6, paddingRight: 6, marginTop: 0}}>

                <div className="live__heightlight mb__30 dark_bg_5" style={{marginBottom: 6}}>
                    <div className="section__title">
                        <h4>
                            {usingLanguage['headlines']['match_highlights']}
                        </h4>
                    </div>


                    <div className="height__table">
                        <div className="tab-content" id="nav-tabContentheight">

                            <div className="tab-pane fade text-white show active" id="height1" role="tabpanel" aria-labelledby="nav-home-tabpre" tabindex="0">
                                <div className="main__table" style={{width: '100%'}}>

                                    <MatchesLists data={byNextDate(footballData?.fixtures)} />

                                </div>
                            </div>


                        </div>
                    </div>
                </div>













                <div className="dark_bg_5 live__heightlight trending__now" style={{marginBottom: 6}}>

                    <div className="section__title">
                        <h4>
                            {usingLanguage['headlines']['trending_results']}
                        </h4>
                    </div>
                    

                    <div className="height__table" style={{}}>
                        <div className="tab-content" id="nav-tabContentheighttrending">






                            <div style={{paddingLeft: 17, paddingRight: 17}}>

                                <Slider className="mainslider-1" arrows={false} {...matchResultSettings}>

                                    {footballData?.results?.slice(0, 7)?.map((eachMatchResult, index) =>{


                                        return <div style={{paddingBottom: 40}}>
                                        
                                            <div className="flexify main-bg-color-1 white-color-1" style={{padding: 4, paddingRight: 10, paddingLeft: 10}}>
                                                <div>{eachMatchResult?.league_country}: {eachMatchResult?.league_name}</div>
                                                <div>{eachMatchResult?.league_round}</div>
                                            </div>

                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30}}>



                                                <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                    <div className="ark_bg_1 text-center" style={{width: 90, height: 90, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5}}>
                                                        <img height={80} src={eachMatchResult?.teams_home_logo} alt={eachMatchResult?.teams_home_name} />
                                                    </div>

                                                    <div className="white-color-1" style={{marginTop: 10}}>
                                                        {eachMatchResult?.teams_home_name}
                                                    </div>
                                                </div>

                                                <div className="text-center white-color-1" style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                    <div className="" style={{fontSize: 10}}>
                                                        {displayTime(eachMatchResult?.fixture_datetime)}
                                                    </div>

                                                    <h1 className="white-color-1" style={{marginTop: 10, fontSize: 50}}>
                                                        {eachMatchResult?.goals_home} - {eachMatchResult?.goals_away}
                                                    </h1>

                                                    <div className="text-success" style={{fontSize: 10, marginTop: 10}}>
                                                        {eachMatchResult?.fixture_status_long}
                                                    </div>

                                                </div>


                                                <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                    <div className="ark_bg_1 text-center" style={{width: 90, height: 90, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5}}>
                                                        <img height={80} src={eachMatchResult?.teams_away_logo} alt={eachMatchResult?.teams_away_name} />
                                                    </div>

                                                    <div className="white-color-1" style={{marginTop: 10}}>
                                                        {eachMatchResult?.teams_away_name}
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="text-center white-color- dark_text_4" style={{fontSize: 11, marginTop: 20}}>
                                                {eachMatchResult?.fixture_venue_name}. {eachMatchResult?.fixture_venue_city}
                                                <p>{eachMatchResult?.fixture_referee}</p>
                                            </div>

                                        </div>

                                    })}

                                </Slider>

                            </div>








                        </div>
                    </div>

                </div>

            </div>



        </WrapUpFootballArea>
        <Outlet />
        </>
    );
};

export default Dashboard;