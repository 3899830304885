import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
// import history from "./History";

import Dashboard from './pages/Football/index';
import Banner from './pages/Football/Banner';
import Promotion from './pages/Football/Promotion';
import Today from './pages/Football/Today';
import Tomorrow from './pages/Football/Tomorrow';
import League from './pages/Football/League';
import Country from './pages/Football/Country';
import Search from './pages/Football/Search';
import MyLeague from './pages/Football/MyLeague';
import BonusOdd from './pages/Football/BonusOdd';
import Live from './pages/Football/Live';
import Cancelled from './pages/Football/Cancelled';
import Results from './pages/Football/Results';
import MyBets from './pages/Football/MyBets';

import News from './pages/News';

import Fund from './pages/wallet/Fund/Fund';
import FundHistory from './pages/wallet/Fund/History';

import Wallet from './pages/wallet/Wallet/Wallet';
import PaymentAddresses from './pages/wallet/Wallet/PaymentAddresses';
import WithdrawalInformation from './pages/wallet/Wallet/WithdrawalInformation';

import Loan from './pages/wallet/Loan';
import OnlineLoan from './pages/wallet/OnlineLoan';
import DownlineList from './pages/referral/DownlineList';
import CopyLink from './pages/referral/CopyLink';
import ShowRefEarningBoxes from './pages/referral/ShowRefEarningBoxes';


import Trade from './pages/wallet/Trade';
import OnlineTrade from './pages/wallet/OnlineTrade';


import Price from './pages/price';
import Demo from './pages/demo';
import Intro from './pages/intro';
import Lock from './pages/lock';
import Otp1 from './pages/otp-1';
import Otp2 from './pages/otp-2';
import PriceDetails from './pages/price-details';
import Profile from './pages/profile';
import SettingsActivity from './pages/settings-activity';
import SettingsApi from './pages/settings-api';
import SettingsApplication from './pages/settings-application';
import SettingsFees from './pages/settings-fees';

import SettingsPaymentMethod from './pages/profile/settings-payment-method';
import SettingsPrivacy from './pages/settings-privacy';
import SettingsProfile from './pages/profile/settings-profile';
import SettingsKYC from './pages/profile/settings-kyc';
import SettingsSalary from './pages/profile/settings-salary';

import PrivacyPolicy from './pages/Policies/Privacy';
import TermsAndCondition from './pages/Policies/Terms';
import AffiliatePolicy from './pages/Policies/AffiliatePolicy';
import Faq from './pages/Policies/Faq';

import SettingsChangePassword from './pages/settings/settings-change-password';
import SettingsChangePin from './pages/settings/settings-change-pin';
import SettingsUpdateGoogleAuth from './pages/settings/settings-google-auth';


import Signin from './pages/auth/Signin';
import Signup from './pages/auth/Signup';
import UserVerification from './pages/auth/UserVerification';
import Reset from './pages/auth/Reset';

import UnverifiedMailRoute from '../utility/UnverifiedMailRoute';
import PrivateRoute from '../utility/PrivateRoute';
import PublicRoute from '../utility/PublicRoute';

import { allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions, footballActions} from '../redux/actions';

import Loader from '../utility/customComponents/loader/index';

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";


const Router = () => {

    // const location = useLocation();

    const site_settings = useSelector(allRemoteSettingsSelect);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        dispatch(authActions.onRefreshSystemData("system/refresh", 10, {}, response => {

            if((response?.code ?? 0) == 1){
                setLoading(false);
            }
            
        }))
    }, [])


    const getFixtures = () => {
        dispatch(footballActions.onLoadAll({}, "football/load-fixtures", response => {

            }))
    }

    // load fixture separately
    useEffect(() => {

        getFixtures()
        
        const intervalId = setInterval(() => {
            getFixtures()
        }, 60*60*1000)

        return () => clearInterval(intervalId); // Cleanup
        
    }, [])



    return (
        <>

            {loading && <Loader />}

            {!loading && <>
                
                <BrowserRouter>

                    <Routes>


                        <Route path='/' element={<Dashboard />} />

                            <Route path='/today' exact element={<Today />} />
                            <Route path='/tomorrow' exact element={<Tomorrow />} />
                            <Route path='/league/:leagueId/:leagueName' exact element={<League />} />
                            <Route path='/country/:countryName' exact element={<Country />} />
                            <Route path='/search' exact element={<Search />} />
                            <Route path='/bonus-odd' exact element={<BonusOdd />} />
                            <Route path='/live' exact element={<Live />} />
                            <Route path='/cancelled' exact element={<Cancelled />} />
                            <Route path='/results' exact element={<Results />} />
                            <Route path='/banner' exact element={<Banner />} />
                            <Route path='/promotion' exact element={<Promotion />} />
                            <Route path='/affiliate-program' element={<AffiliatePolicy />} />
                            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                            <Route path='/terms-and-condition' element={<TermsAndCondition />} />
                            <Route path='/faq' exact element={<Faq />} />



                            <Route element={<PrivateRoute />}>

                                <Route path='/my-leagues' element={<MyLeague />} />
                                <Route path='/news' element={<News />} />
                                <Route path='/my-bets' element={<MyBets />} />
                                <Route path='/fund' element={<Fund />} />
                                <Route path='/fund-history' element={<FundHistory />} />
                                <Route path='/wallet' element={<Wallet />} />
                                <Route path='/payment-address' element={<PaymentAddresses />} />
                                <Route path='/withdrawal-history' element={<WithdrawalInformation />} />
                                <Route path='/settings-salary' element={<SettingsSalary />} />
                                <Route path='/referral' element={<DownlineList />} />
                                <Route path='/invitation-link' element={<CopyLink />} />
                                <Route path='/referral/earning' element={<ShowRefEarningBoxes />} />
                                <Route path='/trade' element={<Trade />} />
                                <Route path='/trade-online' element={<OnlineTrade />} />
                                <Route path='/settings-activity' element={<SettingsActivity />} />
                                <Route path='/settings-activity' element={<SettingsActivity />} />

                                <Route
                                    path='/settings-payment-method'
                                    element={
                                        <SettingsPaymentMethod />
                                    }
                                  />
                                  <Route
                                    path='/settings-privacy'
                                    element={
                                        <SettingsPrivacy />
                                    }
                                  />
                                  <Route
                                    path='/settings-profile'
                                    element={
                                        <SettingsProfile />
                                    }
                                  />
                                  <Route
                                    path='/settings-kyc'
                                    element={
                                        <SettingsKYC />
                                    }
                                  />
                                  <Route
                                    path='/settings-password'
                                    element={
                                        <SettingsChangePassword />
                                    }
                                  />
                                  <Route
                                    path='/settings-pin'
                                    element={
                                        <SettingsChangePin />
                                    }
                                  />
                                  <Route
                                    path='/settings-google-auth'
                                    element={
                                        <SettingsUpdateGoogleAuth />
                                    }
                                  />

                            
                            </Route>


                            


                        

                            <Route element={<UnverifiedMailRoute />}>

                                <Route
                                    path='/verify-user'
                                    element={
                                        <UserVerification />
                                    }
                                />

                            </Route>


                            <Route element={<PublicRoute />}>

                                <Route
                                    path='/signin'
                                    element={
                                        <Signin />
                                    }
                                />

                                <Route
                                    path='/signup'
                                    element={
                                        <Signup />
                                    }
                                />


                                <Route
                                    path='/reset'
                                    element={
                                        <Reset />
                                    }
                                />

                            </Route>

                        </Routes>

                        

                </BrowserRouter>
            </>}
        </>
    );
};



function ModalExample(props) {
    const [showDialog, setShowDialog] = React.useState(true);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    return (
        <div>
            <button onClick={open}>Open Dialog</button>
            <Dialog isOpen={showDialog} onDismiss={close}>
                <button className="close-button" onClick={close}>
                    <span aria-hidden>×</span>
                </button>
                <p>Hello there. I am a dialog</p>
            </Dialog>
        </div>
    );
}

export default Router;