import * as actionTypes from "../actions/actionTypes";

const initialState = {
  footballdata: null,
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    case actionTypes.SAVE_ALL_FIXTURE_DISK:


      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          fixtures: action.data.fixtures
        },
      };


    case actionTypes.SAVE_ALL_MATCH_RESULTS_DISK:


      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          results: action.data.results
        },
      };



    case actionTypes.SAVE_ALL_LIVE_MATCH_TO_DISK:

      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          live: action.data.live
        },
      };


    case actionTypes.SAVE_ALL_CANCELLED_MATCH_TO_DISK:

      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          cancelled: action.data.cancelled
        },
      };




    
    case actionTypes.SAVE_USER_BETS_DISK:
      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          user_games: action.data
        },
      };


    case actionTypes.SAVE_HOME_RECENT_DISK:
      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          recent_bets: action.data
        },
      };










    case actionTypes.SAVE_BET_SLIP_INFO_DISK:

      // action.data can contain objects like {showBetId: bool, openBetSlip: bool, openBetCategory: bool, openAllOddsModal: bool, oddsDataBeingViewd: {} betcart: array of fixtures having added object named 'selectedOdd'}

      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          bet_slip: {...(state?.footballdata?.bet_slip ?? {}), ...action.data}
        },
      };


    case actionTypes.ADD_FIXTURE_TO_BET_SLIP_ON_DISK:

      let betcart = state?.footballdata?.bet_slip?.betcart ?? []

      if(state?.footballdata?.bet_slip?.betcart?.some(item => item.fixture_id == action?.data?.fixture_id)){

        betcart = state?.footballdata?.bet_slip?.betcart?.map((item, index) => {

          if(item.fixture_id == action?.data?.fixture_id){
            return action?.data
          }else{
            return item
          }

        })

      }else{

        const newDataYen = [...betcart, action?.data]
        betcart = newDataYen

      }

      const finalData = {...(state?.footballdata?.bet_slip ?? {}), betcart}

      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          bet_slip: finalData
        },
      };





      case actionTypes.REMOVE_FIXTURE_FROM_BET_SLIP_ON_DISK:

        let remainingBetsInCart = state?.footballdata?.bet_slip?.betcart?.filter(item => item.fixture_id != action?.data?.fixture_id)

        return {
          ...state,
          footballdata: {
            ...state.footballdata,
            bet_slip: {...(state?.footballdata?.bet_slip ?? {}), betcart: remainingBetsInCart}
          },
        };


      case actionTypes.CLEAR_ALL_FIXTURE_FROM_BET_SLIP_ON_DISK:

        return {
          ...state,
          footballdata: {
            ...state.footballdata,
            bet_slip: {...(state?.footballdata?.bet_slip ?? {}), betcart: []}
          },
        };







      case actionTypes.MANAGE_AMOUNT_TO_BET_SLIP_DISK:

        let allFixturesInCart = state?.footballdata?.bet_slip?.betcart ?? []

        const allBetcart = state?.footballdata?.bet_slip?.betcart?.map((item, index) => {

            if(item.fixture_id == action?.data?.fixture_id){
              return {...item, selectedOdd: {...item?.selectedOdd, betAmount: action?.data?.val}}
            }else{
              return item
            }

          })

      

        const finalDataAgain = {...(state?.footballdata?.bet_slip ?? {}), betcart: allBetcart}

        return {
          ...state,
          footballdata: {
            ...state.footballdata,
            bet_slip: finalDataAgain
          },
        };



      
    default:
      return state;
  }
};
