import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import {addUrlToPath} from '../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';


import Header from './header'
import MobileFooter from './mobile-footer'
import Sidebar from './sidebar'
import SiteWide from "../../utility/customComponents/SiteWide";
import ChatDelegator from '../../Livechats/ChatDelegator'


const WrapUpDashboard = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const [openLivechat, setOpenLivechat] = useState(false)

    const updateOpenry = () => {

        if(openLivechat){
            // window.location.reload()
        }

        setOpenLivechat(el => !el)

    }

    const showMatchMenuOptions = props?.showMatchMenuOptions ?? true

    return (
        <SiteWide pageTitle={props?.pageTitle}>


            <Header showMatchMenuOptions={props?.showMatchMenuOptions} />


            <section className="main__body__area main-bg-color-1" style={{marginTop: !showMatchMenuOptions?60:0}}>
                <div className="container-fluid p-0 main-bg-color-1">
                    <div className="row g-0 main-bg-color-1">
                        {props.children}
                    </div>
                </div>
            </section>
            
           
            {!props?.hideMobileFooter && <MobileFooter />}
            

        </SiteWide>
    );
};

export default WrapUpDashboard;