import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {footballActions} from '../../redux/actions';
import {addUrlToPath, subStr, notify} from '../../utility/Helper1'
import TimeFormatter from "../../utility/customComponents/TimeFormatter";

import { userSelect, homeScreenStatSelect, allTradeSelect, allRemoteSettingsSelect, footballSelect } from '../../redux/selectors/index';

import { MdVerifiedUser, MdOutlineCircle, MdOutlineStadium, MdOutlineSports, MdOutlineSummarize } from "react-icons/md";
import BetSlip from './betslip'
import Modal from 'react-modal';
import { BiWindowClose, BiSolidCircle } from "react-icons/bi";
import DashboardEvent from './dashboard-event'
import { IoFootball } from "react-icons/io5";




const Footer = () => {

    const dispatch = useDispatch();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const [openBetslipModal, setOpenBetslipModal] = useState(false)

    const footballData  = useSelector(footballSelect);

    const thisFixture = footballData?.bet_slip?.oddsDataBeingViewd



    const betSlipMobileModalStyle = {

        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust opacity as needed
            zIndex: 99, // Ensure it's above other elements
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            padding: 0
        },

        content: {
            top: 0,
            right: 0,
            left: 'auto',
            bottom: 'auto',
            backgroundColor: '#141c33', // Adjust opacity as needed
            border: '0.5px solid #5f677f',
            width: '300px',
            height: '100vh', // Adjust as needed
            overflow: 'auto',
            margin: 0,
            padding: 0,
        },

    };


    const betCategoriesMobileModalStyle = {

        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust opacity as needed
            zIndex: 99999, // Ensure it's above other elements
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            padding: 0
        },

        content: {
            top: 0,
            right: 'auto',
            left: 0,
            bottom: 'auto',
            backgroundColor: '#141c33', // Adjust opacity as needed
            border: '0.5px solid #5f677f',
            width: '300px',
            height: '100vh', // Adjust as needed
            overflow: 'auto',
            margin: 0,
            padding: 0,
        },

    };




    const oddModalStyles = {

        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // Adjust opacity as needed
            zIndex: 9, // Ensure it's above other elements
            backgroundImage: `url(${require("../../images/soccer/odd1.png")})`,
            // backgroundSize: 'cover',
            backgroundPosition: 80,
            backgroundRepeat: 'no-repeat'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // Adjust opacity as needed
            border: '0.5px solid #5f677f',
            width: '400px',
            maxHeight: '80vh', // Adjust as needed
            overflow: 'auto',
        },

    };



    const toggleGetSlipModal = (boolValue) => {

        dispatch(footballActions.onToggleBetSlip({openBetSlip: boolValue}, response => {

            }),
        )

    }



    const toggleOpenBetCategoryModal = (boolValue) => {

        dispatch(footballActions.onToggleBetSlip({openBetCategory: boolValue}, response => {

            }),
        )

    }





    const toggleAllOdds = (boolValue) => {

        dispatch(footballActions.onToggleBetSlip({openAllOddsModal: boolValue}, response => {

            }),
        )

    }


    const addFixtureToCart = (thisFixture, thisOdds) => {

        dispatch(footballActions.onAddFixtureToBetSlip({...thisFixture, selectedOdd: thisOdds}, response => {
                notify('Bet added to slip', 3)
            }),
        )

    }

    return <>
            <ul class="footer__menu d-lg-none orange-bg-color-2">
                <li>
                    <Link to={"banner"} class="d-grid each-bottom-menu">
                        <div  style={{flex: 1, width: '100%'}}>
                            
                        </div>

                        <div style={{flex: 1, width: '100%'}} class="texta">

                            <MdOutlineStadium size={22} />

                            <span className="f-11">Promo</span>

                        </div>

                    </Link>
                </li>
                <li>

                    <a href="#"  onClick={(e) => {e.preventDefault(); toggleOpenBetCategoryModal(true)}} class="d-grid each-bottom-menu">
                        <div  style={{flex: 1, width: '100%'}}>
                            
                        </div>

                        <div style={{flex: 1, width: '100%'}} class="texta">

                            <MdOutlineSports size={22} />

                            <span className="f-11">Feature</span>

                        </div>

                    </a>

                </li>
                <li class="headr-bartwo d-lg-none">

                    <Link to={"/"}>
                        <IoFootball color={"#fff"} size={50} />
                        {/*<img style={{width: 45, height: 45}} src={require('../../images/soccer/ball.png')} />*/}
                    </Link>

                </li>
                <li>

                    <a href="#" onClick={(e) => {e.preventDefault(); toggleGetSlipModal(true)}} class="d-grid each-bottom-menu">
                        <div  style={{flex: 1, width: '100%'}}>
                            
                        </div>

                        <div style={{flex: 1, width: '100%'}} class="texta">

                            <MdOutlineSummarize size={22} />

                            <span className="f-11">Slip</span>

                            <div className="badge badge-warning" style={{position: 'absolute', top: 10, borderRadius: 100, marginLeft: -10, marginTop: -10, backgroundColor: 'red'}}>
                                {footballData?.bet_slip?.betcart?.length ?? 0}
                            </div>

                        </div>

                    </a>

                </li>
                <li>
                   

                    <Link to={'/settings-profile'} class="d-grid each-bottom-menu">
                        <div  style={{flex: 1, width: '100%'}}>
                            
                        </div>

                        <div style={{flex: 1, width: '100%'}} class="texta">

                            <MdVerifiedUser size={22} />

                            <span className="f-11">Account</span>

                        </div>

                    </Link>

                </li>
            </ul>































            <Modal
                closeTimeoutMS={500}
                isOpen={footballData?.bet_slip?.openAllOddsModal ?? false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => toggleAllOdds(false)}
                style={oddModalStyles}
                contentLabel="all odds"
              >

                {/*<img style={{position: 'absolute', left: 10, bottom: 0}} src={require("../../../images/soccer/odd1.png")} />*/}


                <div onClick={() => toggleAllOdds(false)} className={"pointer"} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <BiWindowClose size={18} color={"#7b839b"} style={{marginTop: -10, marginBottom: 10}} />
                </div>

                <div className="left__site__section">

                    <div className="match__fixing__wrap top__bottom__space left__right__space dark_bg_5">
                        
                        <div className="match__fixing__items dark_bg_5">
                            



                            <div className="match__head">
                                <div className="match__head__left">
                                    <span className="icons">
                                        <img height={20} src={thisFixture?.league_logo} alt={thisFixture?.league_name} />
                                    </span>
                                    <span>
                                        {subStr(thisFixture?.league_name, 12)}
                                    </span>
                                </div>

                                <TimeFormatter className="today" inputTime={thisFixture?.fixture_datetime} />

                            </div>
                            <div className="match__vs">
                                <div className="match__vs__left">
                                    <span className="" title={thisFixture?.teams_home_name}>
                                        
                                        {thisFixture?.teams_home_name}

                                    </span>
                                    <span className="flag">
                                        <img height={60} src={thisFixture?.teams_home_logo} alt={thisFixture?.teams_home_name} />
                                    </span>
                                </div>
                                <span className="vs">
                                Vs
                            </span>
                                <div className="match__vs__left">
                                    <span className="flag">
                                        <img height={60} src={thisFixture?.teams_away_logo} alt={thisFixture?.teams_away_name} />
                                    </span>
                                    <span className="" title={thisFixture?.teams_away_name}>

                                        {thisFixture?.teams_away_name}

                                    </span>
                                </div>
                            </div>
                            <div className="match__result">
                                <span className="matchborder"></span>
                                <span className="match__text">
                                Outcomes
                            </span>
                            </div>

                            <ul className="match__point row no-gutter">

                                {thisFixture?.exactScoreOdd?.map(eachOdds => {

                                    let addedStyle = {}
                                    let testExistenceInSlip = footballData?.bet_slip?.betcart?.some(item => item?.selectedOdd?.uniqueId == eachOdds?.uniqueId)
                                    if(testExistenceInSlip){
                                        addedStyle = {backgroundColor: '#ff6600'}
                                    }


                                    return <li onClick={() => addFixtureToCart(thisFixture, eachOdds)} style={addedStyle} className="pointer col-4">
                                        <span>{`${eachOdds?.['home-scores']}:${eachOdds?.['away-scores']}`}</span>
                                        <span>{Number(eachOdds?.['percentage-winning'])?.toFixed(1)}%</span>
                                    </li>
                                })}

                            </ul>

                        </div>


                        <div style={{ position: 'relative' }}>
                          <img style={{ width: '100%' }} src={addUrlToPath(site_settings?.image_settings?.[0]?.image2)} alt="Stadium" />
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              textAlign: 'center',
                              color: 'white', // Text color
                              fontSize: '18px', // Adjust font size
                              fontWeight: 'bold', // Bold text
                              borderRadius: 5,
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              padding: 5,
                            }}

                          >
                            {thisFixture?.fixture_venue_name}
                          </div>

                          <div
                            style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              color: 'white', // Text color
                              fontSize: '12px', // Adjust font size
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              padding: 2,
                            }}

                          >
                            {thisFixture?.league_country} <BiSolidCircle size={6} /> {thisFixture?.fixture_venue_city}
                          </div>

                        </div>


                    </div>
                    

                </div>

            </Modal>




















            <Modal
                closeTimeoutMS={500}
                isOpen={footballData?.bet_slip?.openBetCategory ?? false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => toggleOpenBetCategoryModal(false)}
                contentLabel="Bet Category Modal"
                style={betCategoriesMobileModalStyle}

              >

                <div className="left__site__section">
                    <DashboardEvent addHiddenClassByDefault={false} />
                </div>

            </Modal>









            <Modal
                closeTimeoutMS={500}
                isOpen={footballData?.bet_slip?.openBetSlip ?? false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => toggleGetSlipModal(false)}
                contentLabel="Betslip Modal"
                style={betSlipMobileModalStyle}

              >

                <BetSlip addHiddenClassByDefault={false} />

            </Modal>

        
        </>




};

export default Footer;