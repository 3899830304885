import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import BuyModal from '../../element/buy-modal'
import PriceArea from '../../charts/price-area'

import {authActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, loanSelect, homeScreenStatSelect, allRemoteSettingsSelect, allDepositSelect } from '../../../redux/selectors/index';
import Helper1, {capitalizeFirstLetter, niceDate, notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'
import SubMenu from '../../layout/SubMenu';

import NoData from "../../../utility/customComponents/NoData";
 import PerfectScrollbar from 'react-perfect-scrollbar'

import parse from 'html-react-parser';
import { stripHtml } from "string-strip-html";
import ReferralWrapper from "./ReferralWrapper";


const eachDataMax = 20

const ShowRefEarningBoxes = () => {
    const [show, setShow] = useState(false);

    const [viewingLevel, setViewingLevel]     = useState(1)
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [mainUplineData, setMainUplineData]    = useState({})
    const [lengthToShow, setLengthToShow]    = useState(eachDataMax)


    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);    
    const finance = useSelector(homeScreenStatSelect);
    
    const [loading, setLoading] = useState(false)


    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.user_dashboard_base_url+'/signup?ref='+ myRefCode
    const copyRefCode = (copythis) => {
        copy(copythis)
        notify('Copied', 1)
    }


    const showMore = () => {
        setLengthToShow(el => el + eachDataMax)
    }


    const originalDataLength = refData?.['level' + viewingLevel];
    const allReferralData = originalDataLength?.slice(0, lengthToShow)


    return (

        <ReferralWrapper pageTitle={"Referral Management"}>




                    <div className="car flexify" style={{gap: 10, margginBottom: 10}}>

                        <div className="text-center card-body dark_bg_5">
                            <h5>Invites</h5>
                            <div style={{marginTop: 12}}>
                                <h2>{userData?.totalInvitees}</h2>
                            </div>
                        </div>

                        <div className="text-center card-body dark_bg_5">
                            <h5>Commission</h5>
                            <div style={{marginTop: 12}}>
                                <h2>{money(finance?.[0]?.commission ?? 0, 1, {removeSymbol: true})}</h2>
                            </div>
                        </div>

                    </div>

                    <br />

        </ReferralWrapper>
    );
};

export default ShowRefEarningBoxes;