export default {
  menu1: {
    deposit:        'Dépôt',
    wallet:         'Portefeuille',
    invitation:     'Invitation',
    logout:         'Déconnexion',
    profile:        'Profil',
    settings:       'Paramètres',
    sign_in:        'Se connecter',
    sign_up:        'S\'inscrire',
    referral:       'Parrainage',
    earn_salary:    'Gagner un salaire',
    top_up:         'Recharger',
    faq:            'FAQ',
    terms:          'Termes & Conditions',
    privacy:        'Politique de confidentialité',
  },
  menu2: {
    home:           'Accueil',
    search:         'Recherche',
    today:          'Aujourd’hui',
    tomorrow:       'Demain',
    my_league:      'Ma ligue',
    bonus_odd:      'Cote bonus',
    live:           'En direct',
    cancelled:      'Annulé',
    results:        'Résultats',
  },
  headlines:{
    match_highlights:   'Temps forts du match',
    general_info:       'Infos générales',
    trending_results:   'Résultats tendance',
    matches:            'Matchs',
    live_account:       'Compte en direct',
    league:             'Ligue',
    nations:            'Nations',
    betslip:            'Coupon de pari',
    slip:               'Fiche',
    my_bets:            'Mes paris',
    fund:               'Fonds',
  },
  page: {
    sign_in:{
      page_title:               'Se connecter au compte',
      username:                 'Nom d’utilisateur',
      password:                 'Mot de passe',
      remember_me:              'Se souvenir de moi',
      forgot_password:              'Mot de passe oublié',
      dont_have_account:              'Pas de compte',
    },
    sign_up:{
      page_title:               'Créer un compte',
      username:                 'Nom d’utilisateur',
      password:                 'Mot de passe',
      email:              'Email',
      country:              'Pays',
      select_country:              'Sélectionner un pays',
      phone:              'Téléphone',
      account_security_pin:              'Code PIN de sécurité du compte',
      agree_to_the_terms_and_conditions: 'J’accepte les termes et conditions',
    },
    index:{
      page_title:              '',
      match_detail:       'Détail du match',
      trending_results:    'Résultats tendances',
    },
    search:{
      title:              'Résultat de recherche',
      match_detail:       'Détail du match',
      enter_team_name:    'Entrez le nom de l’équipe, l’ID de pari ou les infos de la ligue',
    },

  },
  random_text: {
    betslip:{
      empty_slip_note: 'Votre coupon de pari est vide.<br />Veuillez faire une ou plusieurs sélections pour placer un pari.',
      multiple_bet_note: 'Les paris multiples sont placés séparément et les gains/pertes sont traités individuellement',
      place_bet: 'Placer le pari',
      total_odds: 'Cotes totales',
      total_bet_amount: 'Montant total du pari',
      possible_winnings: 'Gains possibles',
    },
    empty_data: {
      no_data: 'Aucune donnée disponible.',
    }
  }
};
