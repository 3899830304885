import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {useNavigate} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect, allLocalSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import ReCAPTCHA from "../../../utility/customComponents/ReCAPTCHA";

import AuthHolder from "./AuthHolder";
import { AiFillCloseCircle, AiOutlineUser, AiOutlineMail, AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineGlobal } from "react-icons/ai";


const Signin = () => {

    const dispatch = useDispatch();
    const users = useSelector(userSelect);

    const [email, setEmail]                             = useState('');

    
    const [password, setPassword]                       = useState('');
    const [loading, changeLoading]                      = useState(false);
    const [loadingText, setLoadingText]                      = useState("");
    const [notificationMessage, setNotificationMessage] = useState('');
    const [fullPageLoading, setFullPageLoading]         = useState(false);

    const navigate                                       = useNavigate();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const local_settings                                 = useSelector(allLocalSettingsSelect);




    const [captcha, setCaptcha]                       = useState(null);

    const captchaHasChanged = (data) => {
        setCaptcha(data)
    }

    const doLogin = async (e) => {

        e.preventDefault();

        if(!captcha && (Number(site_settings?.site_settings?.use_google_reCaptcha) == 1)){ notify('Invalid captcha response', 0); return; }

        setFullPageLoading(true);
        if(loading){ return; }

        changeLoading(true)
        setLoadingText("Authenticating...")

        dispatch(authActions.onLogin("account/login", {username:email, captcha, password:password}, response => {


            if(response.code !== 0){

                if(response.code == 1){

                    let toThisPlace;

                    if(local_settings?.lastPrivateUrl){
                        toThisPlace = local_settings?.lastPrivateUrl
                    }else{
                        toThisPlace = '/'
                    }

                    navigate(toThisPlace, { replace: true });

                }else if(response.code === 2){
                    navigate('/verify-user', { replace: true });
                }

            }
            
            notify(response.msg, response.code)
            changeLoading(false)
            setLoadingText("")

            setFullPageLoading(false);
        
        }),
    );

        
    };


    
    let userLoginStyle = site_settings?.operational_settings?.userLoginStyle
    let userLoginStyleObj = [
        {
            inputType: 'email',
            placeholder: 'Email ID',
        },
        {
            inputType: 'text',
            placeholder: 'Username',
        }

    ]

    return (
        <AuthHolder title={`Sign in to ${site_settings?.site_settings?.sitename}`}>

            

            <form name="myform" className="signin_validate row g-3">

                <h2 className="text-center">Sign In To Account</h2>

                <div onClick={() => navigate(-1)} className={"pointer"} style={{position: 'absolute', top: -34, right: -370}}>
                    <AiFillCloseCircle className="unchanged-white-color-1" size={30} />
                </div>

                <label className="single-input-wrap">
                    <span className="new-input-label hide-label">{userLoginStyleObj?.[userLoginStyle]?.placeholder}*</span>
                    <AiOutlineMail className="auth-icon-color" size={20} />
                    <input onChange = {e => setEmail(e.target.value) } required type={userLoginStyleObj?.[userLoginStyle]?.inputType} className="auth-input form-control" placeholder={userLoginStyleObj?.[userLoginStyle]?.placeholder}
                        name="email"/>
                </label>




                {/*<div className="flexify enforce-radius">

                    <AiOutlineUser size={30} className="side-icon" color={"#000"} style={{color: 'red'}} />

                        <input onChange = {e => setEmail(e.target.value) } required type={userLoginStyleObj?.[userLoginStyle]?.inputType} className="form-control" placeholder={userLoginStyleObj?.[userLoginStyle]?.placeholder}
                            name="email"/>

                </div>*/}








                <label className="single-input-wrap">
                    <span className="new-input-label hide-label">Password*</span>
                    <AiOutlineLock className="auth-icon-color" size={20} />
                    <input onChange={e => setPassword(e.target.value) } required type="password" className="auth-input form-control" placeholder="Password"
                        name="password"/>
                </label>
               

                <div className="col-12">
                    <ReCAPTCHA onChange={captchaHasChanged} />
                </div>


                <div className="text-center">
                    <button onClick={doLogin} type="submit" className="btn btn-primary btn-block">
                        Sign in

                        <LoadingIcon color={"#fff"} active={loading} />

                    </button>

                    <div className="flexify" style={{marginTop: 15}}>
                        <div className="text-center">
                            <Link to={"/reset"}>Forgot Password?</Link>
                        </div>
                        <div className="text-center">
                            <Link to={"/signup"}>Create Account</Link>
                        </div>
                    </div>

                </div>
            </form>

            
                
                

        </AuthHolder>
    );
};

export default Signin;