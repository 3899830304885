import React, {useState, useEffect} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions, footballActions} from '../../redux/actions';
import {addUrlToPath, groupObjectsByKey, subStr, notify} from '../../utility/Helper1'
import { userSelect, homeScreenStatSelect, allTradeSelect, allRemoteSettingsSelect, footballSelect } from '../../redux/selectors/index';

import { MdOutlineTsunami, MdOutlineCircle, MdOutlineStadium, MdOutlineSports, MdOutlineSummarize } from "react-icons/md";
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import { BiFootball, BiSolidFlagAlt, BiPlus, BiMinus, BiRevision } from "react-icons/bi";
import NoData from "../../utility/customComponents/NoData";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import useLanguage from '../../utility/config/hooks/useLanguage';





const DashboardEvent = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const site_settings   
                                  = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);

    const footballData  = useSelector(footballSelect);

    const eachDataSize = 7

    const countries = groupObjectsByKey(footballData?.fixtures, 'league_country');
    const leagues   = groupObjectsByKey(footballData?.fixtures, 'league_name');

    const [refreshingFootballData, setRefreshingFootballData] = useState(false)

    const [countryIsLoading, setCountryIsLoading] = useState(false)
    const [leagueIsLoading, setLeagueIsLoading] = useState(false)

    const [currentCountryLists, setCurrentCountryLists] = useState(eachDataSize)
    const [countriesData, setCountriesData] = useState(countries?.slice(0, eachDataSize))

    const [currentLeagueLists, setCurrentLeagueLists] = useState(eachDataSize)
    const [leaguesData, setLeaguesData] = useState(leagues?.slice(0, eachDataSize))
    const [favouriteIsLoading, setFavouriteIsLoading] = useState(null)
    const usingLanguage = useLanguage()


    const showMoreOfCountry = (mathsSymbol) => {
        let newLength = eval(currentCountryLists+mathsSymbol+eachDataSize)
        const newData = countries?.slice(0, newLength)
        setCountriesData(newData)
        setCurrentCountryLists(newLength)
    }


    const showMoreOfLeague = (mathsSymbol) => {
        let newLength = eval(currentLeagueLists+mathsSymbol+eachDataSize)
        const newData = leagues?.slice(0, newLength)
        setLeaguesData(newData)
        setCurrentLeagueLists(newLength)
    }


    useEffect(() => {
        setCountriesData(countries?.slice(0, eachDataSize))
        setLeaguesData(leagues?.slice(0, eachDataSize))

    }, [footballData?.fixtures])


    const reloadFootballData = () => {
        setRefreshingFootballData(true)

        dispatch(footballActions.onLoadAll({}, "football/load-fixtures", response => {
            setRefreshingFootballData(false)
        }))

    }


    let addHiddenClass = props?.addHiddenClassByDefault ?? true


    const closeMobileSideCat = () => {
        dispatch(footballActions.onToggleBetSlip({openBetCategory: false}, response => {

            }),
        )
    }
    const openLeaguePage = (e, selected) => {
        e.preventDefault()

        const betCategoryBool = footballData?.bet_slip?.openBetCategory ?? false

        if(betCategoryBool){
            closeMobileSideCat()
        }

        let encodedUrl = `/league/${selected?.items?.[0]?.league_id}/${selected?.key}`

        navigate(encodedUrl)

    }

    const openCountryPage = (e, selected) => {
        e.preventDefault()

        const betCategoryBool = footballData?.bet_slip?.openBetCategory ?? false

        if(betCategoryBool){
            closeMobileSideCat()
        }

        let encodedUrl = `/country/${selected?.key}`

        navigate(encodedUrl)

    }



    


    const manageFavourite = (e, leagueId) => {

        e.preventDefault();

        if(!userData?.accountId){ notify('Please login to manage league', 2); return; }
        if(favouriteIsLoading){ return; }

        setFavouriteIsLoading(leagueId)

        dispatch(authActions.onLogin(`football/user/manage-leagues/${leagueId}`, {hello: 1}, response => {


            if(response.code !== 0){

                if(response.code == 1){

                    

                }else if(response.code === 2){

                }

            }
            
            setFavouriteIsLoading(null)

            // notify(response.msg, response.code)
           
        
        }),
    );

        
    };


    const leaguesArray = (userData?.favourite_leagues ?? '')?.split(',');

    return <>

        <div className={`popular__events__left ${addHiddenClass?'display991':''}`} style={{marginTop: !addHiddenClass?0:165}}>

            <div className="hide-scroll-bar-v">

                <div className="dark_bg_5 prematch__wrap">

                    <div className="nav flexify orange-bg-color-2" id="nav-tabpre" style={{paddingRight: 10, borderBottom: 'none'}}>
                        <div sstyle={{color: '#09ff8d'}} className="nav-link">

                            <div className="unchanged-white-color-1">
                                {usingLanguage['headlines']['league']}
                            </div>

                        </div>

                        <div onClick={reloadFootballData} className="unchanged-white-color-1 pointer">
                            {!refreshingFootballData && <BiRevision />}
                            {refreshingFootballData && <LoadingIcon active={refreshingFootballData} />}
                        </div>

                    </div>

                    <div className="tab-content">
                        
                        <div className="tab-pane fade show active" id="nav-homepre">
                            
                            {leaguesData?.length > 0 && <div className="prematch__scopre">
                                
                                {leaguesData?.map(item => {

                                    let hasFillStar = false;

                                    if (leaguesArray.includes(item?.items?.[0]?.league_id)) {
                                        hasFillStar = true
                                    }


                                    return <div style={{gap: 10}} className="prescore__items flexify">
                                        <div style={{flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start'}} onClick={(e) => openLeaguePage(e, item)} className="prescore__left pointer flexify">

                                            <span>
                                                <img src={item?.items?.[0]?.league_logo ?? require('../../images/soccer/globe.png')} width={15} />
                                            </span>

                                            <span className="core white-color-1" title={item?.key}>
                                                {subStr(item?.key)}
                                            </span>
                                        </div>
                                        <div className="prescore__right">

                                            <span>{item?.items?.length}</span>


                                            <div onClick={(e) => manageFavourite(e, item?.items?.[0]?.league_id)} className="pointer dark_text_4">
                                                
                                                {favouriteIsLoading !== item?.items?.[0]?.league_id && <>
                                                    {!hasFillStar && <AiOutlineStar />}
                                                    {hasFillStar && <AiFillStar style={{color: '#ff6600'}} />}

                                                </>}

                                                {favouriteIsLoading === item?.items?.[0]?.league_id && 
                                                    <LoadingIcon active={true} />
                                                }
                                            </div>

                                        </div>
                                    </div>})}

                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        {currentLeagueLists > eachDataSize && <div onClick={() => showMoreOfLeague('-')} className="pointer dark_text_4" style={{fontSize: 12, textAlign: 'right', paddingRight: 8, paddingTop: 7, paddingBottom: 7}}>
                                            <BiMinus size={14} />
                                        </div>}

                                        {leaguesData?.length !== leagues?.length && <div onClick={() => showMoreOfLeague('+')} className="pointer dark_text_4" style={{fontSize: 12, textAlign: 'right', paddingRight: 8, paddingTop: 7, paddingBottom: 7}}>
                                            <BiPlus size={14} />
                                        </div>}
                                    </div>

                                </div>}


                            {(leaguesData?.length ?? 0) == 0 && <div style={{textAlign: 'center', padding: 20, paddingTop: 0}}>
                                <NoData title="" />
                            </div>}
                            
                        </div>



                    </div>
                </div>

                {/*<div className="star__wrap">
                    <span><img src="./Sports Betting HTML Template - home_files/start.png" alt="img" /></span>
                    <span>Favorites</span>
                </div>*/}


                <div className="dark_bg_5 prematch__wrap">

                    <div className="nav flexify orange-bg-color-2" id="nav-tabpre" style={{paddingRight: 10, borderBottom: 'none'}}>
                        <div sstyle={{color: '#09ff8d'}} className="nav-link">

                            <div className="unchanged-white-color-1">
                                {usingLanguage['headlines']['nations']}
                            </div>

                        </div>
                    </div>

                    <div className="tab-content">
                        
                        <div className="tab-pane fade text-white show active" id="nav-homepre">
                            
                            {countriesData?.length > 0 && <div className="prematch__scopre">
                                
                                {countriesData?.map(item => <a href="#" onClick={(e) => openCountryPage(e, item)} className="prescore__items">
                                    <div className="prescore__left flexify">
                                        <span>
                                            <img src={item?.items?.[0]?.league_flag ?? require('../../images/soccer/globe.png')} width={15} />
                                        </span>
                                        <span className="core white-color-1" title={item?.key}>
                                            {subStr(item?.key)}
                                        </span>
                                    </div>
                                    <div className="prescore__right">
                                        <span>{item?.items?.length}</span>
                                    </div>
                                </a>)}

                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {currentCountryLists > eachDataSize && <div onClick={() => showMoreOfCountry('-')} className="pointer dark_text_4" style={{fontSize: 12, textAlign: 'right', paddingRight: 8, paddingTop: 7, paddingBottom: 7}}>
                                        <BiMinus size={14} />
                                    </div>}

                                    {countriesData?.length !== countries?.length && <div onClick={() => showMoreOfCountry('+')} className="pointer dark_text_4" style={{fontSize: 12, textAlign: 'right', paddingRight: 8, paddingTop: 7, paddingBottom: 7}}>
                                        <BiPlus size={14} />
                                    </div>}
                                </div>

                            </div>}


                            {(countriesData?.length ?? 0) == 0 && <div style={{textAlign: 'center', padding: 20, paddingTop: 0}}>
                                <NoData title="" />
                            </div>}
                            
                        </div>


                    </div>
                </div>


                {addHiddenClass && <div>
                    <Link to={'/banner'}>
                        <img width={'100%'} src={addUrlToPath(site_settings?.image_settings?.[0]?.image1)} alt="img" />
                    </Link>
                </div>}

            </div>

        </div>

    </>
};

export default DashboardEvent;