import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import BuyModal from '../../element/buy-modal'
import PriceArea from '../../charts/price-area'

import {tradeActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allTradeSelect, homeScreenStatSelect, allRemoteSettingsSelect, allDepositSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import NoData from "../../../utility/customComponents/NoData";
import TradeSubMenu from "./Trade-sub-menu";


const OnlineTrade = () => {
    const [show, setShow] = useState(false);


    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);    
    const finance = useSelector(homeScreenStatSelect);
    
    const [loading, setloading] = useState(false)

    useEffect(() => {

        setloading(true)

        dispatch(tradeActions.onGETTheTrades("trade/all", response => {
            setloading(false)
        }),
        )
    }, [])

    const tradesData = useSelector(allTradeSelect);

    const getConfirmedPayment = tradesData?.childs


    const theTenures = [
                {
                    title: 'SHORT TERM',
                    subTitle: 'Market Position'
                },
                {
                    title: 'LONG TERM',
                    subTitle: 'Asset Holdings'
                }
            ]

    let availab = userData?.demoAccount == 0?'':'Demo'

    return (
        <WrapUpDashboard pageTitle={`Available trades`}>

            <div className="card-header">
                <TradeSubMenu pageTitle={'Available Trades'}/>
            </div>

            <div className="row">


                {/*<div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6`}>
                    <div className={`flexify pointer chart-stat active-tenure`}>
                        <div>
                            <h4>item?.title</h4>
                            <p className="mb-1">item?.subTitle</p>
                        </div>
                        
                        <span>he</span>

                    </div>
                </div>*/}

                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    {finance?.map((item, index) => {
                            
                        let newArrb = item?.abbrRo;
                        
                        if(item?.name == 'Tether'){
                            newArrb = 'usdt';
                        }

                        let totalCapitals = 0
                        let totalProfits = 0

                        getConfirmedPayment?.filter(filterItem => filterItem?.currencyId == item.type)?.forEach((secongTtem, index) => {

                            totalCapitals += Number(secongTtem.capital)
                            totalProfits += Number(secongTtem.profit)

                        })

                        return <div className={`price-widget bg-${newArrb?.toLowerCase()}`}>
                                <>
                                    <div style={{position: 'relative', top: 40, left: 10}} className="price-content lexify">
                                        <div className="icon-title">
                                            <img style={{width: 30, height: 30}} src={item?.image} alt="img" />
                                            <span>{item?.name}</span>
                                        </div>

                                        <div className="lexify icon-title" style={{marginLeft: 5, paddingLeft: 0, isplay: 'flex', lignItems: 'flex-atart'}}>
                                            <h5 style={{margin: 0, marginTop: 10}}>Capital<br />{money(totalCapitals, item.type)}</h5>
                                            <h5 style={{margin: 0, marginTop: 10, marginRight: 15}}>Profit<br />{money(totalProfits, item.type)}</h5>
                                        </div>
                                    </div>
                                    <PriceArea />
                                </>
                        
                    </div>})}
                </div>





                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Available {availab} Trades</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped responsive-table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Method</th>
                                            <th>Txn_id</th>
                                            <th>Tenure</th>
                                            <th>Time</th>
                                            <th>Capital</th>
                                            <th>Day</th>
                                            <th>Profit</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        

                                        {getConfirmedPayment?.map((item, index) => {

                                            let image = finance?.filter((filitem, index) => filitem.type == item.currencyId)

                                        return <tr>
                                                    <td><span className="info-arrow"><i className="icofont-arrow-right"></i>
                                                        Trade</span>
                                                    </td>
                                                    <td className="coin-name">
                                                        <img style={{width: 30, height: 30}} src={image?.[0]?.image} alt="img" />
                                                    </td>
                                                    <td>
                                                        <span className="text-muted">
                                                            {item.txn_id}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="text-muted">
                                                            {theTenures?.[item?.tenure]?.title ?? ''}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        {item?.timeCreated}
                                                    </td>

                                                    <td className="text-danger">
                                                        {money(item.capital, item.type)}
                                                    </td>

                                                    <td>
                                                        {item?.growths}
                                                    </td>

                                                    <td className="text-danger">
                                                        {money(item.profit, item.type)}
                                                    </td>
                                                    <td>
                                                        <span className={`text-${usefulColors(item?.active)}`}>{item?.active == 1?'Active':'Inactive'}</span>
                                                    </td>
                                        </tr>})}
                                        

                                        {(getConfirmedPayment?.length ?? 0) == 0 && loading && <tr>
                                            <td colSpan={5} className="text-center">
                                                <LoadingIcon color={"green"} active={loading} />
                                            </td>
                                        </tr>}

                                    </tbody>
                                </table>

                                {(getConfirmedPayment?.length ?? 0) == 0 && !loading && <NoData subTitle="trade" />}

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        



        </WrapUpDashboard>
    );
};

export default OnlineTrade;