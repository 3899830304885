import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import {useSelector} from 'react-redux'

const SubMenu = (props) => {

    const site_settings = useSelector(allRemoteSettingsSelect)

    const location = useLocation()

    return (

        <div className="top__bottom__space" style={{marginTop: -5, margin: 0, padding: 6, paddingTop: 0, paddingBottom: 0}}>
            <div className="world__cup__area">

                <div className="world__tabs">
                    
                    <ul className="nav nav-pills mb-3">
                        
                        {props?.data?.map((item, index) => {

                            if(!item?.show){ return <></> }

                            return <li className="nav-item">
                                <Link to={item.url}>
                                    <button className={`nav-link ${location.pathname == item.url?'active':''}`} type="button">
                                        <span> 
                                            {item?.title}
                                        </span>
                                    </button>
                                </Link>
                            </li>
                        })}

                    </ul>

                </div>
            </div>
        </div>
    );
};

export default SubMenu;