import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import {addUrlToPath} from '../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';


import Header from './header'
import Sidebar from './sidebar'
import SiteWide from "../../utility/customComponents/SiteWide";
import ChatDelegator from '../../Livechats/ChatDelegator'

import WrapUpDashboard from './WrapUpDashboard'
import DashboardEvent from './dashboard-event'
import BetSlip from './betslip'
import DashboardFooter1 from './dashboard-footer-1'
import Modal from 'react-modal';

const WrapUpFootballArea = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const [openLivechat, setOpenLivechat] = useState(false)

    const updateOpenry = () => {

        if(openLivechat){
            // window.location.reload()
        }

        setOpenLivechat(el => !el)

    }



    const betSlipMobileModalStyle = {

        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust opacity as needed
            zIndex: 99999, // Ensure it's above other elements
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            padding: 0
        },
        content: {
            top: 0,
            right: 0,
            left: 'auto',
            bottom: 'auto',
            backgroundColor: '#141c33', // Adjust opacity as needed
            border: '0.5px solid #5f677f',
            width: '300px',
            height: '100vh', // Adjust as needed
            overflow: 'auto',
            margin: 0,
            padding: 0,
        },

    };

    let hideEvent = false
    let mainContentGrid = 9

    let overAllGrid1 = 10
    let overAllGrid2 = 9

    if(props?.hideEventPlace){
        hideEvent = true
        mainContentGrid = 12
    }

    if(props?.hideBetSlip){
        overAllGrid1 = 12
        overAllGrid2 = 12
    }

    return (
        <WrapUpDashboard pageTitle={props?.pageTitle ?? "My dashboard"}>

            

            <div class={`col-xxl-${overAllGrid1} col-xl-${overAllGrid2} col-lg-${overAllGrid2}`}>
                {3>2 && <div class="left__site__section" style={{padding: 0, margin: 0, width: '100%'}}>
                    <div id="myTabContentmain">

                        <div class="popular__events__body main-bg-color-1">
                            <div class="container-fluid p-0">
                                <div class="row g-0">




                                    {!hideEvent && <div class="col-xxl-3 col-xl-3 col-lg-3">
                                        <DashboardEvent />
                                    </div>}



                                    <div class={`col-xxl-${mainContentGrid} col-xl-${mainContentGrid} col-lg-${mainContentGrid}`}>
                                        <div className="" style={{height: '100%', position: 'relative', top: 150}}>
                                            
                                            {props.children}

                                            {!props?.hideGeneralFooter && <DashboardFooter1 />}
                    
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>

                    </div>
                </div>}
            </div>




            {!props?.hideBetSlip && <div class="col-xxl-2 col-xl-3 col-lg-3">
                <BetSlip />
            </div>}






        </WrapUpDashboard>
    );
};

export default WrapUpFootballArea;