import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'


import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../../redux/selectors/index';
import SiteWide from "../../../utility/customComponents/SiteWide";
import TimeFormatter from "../../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {byTomorrowsDate, selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../../utility/Helper1';

import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import Balance from "../Fragments/Balance";
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../../redux/actions';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';
import NoData from "../../../utility/customComponents/NoData";

import Ticker1 from "../../../utility/customComponents/Ticker1";
import Ticker2 from "../../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer } from "react-icons/md";
import DashboardFooter1 from '../../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import MatchesLists from '../../layout/MatchesLists';

import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import ShowPlayableMatches from '../../layout/ShowPlayableMatches';




const MyLeague = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);
    const [matchResultLoading, setMatchResultLoading] = useState(false)

    let myLeaguesArray = userData?.favourite_leagues?.split(',');
    const myLeagues = footballData?.fixtures?.filter(item => myLeaguesArray?.some(eachItem => eachItem === item.league_id));

    const dataToUse = byNextDate(myLeagues)

    return ( <>
        <WrapUpFootballArea pageTitle={"My leagues"}>

            <ShowPlayableMatches dataToUse={myLeaguesArray} titleLangKey="my_league" heroTitle={"My leagues"} />

        </WrapUpFootballArea>

        </>
    );
};

export default MyLeague;