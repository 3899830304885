import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from './WrapUpFootballArea'


import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../redux/selectors/index';
import SiteWide from "../../utility/customComponents/SiteWide";
import TimeFormatter from "../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {displayTime, selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../utility/Helper1';

import copy from 'copy-to-clipboard';
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../redux/actions';
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../utility/customComponents/Ticker1";
import Ticker2 from "../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer, MdOutlineSearch } from "react-icons/md";
import DashboardFooter1 from '../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";
import NoData from "../../utility/customComponents/NoData";
import { ProgressBar } from  'react-loader-spinner'
import { AiFillCloseCircle } from "react-icons/ai";
import useLanguage from '../../utility/config/hooks/useLanguage';



const ShowMatches = (props) => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);

    const EACHHIGHLIGHT_LIMIT = 10

    const [matchData, setMatchData]     = useState(props?.data)
    const [currentMatchDataLength, setCurrentMatchDataLength] = useState(EACHHIGHLIGHT_LIMIT)
    const [searchInputToUse, setSearchInputToUse] = useState('')
    const [itemToDelete, setItemToDelete] = useState(null)
    const usingLanguage = useLanguage()

    useEffect(() => {
        setMatchData(props?.data?.slice(0, EACHHIGHLIGHT_LIMIT))
    }, [props?.data])


    const showMoreOfMainData = (mathsSymbol) => {
        let newLength = eval(currentMatchDataLength+mathsSymbol+EACHHIGHLIGHT_LIMIT)
        const newData = props?.data?.slice(0, newLength)
        setMatchData(newData)
        setCurrentMatchDataLength(newLength)
    }



    const dataToShow = matchData?.filter(

              item => {

                return item?.teams_home_name?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.teams_away_name?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.fixture_id?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.league_name?.match(new RegExp(searchInputToUse, "i")) || 
                        item?.league_country?.match(new RegExp(searchInputToUse, "i"))

            }
        )

    const deleteGame = (objToDetete) => {

        if(itemToDelete){ return; }

        setItemToDelete(objToDetete.id)

        dispatch(footballActions.onGetUserGames(`football/user/deleteBet/${objToDetete.id}`, response => {

            notify(response.msg, response.code)
            setItemToDelete(null)

        }))
    }


    return ( <>

        <div className="main__body__wrap left__right__space" style={{paddingLeft: 6, paddingRight: 6, marginTop: 15}}>

                <div className="live__heightlight mb__30 dark_bg_5" style={{marginBottom: 6}}>

                    <div className="section__title dark_bg_5" style={{position: 'relative'}}>

                        <h4>
                            {usingLanguage?.['menu2']?.[props?.heroTitle?.toLowerCase()] ?? props?.heroTitle}
                        </h4>

                        <div class="secondSearchContainer">
                            <input value={searchInputToUse} onChange={e => setSearchInputToUse(e.target.value)} type="text" placeholder="Search..." />
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} class="search">
                                <MdOutlineSearch  size={20} />
                            </div>
                        </div>

                    </div>


                    {!props?.showLoading && <div className="height__table dark_bg_5">
                        <div className="tab-content dark_bg_5" id="nav-tabContentheight">

                            <div className="tab-pane fade text-white show active" id="height1" role="tabpanel" aria-labelledby="nav-home-tabpre" tabindex="0">
                                
                                <div className="main__table" style={{width: '100%'}}>


                                    <div className="table__wrap">


                                        {dataToShow?.map((eachMatchResult, index) => {

                                            return <div style={{apaddingBottom: 40}}>
                                                                
                                                                    <div className="flexify dar white-color-1" style={{padding: 4, paddingRight: 10, paddingLeft: 10}}>
                                                                        
                                                                        {!props?.myGamesPage && <>
                                                                            <div>{eachMatchResult?.league_country}: {eachMatchResult?.league_name}</div>
                                                                            <div>{eachMatchResult?.league_round}</div>
                                                                        </>}

                                                                        {props?.myGamesPage && <>
                                                                            <div className="flexify" ssstyle={{fontSize: 11, marginTop: 20}}>
                                                                                
                                                                                <div>
                                                                                    Prediction - {eachMatchResult?.['home-scores']}:{eachMatchResult?.['away-scores']}
                                                                                    {/*<br />
                                                                                    Odd played: 9%*/}
                                                                                </div>

                                                                                <div className="bg-danger">
                                                                                    {(new Date(eachMatchResult.fixture_datetime) > new Date()) && !itemToDelete && <span onClick={() => window.confirm('Delete?') && deleteGame(eachMatchResult)} style={{marginLeft: 5}} className="pointer text-danger"><AiFillCloseCircle size={20} /></span>}
                                                                                    {itemToDelete == eachMatchResult?.id && <LoadingIcon active={true} />}


                                                                                </div>

                                                                            </div>

                                                                            <div>{money(eachMatchResult?.amount, eachMatchResult?.currency)}
                                                                                {/*<br />Estimated winning: $300*/}
                                                                            </div>
                                                                        </>}

                                                                    </div>

                                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>



                                                                        <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                                            <div className="dark_bg_ text-center" style={{width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5}}>
                                                                                <img height={50} src={eachMatchResult?.teams_home_logo} alt={eachMatchResult?.teams_home_name} />
                                                                            </div>

                                                                            <div className="white-color-1" style={{marginTop: 10}}>
                                                                                {eachMatchResult?.teams_home_name}
                                                                            </div>
                                                                        </div>

                                                                        <div className="text-center white-color-1" style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                                            <div className="" style={{fontSize: 10}}>
                                                                                {displayTime(eachMatchResult?.fixture_datetime)}
                                                                                
                                                                                {props?.myGamesPage && <>
                                                                                    <br />
                                                                                    <span>Expected win: {Number(eachMatchResult?.['amount'] + ((eachMatchResult?.['percentage-winning']/100) * eachMatchResult?.['amount']))?.toFixed(2)}</span>
                                                                                </>}

                                                                            </div>

                                                                            <h1 className="white-color-1" style={{marginTop: 10, fontSize: 50}}>
                                                                                {eachMatchResult?.goals_home} - {eachMatchResult?.goals_away}
                                                                            </h1>

                                                                            <div className="text-success" style={{fontSize: 10, marginTop: 0}}>

                                                                                {eachMatchResult?.fixture_status_short == 'LIVE' && <div>
                                                                                    <ProgressBar
                                                                                      visible={true}
                                                                                      height="30"
                                                                                      width="120"
                                                                                      borderColor="green"
                                                                                      barColor="#9bc9ff"
                                                                                      ariaLabel="progress-bar-loading"
                                                                                      wrapperStyle={{}}
                                                                                      wrapperClass=""
                                                                                      />
                                                                                </div>}

                                                                                {eachMatchResult?.fixture_status_long}
                                                                            </div>

                                                                        </div>


                                                                        <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                                            <div className="dark_bg_ text-center" style={{width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5}}>
                                                                                <img height={50} src={eachMatchResult?.teams_away_logo} alt={eachMatchResult?.teams_away_name} />
                                                                            </div>

                                                                            <div className="white-color-1" style={{marginTop: 10}}>
                                                                                {eachMatchResult?.teams_away_name}
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                    {!props?.myGamesPage && <div className="text-center white-color- dark_text_4" style={{fontSize: 11, marginTop: 20}}>
                                                                        {eachMatchResult?.fixture_venue_name} {eachMatchResult?.fixture_venue_city}
                                                                        <p>{eachMatchResult?.fixture_referee}</p>
                                                                    </div>}

                                                                    

                                                                </div>})}

                                        {(dataToShow?.length ?? 0) === 0 && <NoData textAreaClassName="white-color-1" title={props?.title ?? "No data is available."} />}



                                        {searchInputToUse == '' && <div className="table__footer" style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                                            
                                        
                                            {currentMatchDataLength > EACHHIGHLIGHT_LIMIT && <span onClick={() => showMoreOfMainData('-')} className="lobby pointer">
                                                <BsRewind className="dark_text_3" />
                                            </span>}

                                            {props?.data?.length > currentMatchDataLength && <span onClick={() => showMoreOfMainData('+')} className="lobby pointer">
                                                <BsFastForward className="dark_text_3" />
                                            </span>}

                                        </div>}

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>}


                    <div className="text-center" style={{paddingTop: 30, paddingBottom: 30}}>
                        <LoadingIcon width={50} active={props?.showLoading} />
                    </div>


                </div>






            </div>
        </>
    );
};

export default ShowMatches;