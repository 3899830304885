import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import BuyModal from '../../element/buy-modal'
import PriceArea from '../../charts/price-area'

import {authActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, loanSelect, homeScreenStatSelect, allRemoteSettingsSelect, allDepositSelect } from '../../../redux/selectors/index';
import Helper1, {capitalizeFirstLetter, niceDate, notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'
import SubMenu from '../../layout/SubMenu';

import NoData from "../../../utility/customComponents/NoData";
 import PerfectScrollbar from 'react-perfect-scrollbar'

import parse from 'html-react-parser';
import { stripHtml } from "string-strip-html";
import ReferralWrapper from "./ReferralWrapper";


const eachDataMax = 20

const DownlineList = () => {
    const [show, setShow] = useState(false);

    const [viewingLevel, setViewingLevel]     = useState(1)
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [mainUplineData, setMainUplineData]    = useState({})
    const [lengthToShow, setLengthToShow]    = useState(eachDataMax)


    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);    
    const finance = useSelector(homeScreenStatSelect);
    
    const [loading, setLoading] = useState(false)


    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.user_dashboard_base_url+'/signup?ref='+ myRefCode
    const copyRefCode = (copythis) => {
        copy(copythis)
        notify('Copied', 1)
    }


    const showMore = () => {
        setLengthToShow(el => el + eachDataMax)
    }


    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
console.log('response', response)

            setLoading(false)

            setRefDataRaw(response?.data)
            setRefData(response?.data?.referrals)
            setMainUplineData(response?.data?.upline)

        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])


    const originalDataLength = refData?.['level' + viewingLevel];
    const allReferralData = originalDataLength?.slice(0, lengthToShow)


    return (

        <ReferralWrapper pageTitle={"Referral Management"}>

            <div className="row">



                <div className="col-xxl-12 col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Available Referrals</h4>
                        </div>
                        <div className="card-body">
                            <div className="balance-widget trade-balance">
                                


                                {allReferralData?.map((eachDownline, index) => {

                                    let refWithdrawnFromMe = eachDownline?.['refWithdrawnFromMe_' + viewingLevel] ?? 0;
                                    let rebWithdrawnFromMe = eachDownline?.['rebateWithdrawnFromMe_' + viewingLevel]?? 0;
                                    let totalEarning = refWithdrawnFromMe + rebWithdrawnFromMe;

                                    return <li>
                                        <h5>{capitalizeFirstLetter(eachDownline?.username)}</h5>
                                        <div className="text-right">
                                            <h6>{niceDate(eachDownline?.date)}</h6>
                                            {totalEarning > 0 && <span>{`${money(totalEarning, finance?.[0]?.type)}`}</span>}

                                        </div>
                                    </li>})}

                            </div>
                        </div>
                    </div>
                </div>



                {(originalDataLength?.length ?? 0) > lengthToShow && <div className="col-12">
                    <button
                        onClick={showMore}
                        className="btn btn-success pl-5 pr-5 waves-effect">
                        
                        More

                    </button>
                </div>}

            </div>

        </ReferralWrapper>
    );
};

export default DownlineList;