export default {
  menu1: {
    deposit:        'Depósito',
    wallet:         'Cartera',
    invitation:     'Invitación',
    logout:         'Cerrar sesión',
    profile:        'Perfil',
    settings:       'Configuración',
    sign_in:        'Iniciar sesión',
    sign_up:        'Registrarse',
    referral:       'Referido',
    earn_salary:    'Ganar salario',
    top_up:         'Recargar',
    faq:            'Preguntas frecuentes',
    terms:          'Términos y condiciones',
    privacy:        'Política de privacidad',
  },
  menu2: {
    home:           'Inicio',
    search:         'Buscar',
    today:          'Hoy',
    tomorrow:       'Mañana',
    my_league:      'Mi liga',
    bonus_odd:      'Cuota bonificada',
    live:           'En vivo',
    cancelled:      'Cancelado',
    results:        'Resultados',
  },
  headlines:{
    match_highlights:   'Destacados del partido',
    general_info:       'Información general',
    trending_results:   'Resultados en tendencia',
    matches:            'Partidos',
    live_account:       'Cuenta en vivo',
    league:             'Liga',
    nations:            'Naciones',
    betslip:            'Boleto de apuestas',
    slip:               'Talonario',
    my_bets:            'Mis apuestas',
    fund:               'Fondo',
  },
  page: {
    sign_in:{
      page_title:               'Iniciar sesión en la cuenta',
      username:                 'Nombre de usuario',
      password:                 'Contraseña',
      remember_me:              'Recuérdame',
      forgot_password:          'Olvidé mi contraseña',
      dont_have_account:        'No tengo una cuenta',
    },
    sign_up:{
      page_title:               'Crear una cuenta',
      username:                 'Nombre de usuario',
      password:                 'Contraseña',
      email:                    'Correo electrónico',
      country:                  'País',
      select_country:           'Seleccionar país',
      phone:                    'Teléfono',
      account_security_pin:    'PIN de seguridad de la cuenta',
      agree_to_the_terms_and_conditions: 'Acepto los términos y condiciones',
    },
    index:{
      page_title:              '',
      match_detail:       'Detalle del partido',
      trending_results:    'Resultados en tendencia',
    },
    search:{
      title:              'Resultado de búsqueda',
      match_detail:       'Detalle del partido',
      enter_team_name:    'Introduzca nombre del equipo, ID de apuesta o información de la liga',
    },
  },
  random_text: {
    betslip:{
      empty_slip_note: 'Tu boleto de apuestas está vacío.<br />Por favor, realiza una o más selecciones para colocar una apuesta.',
      multiple_bet_note: 'Las apuestas múltiples se colocan por separado y las ganancias/pérdidas se procesan individualmente',
      place_bet: 'Realizar apuesta',
      total_odds: 'Cuotas totales',
      total_bet_amount: 'Cantidad total apostada',
      possible_winnings: 'Ganancias posibles',
    },
    empty_data: {
      no_data: 'No hay datos disponibles.',
    }
  }
};
