import React, {useState, useEffect} from "react";
import {Images} from "./index";
import {addUrlToPath} from '../../utility/Helper1'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';


function NoData({image, type, title, subTitle, textAreaStyle, textAreaClassName}) {

    const site_settings = useSelector(allRemoteSettingsSelect);

    return <div className="" style={{display: 'flex', marginBottom: 40, marginTop: 40, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
        <div className="text-center" style={{marginTop: 40, marginBottom: 40, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <Images height={60} src={image ?? addUrlToPath(site_settings?.image_settings?.[0]?.image4)} />
            <div style={{marginTop: 30}}>
                <h6 style={{...(textAreaStyle ?? {})}} className={`prem text-dark text-center ${textAreaClassName ?? ''}`}>{title ?? `No ${subTitle} information`}</h6>
            </div>
        </div>
    </div>
}

export default NoData;

