import UK from './UK'
import Turkey from './Turkey'
import China from './China'
import France from './France'
import India from './India'
import Spain from './Spain'


export default  {
  UK,
  Turkey,
  China,
  France,
  India,
  Spain
};