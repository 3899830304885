import React from 'react';
import WrapUpFootballArea from '../../../layout/WrapUpFootballArea'

import SubMenu from '../../../layout/SubMenu';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../../../redux/selectors/index';

const ProfileWrapper = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const menu = [
            {
                title: 'New fund',
                url: '/fund',
                show: true
            },
            {
                title: 'History',
                url: '/fund-history',
                show: true

            }
        ]

    return (
        

        <WrapUpFootballArea hideEventPlace={"true"} pageTitle={props?.pageTitle}>

            <SubMenu data={menu} />

            <div className="tb-content" style={{paddingLeft: 6, paddingRight: 6}}>

                {props.children}

            </div>
            

        </WrapUpFootballArea>


    );
};

export default ProfileWrapper;