import { useState } from 'react';
import { allLocalSettingsSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {allLanguages} from '../../Helper1';
import Languages from '../lang';

const useBackendcontentSettings = () => {

  const local_settings                                = useSelector(allLocalSettingsSelect);
  const site_settings                                 = useSelector(allRemoteSettingsSelect);

  const currentLanguageObj = local_settings?.activeLanguageObject ?? allLanguages()?.[0]

  const contentHolder = site_settings?.content_settings?.filter(item => item.language_code == currentLanguageObj?.language)

console.log('site_settings?.content_settings', site_settings?.content_settings)
  return contentHolder

};

export default useBackendcontentSettings;