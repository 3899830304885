import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {useHistory, useNavigate} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import { AiFillCloseCircle } from "react-icons/ai";



const AuthHolder = (props) => {

       const navigate                                       = useNavigate();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    
    const imageUrl = addUrlToPath(site_settings?.image_settings?.[0]?.image3);

    useEffect(() => {

        document.body.style.background = `linear-gradient(23deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%), url(${imageUrl})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundPosition = 'center';

        // Cleanup function to revert styles on component unmount
        return () => {
            document.body.style.background = ''
        }
        
      }, []); 

    return (
        
        <SiteWide pageTitle={props.title} themeColor={"#01131d"}>
            <div className="authincation section-padding" style={{}}>
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center" style={{mharginTop: 210}}>
                        <div className="col-xl-6 col-md-6">
                            
                            <div className="mini-logo text-center my-4">
                                {/*<a href={site_settings?.site_settings?.homepage_url}>
                                    <img style={{height: 50}} src={addUrlToPath(site_settings?.site_settings?.logo)} alt="logo"/>
                                </a>*/}
                                {/*<h4 className="card-title mt-3">{props.title}</h4>*/}
                                {/*{props?.title2 && <p>{props?.title2}</p>}*/}
                            </div>

                            <div className="auth-form card auth-main-cover" style={{backgroundColor: 'transparent'}}>
                                <div className="ard-body" style={{}}>

                                    <div class="background">
                                        <div class="shape"></div>
                                        <div class="shape"></div>
                                    </div>

                                    {/*<div onClick={() => navigate(-1)} className={"pointer"} style={{position: 'absolute', top: 2, right: 88, zIndex: 9}}>
                                        <AiFillCloseCircle color="#fff" size={30} />
                                    </div>
*/}
                                    {props.children}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </SiteWide>
    );
};

export default AuthHolder;