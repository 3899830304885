import 'react-perfect-scrollbar/dist/css/styles.css';
import '../src/css/style.css'
import '../src/css/soccer.css'
// import '../src/css/authentication-page.css'
import '../src/css/mobile_menu.css'
import '../src/css/color.css'

import Router from './jsx/router'
import { SnackbarProvider, enqueueSnackbar } from 'notistack';



function App() {
  return (
    <>
      
      <Router />
      
    </>
  );
}

export default App;
