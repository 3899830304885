import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from './WrapUpFootballArea'


import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../redux/selectors/index';
import SiteWide from "../../utility/customComponents/SiteWide";
import TimeFormatter from "../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../utility/Helper1';

import copy from 'copy-to-clipboard';
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../redux/actions';
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../utility/customComponents/Ticker1";
import Ticker2 from "../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer } from "react-icons/md";
import DashboardFooter1 from '../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";
import NoData from "../../utility/customComponents/NoData";




const MatchesLists = (props) => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);

    
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [loadingDownlines, setLoadingDownlines]     = useState(false)
    const [recentBetsLoading, setRecentBetsLoading] = useState(false)
    const [matchResultLoading, setMatchResultLoading] = useState(false)

    const EACHHIGHLIGHT_LIMIT = 10

    const [matchData, setMatchData]     = useState(byNextDate(props?.data)?.slice(0, EACHHIGHLIGHT_LIMIT))
    const [currentMatchDataLength, setCurrentMatchDataLength] = useState(EACHHIGHLIGHT_LIMIT)


    const [homeFixture, setHomeFixture]   = useState(byNextDate(props?.data));
    
    useEffect(() => {
        setHomeFixture(byNextDate(props?.data));
        setMatchData(byNextDate(props?.data)?.slice(0, EACHHIGHLIGHT_LIMIT))
    }, [props?.data])


    const showMoreOfMainData = (mathsSymbol) => {
        let newLength = eval(currentMatchDataLength+mathsSymbol+EACHHIGHLIGHT_LIMIT)
        const newData = byNextDate(props?.data)?.slice(0, newLength)
        setMatchData(newData)
        setCurrentMatchDataLength(newLength)
    }



    const showAllOdds = (thisFixture) => {
        
        dispatch(footballActions.onToggleBetSlip({openAllOddsModal: true, oddsDataBeingViewd: thisFixture}, response => {

            }),
        )

    }


    const addFixtureToCart = (thisFixture, thisOdds) => {

        dispatch(footballActions.onAddFixtureToBetSlip({...thisFixture, selectedOdd: thisOdds}, response => {
                notify('Bet added to slip', 3)
            }),
        )

    }


    const copyId = (fixture_id) => {
        notify('Bet Id copied', 2)
        copy(fixture_id)
    }

    return ( <>
            <div className="table__wrap">


                {matchData?.map((thisFixture, index) => {

                    let randomOdds = selectRandomObjects(thisFixture?.exactScoreOdd, 6, `${thisFixture?.teams_home_name}${thisFixture?.teams_away_name}`);

                    return <div className="table__items b__bottom flexify">
                        <div className="t__items" style={{flex: 1}}>
                            <div onClick={() => showAllOdds(thisFixture)} className="t__items__left pointer">

                                {footballData?.bet_slip?.showBetId && <div className="pointer dark_text_4" onClick={() => copyId(thisFixture?.fixture_id)} style={{fontSize: 10}}>Bet ID: <span style={{textDecoration: 'underline', color: '#868ea7'}}>{thisFixture?.fixture_id}</span></div>}

                                <h6 className="white-color-1">
                                    <img height={15} src={thisFixture?.teams_home_logo} alt={thisFixture?.teams_home_name} /> {thisFixture?.teams_home_name}
                                </h6>
                                <span className="text white-color-1">
                                    <img height={15} src={thisFixture?.teams_away_logo} alt={thisFixture?.teams_away_name} /> {thisFixture?.teams_away_name}
                                </span>

                                <p>

                                    <a title={thisFixture?.fixture_status_long} href="#">
                                        {thisFixture?.fixture_status_short}
                                    </a>
                                    <span style={{marginLeft: 5}}>
                                        <TimeFormatter className="today white-color-1" inputTime={thisFixture?.fixture_datetime} />
                                    </span>

                                </p>

                                

                            </div>
                        </div>

                        <div onClick={() => showAllOdds(thisFixture)} className="cart__point" style={{}}>

                               <BsArrowUpRightSquare className="dark_text_4 pointer" size={22} />


                        </div>


                        <div className="mart__point__items" style={{flex: 5}}>
                            {randomOdds?.map(eachOdd => {

                                let addedStyle = {position: 'relative'}

                                let testExistenceInSlip = footballData?.bet_slip?.betcart?.some(item => item?.selectedOdd?.uniqueId == eachOdd?.uniqueId)
                                if(testExistenceInSlip){
                                    addedStyle = {...addedStyle, backgroundColor: '#ff6600'}
                                }

                                if(eachOdd?.favourite == 1){
                                    addedStyle = {...addedStyle, border: '1px solid #fed300'}
                                }

                                return <span style={addedStyle} onClick={() => addFixtureToCart(thisFixture, eachOdd)} className="point__box pointer">
                                        {`${eachOdd?.['home-scores']}:${eachOdd?.['away-scores']}`} {Number(eachOdd?.['percentage-winning'])?.toFixed(1)}%

                                        {eachOdd?.favourite == 1 && <img style={{width: 20, position: 'absolute', top: -12, right: -8}} src={require('../../images/soccer/crown.png')} />}

                                    </span>
                            })}
                        </div>
                    

                </div>})}

                {(matchData?.length ?? 0) === 0 && <NoData textAreaClassName="white-color-1" title={props?.title ?? "No data is available."} />}



                <div className="table__footer" style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                    
                    

                    {currentMatchDataLength > EACHHIGHLIGHT_LIMIT && <span onClick={() => showMoreOfMainData('-')} className="lobby pointer">
                        <BsRewind className="dark_text_3" />
                    </span>}

                    {props?.data?.length > currentMatchDataLength && <span onClick={() => showMoreOfMainData('+')} className="lobby pointer">
                        <BsFastForward className="dark_text_3" />
                    </span>}

                </div>
            </div>

        </>
    );
};

export default MatchesLists;