import React, {useState, useEffect} from "react";
import {Images} from "./index";
import {systemColor} from '../../utility/styles';
import { useSelector } from 'react-redux';
import { allRemoteSettingsSelect } from '../../redux/selectors/index';



function PinAuth(props) {

    const {setWithdrawalPin, setGoogleAuth, userData, resultingObjMethod} = props
    const systemColorManager = systemColor()
    const site_settings = useSelector(allRemoteSettingsSelect);

    const op_se = site_settings.operational_settings

    const [securityState, setSecurityState] = useState({});

    const updateSecurityState = (labelHolder, newValue) => {

        let oldSeState = securityState
        oldSeState[labelHolder] = newValue

        resultingObjMethod({...oldSeState})
        setSecurityState({...oldSeState})

    }


    return <>
       

        {(op_se.use_pin == 1) && ((userData?.enforce_pin_usage ?? 1) == 1) && <div class="col-xl-12">
            <label class="form-label">
                Enter Your Security Pin
            </label>
            <input 
                type="password"
                class="form-control" 
                placeholder={"Security Pin"} 
                maxLength={op_se?.security_pin_length}
                required=""
                autoComplete={"new-password"}
                onChange = {e => updateSecurityState('withdrawalPin', e.target.value) }
            />
        </div>}


        {(((userData?.enforce_google_auth_usage ?? 1) == 1) && op_se.use_google_auth == 1) && <div class="col-xl-12">
            <label class="form-label">
                Enter Your Google Auth Code
            </label>
            <input 
                type="password"
                class="form-control" 
                placeholder={"Google Auth Code"} 
                required=""
                autoComplete={"new-password"}
                onChange = {e => updateSecurityState('googleAuth', e.target.value) }
            />
        </div>}




        {(op_se.use_old_password == 1) && ((userData?.enforce_password_as_auth_usage ?? 1) == 1) && <div class="col-xl-12">
            <label class="form-label">
                Enter Your Account Password
            </label>
            <input 
                type="password"
                class="form-control" 
                placeholder={"Account Password"} 
                required=""
                autoComplete={"new-password"}
                onChange = {e => updateSecurityState('oldPassword', e.target.value) }
            />
        </div>}



    </>

}

export default PinAuth;