import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions, settingActions} from '../../redux/actions';
import {addUrlToPath, notify} from '../../utility/Helper1'
import { userSelect, homeScreenStatSelect, allTradeSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';

import { MdOutlineTsunami, MdOutlineCircle, MdOutlineStadium, MdOutlineSports, MdOutlineSummarize } from "react-icons/md";
import Slider from "react-slick";
import parse from 'html-react-parser';
import useLanguage from '../../utility/config/hooks/useLanguage';
import useBackendcontentSettings from '../../utility/config/hooks/useBackendcontentSettings';





const DashboardFooter = () => {

    const dispatch = useDispatch();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    
    const rightArrow = require('../../images/soccer/rightarrow.png')
    const navigate = useNavigate()
    const userData = useSelector(userSelect);

    const usingLanguage = useLanguage()
    const usingContentSetting = useBackendcontentSettings()


    const socials = [
  {
    title: 'facebook',
    uri: site_settings?.site_settings?.facebook,
    icon: 'icofont-facebook',
    color: '#3b5998', // Facebook blue
  },
  {
    title: 'instagram',
    uri: site_settings?.site_settings?.instagram,
    icon: 'icofont-instagram',
    color: '#e1306c', // Instagram pink
  },
  {
    title: 'twitter',
    uri: site_settings?.site_settings?.twitter,
    icon: 'icofont-twitter',
    color: '#55acee', // Twitter blue
  },
  {
    title: 'telegram',
    uri: site_settings?.site_settings?.telegram,
    icon: 'icofont-telegram',
    color: '#0088cc', // Telegram blue
  },
  {
    title: 'whatsapp',
    uri: site_settings?.site_settings?.whatsapp,
    icon: 'icofont-whatsapp',
    color: '#25d366', // WhatsApp green
  },
];



    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())

        dispatch(settingActions.onManageLocalSettings({lastPrivateUrl: null}, response => {

        }))

        notify('Logout successful', 1)
    }

    const headerMenu = [
            {
                title: usingLanguage['menu2']['home'],
                whenClicked: () => {navigate('/')},
                routeFocused: '/'
            },
            {
                title: usingLanguage['menu2']['search'],
                whenClicked: () => {navigate('/search')},
                routeFocused: '/search'
            },
            {
                title: usingLanguage['menu2']['today'],
                whenClicked: () => {navigate('/today')},
                routeFocused: '/today'
            },
            {
                title: usingLanguage['menu2']['tomorrow'],
                whenClicked: () => {navigate('/tomorrow')},
                routeFocused: '/tomorrow'

            },
            {
                title: usingLanguage['menu2']['my_league'],
                whenClicked: () => {navigate('/my-leagues')},
                routeFocused: '/my-leagues'
            },
            {
                title: usingLanguage['menu2']['bonus_odd'],
                whenClicked: () => {navigate('/bonus-odd')},
                routeFocused: '/bonus-odd'
            },
            {
                title: usingLanguage['menu2']['live'],
                whenClicked: () => {navigate('/live')},
                routeFocused: '/live'
            },
            {
                title: usingLanguage['menu2']['cancelled'],
                whenClicked: () => {navigate('/cancelled')},
                routeFocused: '/cancelled'
            },
            {
                title: usingLanguage['menu2']['results'],
                whenClicked: () => {navigate('/results')},
                routeFocused: '/results'
            },

        ]





    const mobileMenu = [
            {
                title: usingLanguage['menu1']['top_up'],
                whenClicked: (e) => {e.preventDefault(); navigate('/fund')},
                onlyOnMobile: false,
                show: true
            },
            {
                title: usingLanguage['menu1']['wallet'],
                whenClicked: (e) => {e.preventDefault(); navigate('/wallet')},
                routeFocused: '/wallet',
                onlyOnMobile: false,
                show: true
            },
            {
                title: usingLanguage['menu1']['referral'],
                whenClicked: (e) => {e.preventDefault(); navigate('/invitation-link')},
                routeFocused: '/invitation-link',
                onlyOnMobile: false,
                show: true
            },
            {
                title: usingLanguage['menu1']['my_account'],
                whenClicked: (e) => {e.preventDefault(); navigate('/settings-profile')},
                routeFocused: '/settings-profile',
                onlyOnDesktop: true,
                show: true
            },
            {
                title: usingLanguage['menu1']['earn_salary'],
                whenClicked: (e) => {e.preventDefault(); navigate('/settings-salary')},
                routeFocused: '/settings-salary',
                onlyOnDesktop: true,
                show: true
            },
            {
                title: usingLanguage['menu1']['settings'],
                whenClicked: (e) => {e.preventDefault(); navigate('/settings-password')},
                routeFocused: '/settings-password',
                onlyOnMobile: false,
                show: true
            },
            {
                title: 'Logout',
                whenClicked: (e) => {

                        e.preventDefault(); 
                        doLogOutNowNow()
                        
                    },

                routeFocused: '',
                onlyOnMobile: false,
                show: userData?.accountId
            },
            {
                title: 'Sign in',
                whenClicked: (e) => {e.preventDefault(); navigate('/signin')},
                onlyOnMobile: true,
                show: !userData?.accountId
            },
            {
                title: 'Sign up',
                whenClicked: (e) => {e.preventDefault(); navigate('/signup')},
                onlyOnMobile: true,
                show: !userData?.accountId
            }
        ]




    // match result
    const matchResultSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1
              }
            }
        ]
    };

    return <>

        <footer class="dark_bg_5 footer__section main__footer__section" style={{marginLeft: 6, marginRight: 6, borderRadius: 10}}>
            <div class="container-fluid p-0">


                <div class="footer__content__section" style={{marginTop: 0, padding: 15, paddingTop: 30, marginBottom: 50}}>
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="footer__content__items">
                                {parse(usingContentSetting?.[0]?.con1 ?? '')}
                                {parse(usingContentSetting?.[0]?.con2 ?? '')}
                            </div>
                            <div class="footer__content__items">
                                {parse(usingContentSetting?.[0]?.con3 ?? '')}
                                {parse(usingContentSetting?.[0]?.con4 ?? '')}
                            </div>
                            <div class="footer__content__items">
                                {parse(usingContentSetting?.[0]?.con5 ?? '')}
                                {parse(usingContentSetting?.[0]?.con6 ?? '')}
                            </div>
                        </div>
                    </div>
                </div>


                {/*Footer Top*/}
                <div class="footer__top pb-60">
                    <div class="row g-5">

                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 wow fadeInUp" data-wow-delay="0.9s" style={{visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                            <div class="widget__items">
                                <div class="footer-head">
                                    <h3 class="title">
                                        {usingLanguage['headlines']['general_info']}
                                    </h3>
                                </div>
                                <div class="content-area">
                                    <ul class="quick-link">
                                        
                                        
                                        <div className="social-share-link">
                                            {socials?.map((item, index) => {
                                                if (item.uri === '') {
                                                  return <></>;
                                                }

                                                return (
                                                  <a
                                                    key={index} // Add a key for React
                                                    target={"_blank"}
                                                    href={item.uri}
                                                    className={`social-link ${item.title}`} // Add class for styling
                                                  >
                                                    <span style={{ backgroundColor: item.color }}>
                                                      <i className={item.icon}></i>
                                                    </span>
                                                  </a>
                                                );
                                            })}
                                        </div>

                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 wow fadeInUp" data-wow-delay="0.7s">
                            <div class="widget__items">
                                <div class="footer-head">
                                    <h3 class="title">
                                        {usingLanguage['headlines']['matches']}
                                    </h3>
                                </div>
                                <div class="content-area">
                                    <ul class="quick-link">
                                        
                                        {headerMenu?.map(item => <li>
                                            <a className="pointer" onClick={(e) => {e.preventDefault(); item?.whenClicked?.()}}>
                                                <img src={rightArrow} alt="angle" /> {item?.title}
                                            </a>
                                        </li>)}

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                            <div class="widget__items">
                                <div class="footer-head">
                                    <h3 class="title">
                                        {usingLanguage['headlines']['live_account']}
                                    </h3>
                                </div>
                                <div class="content-area">
                                    <ul class="quick-link">

                                        {mobileMenu?.map(item => {

                                            if(!item?.show){ return <></>}
                                            if(item?.onlyOnMobile){ return <></>}
                                        
                                            return <li className="pointer">
                                                <a className="" onClick={(e) => {e.preventDefault(); item?.whenClicked?.(e)}}>
                                                    <img src={rightArrow} alt="angle" /> {item?.title}
                                                </a>
                                            </li>

                                        })}

                                    </ul>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                {/*Footer Top*/}

                {/*Footer Sponor Here*/}
                <div class="footer__sponsor" style={{backgroundColor: '#fff'}}>



                    <Slider arrows={false} {...matchResultSettings}>

                        {[...Array(11)]?.map((_, index) => {

                            let thisNumber = 5 + index

                            return <div style={{}}>
                                <div>

                                    <img style={{height: 40}} src={addUrlToPath(site_settings?.image_settings?.[0]?.['image' + thisNumber])} alt="brand image" />

                                </div>
                            </div>})}

                    </Slider>


                    
                </div>
                {/*Footer Sponor End*/}

                {/*Footer bottom*/}
                <div class="footer__bottom">
                    <p>
                        Copyright © {(new Date).getFullYear()} - All Right Reserved
                    </p>
                    <ul class="bottom__ling">

                        {site_settings?.faq?.length > 0 && <li>
                            <Link to="/faq">
                                {usingLanguage['menu1']['faq']}
                            </Link>
                        </li>}

                        {(site_settings?.site_settings?.term ?? '') != '' && <li>
                            <Link  to="/terms-and-condition">
                                {usingLanguage['menu1']['terms']}
                            </Link>
                        </li>}

                        {(site_settings?.site_settings?.privacy_policy ?? '') != '' && <li>
                            <Link to="/privacy-policy">
                                {usingLanguage['menu1']['privacy']}
                            </Link>
                        </li>}
                    </ul>
                </div>
                {/*Footer bottom*/}
            </div>
        </footer>

    </>
};

export default DashboardFooter;