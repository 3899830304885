import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {useNavigate} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import AuthHolder from "./AuthHolder";
import Countdown from 'react-countdown';
import { RiLoginBoxLine, RiHome7Line, RiArchiveLine, RiBarChart2Line, RiBriefcase4Fill, RiArchiveDrawerLine } from "react-icons/ri";
import {staticCountries} from '../../../utility/countries';
import { AiFillCloseCircle, AiOutlineUser, AiOutlineMail, AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineGlobal } from "react-icons/ai";


const UserVerification = () => {

    const userData = useSelector(userSelect);

    const [emailCodeInput, setEmailCodeInput] = useState('') 
    const [phoneCodeInput, setPhoneCodeInput] = useState('') 
    const [verifyingEmailOrPhone, setVerifyingEmailOrPhone] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const navigate                                       = useNavigate();

    const [loading, changeLoading]                      = useState(false);
    const [loadingText, setLoadingText]                 = useState("");


    const dispatch = useDispatch();

    const verifyEmail = (e) => {
        e.preventDefault();

        if(loading){ return; }

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")

        dispatch(authActions.onSignUp("account/verify_email", {emailCode:emailCodeInput}, response => {

            if(response.code == 1){
                if((userData?.emailVerification == 1) && (userData?.phoneVerification == 1)){
                    navigate('/', {replace: true});
                }else{
                    setVerifyingEmailOrPhone(1)
                }
            }

            notify(response.msg, response.code)
            // setFullPageLoading(false);
            changeLoading(false)
            setLoadingText("")
        
        }),
        );

    }


    const resendEmailVerification = (e) => {
        e.preventDefault();

        if(loading){ return; }

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")
        
        dispatch(authActions.onRefreshSystemData("account/resend_email_verification", 100, {}, response => {
            notify(response.msg, response.code)
            // setFullPageLoading(false);
            changeLoading(false)
            setLoadingText("")
        }),
        );
    }


    const resendPhoneVerification = (e) => {
        e.preventDefault();

        if(loading){ return; }

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")
        
        dispatch(authActions.onRefreshSystemData("account/resend_phone_verification", 100, {}, response => {
            notify(response.msg, response.code)
            setFullPageLoading(false);
            changeLoading(false)
        }),
        );
    }



    const verifyPhone = (e) => {
        e.preventDefault();

        if(loading){ return; }

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")

        dispatch(authActions.onSignUp("account/verify_phone", {smsCode:phoneCodeInput}, response => {

            if(response.code == 1){
                navigate('/personal-information', {replace: true});
            }

            notify(response.msg, response.code)
            // setFullPageLoading(false);
            changeLoading(false)
            setLoadingText("")
        
        }),
        );

    }



    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    useEffect(() => {
        if(userData?.emailVerification == 0){
            setVerifyingEmailOrPhone(0)
        }else if(userData?.phoneVerification == 0){
            setVerifyingEmailOrPhone(1)
        }
    }, [])


    const titleText = verifyingEmailOrPhone == 0?`Email verification`:`Phone verification`;
    const titleText2 = verifyingEmailOrPhone == 0?`We sent a token/code to ${userData?.email}. Please provide the token below.`:`We sent an OTP to (${userData?.countryPhoneCode})${userData?.phone}. Please provide the OTP below.`;


    return (
        <AuthHolder title={`${titleText}`} title2={titleText2}>
            
            
                    <form name="myform" className="signin_validate row g-3">
                        
                        {verifyingEmailOrPhone == 0 && <>
                    

                        <h2 className="text-center">Verify Email</h2>

                        <label className="single-input-wrap">
                            <label for="code-1" className="hide-label">Code*</label>
                            <AiOutlineLock className="auth-icon-color" size={20} />
                            <input onChange = {e => setEmailCodeInput(e.target.value) } required type="text" className="auth-input form-control" placeholder="Code"
                                name="email"/>
                        </label>

                    

                    <div className="text-center">
                        <button onClick={verifyEmail} type="submit" className="btn btn-primary btn-block">
                            Verify

                            <LoadingIcon color={"#fff"} active={loading} />

                        </button>




                         <div className="flexify" style={{marginTop: 15}}>
                            <div className="text-center unchanged-white-color-1">
                                
                                <Countdown
                                    zeroPadTime={2}
                                    key={new Date(userData?.lastEmailTime)}
                                    date={new Date(userData?.lastEmailTime)}
                                    renderer={({ hours, minutes, seconds, completed }) => {
                                      if (completed) {
                                        // Render a completed state
                                        // timeUp();
                                        return <><span className="pointer mt-40 pointer" onClick={resendEmailVerification}>Resend OTP</span><LoadingIcon color={"#fff"} active={loading} /></>
                                        // 
                                      } else {
                                        // Render a countdown
                                        return <span className="mt-40">Resend after {minutes}:{seconds}</span>;
                                      }
                                    }}
                                />


                            </div>



                            <div className="text-center">
                                <span onClick={doLogOutNowNow} className="unchanged-white-color-1 pointer">Sign off</span>
                            </div>
                        </div>




                    </div>



                </>
                }

                














                {verifyingEmailOrPhone == 1 && <>

                    <h2 className="text-center">Verify Phone</h2>

                    <label className="single-input-wrap">
                        <label for="code-2" className="hide-label">Code*</label>
                        <AiOutlineLock className="auth-icon-color" size={20} />
                        <input id="code-2" onChange = {e => setPhoneCodeInput(e.target.value) } required type="text" className="auth-input form-control" placeholder="Code"
                            name="email"/>
                    </label>

                    


                    <div className="text-center">
                        <button onClick={verifyPhone} type="submit" className="btn btn-primary btn-block">
                            Verify

                            <LoadingIcon color={"#fff"} active={loading} />

                        </button>


                        <div className="flexify" style={{marginTop: 15}}>
                            <div className="text-center unchanged-white-color-1">
                                
                                <Countdown
                                    zeroPadTime={2}
                                    key={new Date(userData?.lastSMSTime)}
                                    date={new Date(userData?.lastSMSTime)}
                                    renderer={({ hours, minutes, seconds, completed }) => {
                                      if (completed) {
                                        // Render a completed state
                                        // timeUp();
                                        return <><span onClick={resendPhoneVerification} className="mt-40 pointer">Resend Phone OTP</span><LoadingIcon color={"#fff"} active={loading} /></>
                                        // 
                                      } else {
                                        // Render a countdown
                                        return <span className="mt-40">Resend after {minutes}:{seconds}</span>;
                                      }
                                    }}
                                />

                            </div>


                            <div className="text-center">
                                <span onClick={doLogOutNowNow} className="unchanged-white-color-1 pointer">Sign off</span>
                            </div>

                        </div>
                    </div>




                </>
                }
                    </form>


                
                
        </AuthHolder>
    );
};

export default UserVerification;