import React, {useEffect} from 'react';
import { Route, Navigate, useLocation, useBlocker, Outlet } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../redux/selectors/index';
import {settingActions} from '../redux/actions';

// handle the private routes
function PrivateRoute({children}) {
  
  const location = useLocation();
  const dispatch = useDispatch();
  const users = useSelector(userSelect);
  let to = '/verify-user'

  const userLoginIsValid = ((users?.emailVerification ?? 0) == 1) && ((users?.phoneVerification ?? 0) == 1)


  useEffect(() => {

    dispatch(settingActions.onManageLocalSettings({lastPrivateUrl: location?.pathName}, response => {

      }

    ))

  }, [location])



  if (!userLoginIsValid) {
    return <Navigate to={to} replace />;
  }

  return children ? children : <Outlet />;  

}

export default PrivateRoute;