import React, {Component, useState} from "react";
import SiteWide from "../../../utility/customComponents/SiteWide";
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import WrapUpPolicies from './WrapUpPolicies'
import Faqo from "react-faq-component";

    

function FAQ(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "#000",
        rowTitleColor: "#000",
        rowContentColor: '#aeaeb5',
        arrowColor: "#000",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
    };

    const data = {    
        title: "",
        rows: site_settings?.faq?.map(function(item, index){ return {title: item.question, content: item.answer} })

    };


    return (
        <WrapUpPolicies pageTitle={"FAQ"}>


            {site_settings?.faq?.map(item => {

                return <div style={{textAlign: 'center', marginBottom: 20}}>
                    <div className="h2" style={{fontWeight: 900, color: '#88b6ff'}}>{item?.question}</div>
                    <p className="text-muted">{item?.answer}</p>
                </div>


            })}

            {/*<Faqo
                data={data}
                styles={styles}
                config={config}
            />*/}
                                

             
        </WrapUpPolicies>
    );

}

export default FAQ;