import React from 'react';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import SubMenu from './sub-menu';
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'

const ProfileWrapper = (props) => {
    return (
        
        <WrapUpFootballArea hideEventPlace={"true"} pageTitle={props?.pageTitle}>
            
            <SubMenu />

            <div className="tb-content" style={{paddingLeft: 6, paddingRight: 6}}>

                {props.children}

            </div>

        
        </WrapUpFootballArea>
    );
};

export default ProfileWrapper;