import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';





function* onGetFixtures(action) {
  try {

    const response = yield (new APIRequests).runGet(action.routeToCall);


    if ((response.code == 1) && (response?.data?.fixtures)) {

      yield put({ type: actionTypes.SAVE_ALL_FIXTURE_DISK, data: response.data });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* onLoadMatchResults(action) {
  try {

    const response = yield (new APIRequests).runGet(action.routeToCall);


    if ((response.code == 1) && (response?.data?.results)) {

      yield put({ type: actionTypes.SAVE_ALL_MATCH_RESULTS_DISK, data: response.data });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* onLoadLiveMatch(action) {
  try {

    const response = yield (new APIRequests).runGet(action.routeToCall);


    if ((response.code == 1) && (response?.data?.live)) {

      yield put({ type: actionTypes.SAVE_ALL_LIVE_MATCH_TO_DISK, data: response.data });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* onLoadCancelledMatch(action) {
  try {

    const response = yield (new APIRequests).runGet(action.routeToCall);


    if ((response.code == 1) && (response?.data?.cancelled)) {

      yield put({ type: actionTypes.SAVE_ALL_CANCELLED_MATCH_TO_DISK, data: response.data });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}





function* onSubmitBet(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);
    if (response.code == 1) {

      if(response?.data?.finance){
        yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      }
      if(response?.data?.user_games){
        yield put({ type: actionTypes.SAVE_USER_BETS_DISK, data: response.data.user_games });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onLoadRecent(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);
    if (response.code == 1) {

      if(response?.data?.recent_bets){
        yield put({ type: actionTypes.SAVE_HOME_RECENT_DISK, data: response.data.recent_bets });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* onLoadUserBets(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);
    if (response.code == 1) {

      if(response?.data?.user_games){
        yield put({ type: actionTypes.SAVE_USER_BETS_DISK, data: response.data.user_games });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}








function* onToggleBetSlip(action) {

  try {

    yield put({ type: actionTypes.SAVE_BET_SLIP_INFO_DISK, data: action?.data });
      
    return action.callback?.(true);

  } catch (error) {

    return action.callback?.(false);

  }

}



function* onAddFixtureToBetSlip(action) {

  try {

    yield put({ type: actionTypes.ADD_FIXTURE_TO_BET_SLIP_ON_DISK, data: action?.data });
      
    return action.callback?.(true);

  } catch (error) {

    return action.callback?.(false);

  }

}



function* onManageBetSlipAmount(action) {

  try {

    yield put({ type: actionTypes.MANAGE_AMOUNT_TO_BET_SLIP_DISK, data: action?.data });
      
    return action.callback?.(true);

  } catch (error) {

    return action.callback?.(false);

  }

}





function* onRemoveFixtureFromBetSlip(action) {

  try {

    yield put({ type: actionTypes.REMOVE_FIXTURE_FROM_BET_SLIP_ON_DISK, data: action?.data });
      
    return action.callback?.(true);

  } catch (error) {

    return action.callback?.(false);

  }

}

function* onClearFixtureFromBetSlip(action) {

  try {

    yield put({ type: actionTypes.CLEAR_ALL_FIXTURE_FROM_BET_SLIP_ON_DISK, data: action?.data });
      
    return action.callback?.(true);

  } catch (error) {

    return action.callback?.(false);

  }

}






function* watchOnLoadLiveMatch() {
  yield takeEvery(actionTypes.SAVE_ALL_LIVE_MATCH_TO_ACTION, onLoadLiveMatch);
}

function* watchOnLoadCancelledMatch() {
  yield takeEvery(actionTypes.SAVE_ALL_CANCELLED_MATCH_TO_ACTION, onLoadCancelledMatch);
}

function* watchOnToggleBetSlip() {
  yield takeEvery(actionTypes.TOGGLE_BET_SLIP_MODAL, onToggleBetSlip);
}

function* watchOnManageBetSlipAmount() {
  yield takeEvery(actionTypes.MANAGE_AMOUNT_TO_BET_SLIP_ACTION, onManageBetSlipAmount);
}



function* watchOnLoadMatchResults() {
  yield takeEvery(actionTypes.ALL_MATCH_RESULTS_ACTION, onLoadMatchResults);
}



function* watchOnLoadRecentBets() {
  yield takeEvery(actionTypes.LOAD_RECENT_BETS, onLoadRecent);
}

function* watchOnGetFixture() {
  yield takeEvery(actionTypes.GET_ALL_FIXTURE, onGetFixtures);
}

function* watchOnSubmitBet() {
  yield takeEvery(actionTypes.SUBMIT_BET_REQUEST, onSubmitBet);
}

function* watchOnLoadUserBets() {
  yield takeEvery(actionTypes.LOAD_USER_BETS, onLoadUserBets);
}

function* watchOnAddFixtureToBetSlip() {
  yield takeEvery(actionTypes.ADD_FIXTURE_TO_BET_SLIP_ON_ACTION, onAddFixtureToBetSlip);
}

function* watchOnRemoveFixtureFromBetSlip() {
  yield takeEvery(actionTypes.REMOVE_FIXTURE_FROM_BET_SLIP_ON_ACTION, onRemoveFixtureFromBetSlip);
}

function* watchOnClearFixtureFromBetSlip() {
  yield takeEvery(actionTypes.CLEAR_ALL_FIXTURE_FROM_BET_SLIP_ON_ACTION, onClearFixtureFromBetSlip);
}


export default function* footballSagas() {
  yield all([watchOnManageBetSlipAmount(), watchOnLoadLiveMatch(), watchOnLoadCancelledMatch(), watchOnLoadMatchResults(), watchOnAddFixtureToBetSlip(), watchOnRemoveFixtureFromBetSlip(), watchOnClearFixtureFromBetSlip(), watchOnToggleBetSlip(), watchOnGetFixture(), watchOnSubmitBet(), watchOnLoadRecentBets(), watchOnLoadUserBets()]);
}
