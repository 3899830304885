import React, {useState, useEffect} from 'react';
import ProfileWrapper from './ProfileWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import checkimg from '../../../images/routing.png'
import { Modal, Button } from 'react-bootstrap'
import PinAuth from "../../../utility/customComponents/PinAuth";


const PaymentMethod = () => {

    const dispatch = useDispatch();
    const userData = useSelector(userSelect);
    const [securityObj, setSecurityObj] = useState({})
    const [loading, setloading]   = useState();
    const [walletAddresses, setWalletAddresses]   = useState({});
    const [updatingPaymentDetail, setUpdatingPaymentDetail]   = useState(false);
    const [show, setShow] = useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');
    const [iban, setIban]       = useState(userData?.iban ?? '');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const doAddressChange = (e) => {

        if(updatingPaymentDetail){ return; }

        setloading(true);
        e.preventDefault();

        setUpdatingPaymentDetail(true)
        let updateObject = {...(securityObj ?? {}), ...walletAddresses}

        dispatch(authActions.onLogin(`account/account_change/1`, updateObject, response => {
            notify(response.msg, response.code)

            setUpdatingPaymentDetail(false)
            if(response.code == 1){

            }

        }),
        );
    }

    const doBankUpdate = (e) => {

        if(updatingPaymentDetail){ return; }

        e.preventDefault();
        setloading(true);
        setUpdatingPaymentDetail(true)
        let updateObject;

        updateObject = {iban, bank, accountName, accountNumber, ...(securityObj ?? {}),}

        dispatch(authActions.onLogin(`account/account_change/0`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            setUpdatingPaymentDetail(false)

            if(response.code == 1){

            }
        }),
        );
    }

    useEffect(() => {

        let cryptoWallets = {}

        userData?.bankdetails?.forEach((item, detail) => {

            cryptoWallets[item.name] = item.value

        })

        setWalletAddresses(cryptoWallets)

    }, [])

    const updateSpecifiCrypto = (cryptoName, value) => {

        let allCryptoAddresses = {...walletAddresses};
        allCryptoAddresses[cryptoName] = value
        setWalletAddresses(allCryptoAddresses)
    }



    return (<>
        <ProfileWrapper pageTitle={"Payment Methods"}>

            <div className="card-body padding-0">
                <div className="row">

                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card" style={{}}>


                            

                                    <div className="card-header">
                                        <h4 className="card-title">Payment methods </h4>
                                    </div>
                                    <div className="card-body">
                                       

                                        {userData?.bank !== null && 1>2 && <><div className="verify-content">

                                            <div className="d-flex align-items-center">
                                                <span className="mr-3 icon-circle bg-primary text-white">
                                                    <i className="icofont-bank-alt"></i>
                                                </span>
                                                <div className="primary-number">
                                                    <p className="mb-0">{userData?.bank}</p>
                                                    <small>{userData?.accountName}</small>
                                                    <br />
                                                    <span className="text-success">{userData?.accountNumber}</span>
                                                </div>
                                            </div>

                                        </div><hr className="dropdown-divider my-4" /></>}

                                        

                                        {userData?.bankdetails?.map((item, detail) => {

                                            if(item?.value === ''){ return <></>}
                                            if(item?.value == null){ return <></>}

                                            let image = finance?.filter((filitem, index) => filitem.type == item.type)

                                            return <>
                                                <div className="verify-content">
                                                    <div className="d-flex flexify align-items-center">

                                                        <img style={{width: 30, height: 30}} src={image?.[0]?.image} alt="img" />

                                                        <div className="primary-number" style={{marginLeft: 10}}>
                                                            <p className="mb-0">{item?.name}</p>
                                                            <small>{item?.value}</small>
                                                            <br />
                                                            <span className="text-success">{item?.abbr}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="dropdown-divider my-4" />

                                            </>})}

                                        <div className="mt-5">
                                            {/*<button type="button" className="btn btn-primary mr-3" onClick={handleShow}>Manage Bank</button>*/}
                                            <button type="button" className="btn btn-success" onClick={handleShow1}>Update Wallet</button>
                                        </div>

                                    </div>
                                


                        </div>
                    </div>
                </div>
            </div>

        </ProfileWrapper>

        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header>
                <Modal.Title>Update wallet addresses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form action="verify-step-6.html" class="identity-upload">
                  <div class="row g-3">

                    {userData?.bankdetails?.map((item, detail) => <div class="col-xl-12">
                        <label class="form-label">
                            {item?.abbr} wallet
                        </label>
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder={item?.name} 
                            onChange = {e => updateSpecifiCrypto(item?.name, e.target.value) } 
                            value={walletAddresses[item?.name]} 
                        />
                    </div>)}

                    <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />

                  </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>Close</Button>
                <Button variant="primary" onClick={doAddressChange}>
                    Confirm
                    <LoadingIcon color={"#fff"} active={updatingPaymentDetail} />
                </Button>
            </Modal.Footer>

            
        </Modal>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Add Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form class="identity-upload">
                  <div class="row g-3">
                    
                    <div class="col-xl-12">
                        <label class="form-label">
                            Bank Name
                        </label>
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder={'Bank Name'} 
                            onChange = {e => setBank(e.target.value)} 
                            value={bank} 
                        />
                    </div>

                    <div class="col-xl-12">
                        <label class="form-label">
                            Account Name
                        </label>
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder={'Account Name'} 
                            onChange = {e => setAccountName(e.target.value)} 
                            value={accountName} 
                        />
                    </div>


                    <div class="col-xl-12">
                        <label class="form-label">
                            Account Number
                        </label>
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder={'Account Number'} 
                            onChange = {e => setAccountNumber(e.target.value)} 
                            value={accountNumber} 
                        />
                    </div>


                    <div class="col-xl-12">
                        <label class="form-label">
                            IBAN or SWIFT code
                        </label>
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder={'IBAN or SWIFT code'} 
                            onChange = {e => setIban(e.target.value)} 
                            value={iban} 
                        />
                    </div>


                    
                    <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />

                  </div>
                </form>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="danger" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={doBankUpdate}>
                    Confirm
                    <LoadingIcon color={"#fff"} active={updatingPaymentDetail} />
                </Button>

            </Modal.Footer>
        </Modal>

        </>
    );
};

export default PaymentMethod;