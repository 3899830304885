import React, {useState, useEffect, useCallback} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpFootballArea from './WrapUpFootballArea'


import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, footballSelect } from '../../redux/selectors/index';
import SiteWide from "../../utility/customComponents/SiteWide";
import TimeFormatter from "../../utility/customComponents/TimeFormatter";

import parse from 'html-react-parser';
import Helper1, {displayTime, selectRandomObjects, moneyStatus, byNextDate, notify, money, subStr, addUrlToPath} from '../../utility/Helper1';

import copy from 'copy-to-clipboard';
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../utility/customComponents/PinAuth";
import {authActions, footballActions} from '../../redux/actions';
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../utility/customComponents/Ticker1";
import Ticker2 from "../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';
import { MdSportsSoccer, MdOutlineSearch } from "react-icons/md";
import DashboardFooter1 from '../layout/dashboard-footer-1'
import Marquee from "react-easy-marquee";
import Modal from 'react-modal';
import { BiWindowClose } from "react-icons/bi";
import { BsArrowUpRightSquare, BsFastForward, BsRewind } from "react-icons/bs";
import NoData from "../../utility/customComponents/NoData";
import { ProgressBar } from  'react-loader-spinner'
import { AiFillCloseCircle } from "react-icons/ai";
import MatchesLists from './MatchesLists';
import useLanguage from '../../utility/config/hooks/useLanguage';




const ShowPlayableMatches = (props) => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const footballData  = useSelector(footballSelect);

    const EACHHIGHLIGHT_LIMIT = 10

    const [matchData, setMatchData]     = useState(props?.data)
    const [currentMatchDataLength, setCurrentMatchDataLength] = useState(EACHHIGHLIGHT_LIMIT)
    const [searchInputToUse, setSearchInputToUse] = useState('')
    const [itemToDelete, setItemToDelete] = useState(null)

    const usingLanguage = useLanguage()
    

    return ( <>

        <div className="main__body__wrap left__right__space" style={{paddingLeft: 6, paddingRight: 6, marginTop: 15}}>

                <div className="live__heightlight mb__30 dark_bg_5" style={{marginBottom: 6}}>
                    <div className="section__title" style={{position: 'relative'}}>

                        <h4>
                            {usingLanguage?.['menu2']?.[props?.heroTitle?.toLowerCase()] ?? usingLanguage?.['menu2']?.[props?.titleLangKey?.toLowerCase()] ?? props?.heroTitle}
                        </h4>

                        {props?.mainBgLogo && <img style={{position: 'absolute', right: 0, top: 0, opacity: 0.3, zIndex: 0}} src={props?.mainBgLogo} width={150} />}

                    </div>


                    <div className="height__table">
                        <div className="tab-content" id="nav-tabContentheight">

                            <div className="tab-pane fade text-white show active" id="height1" role="tabpanel" aria-labelledby="nav-home-tabpre" tabindex="0">
                                <div className="main__table" style={{width: '100%'}}>


                                    <MatchesLists data={props?.dataToUse} />


                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="text-center" style={{paddingTop: 30, paddingBottom: 30}}>
                        <LoadingIcon width={50} active={props?.showLoading} />
                    </div>


                </div>






            </div>
        </>
    );
};

export default ShowPlayableMatches;