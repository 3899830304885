import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../redux/selectors/index';

    

// handle the public routes
function PublicRoute({children}) {
  const users = useSelector(userSelect);

  if (users?.emailVerification) {
    return <Navigate to={'/'} replace />;
  }

  return children ? children : <Outlet />;  


}
 
export default PublicRoute;
