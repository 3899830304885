import React, { useEffect, useState } from 'react';

const TimeFormatter = ({ inputTime, className }) => {

  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    const now = new Date();
    const inputDate = new Date(inputTime);
    const timeDifference = inputDate.getTime() - now.getTime();
    const secondsInAnHour = 3600;
    const secondsInADay = 86400;

    if (timeDifference < secondsInAnHour) {
      const minutes = Math.floor(timeDifference / 60);
      const seconds = timeDifference % 60;
      setFormattedTime(`${minutes}M ${seconds}S`);
    } else if (timeDifference < secondsInADay) {
      const hours = Math.floor(timeDifference / secondsInAnHour);
      const minutes = Math.floor((timeDifference % secondsInAnHour) / 60);
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      setFormattedTime(`${formattedHours}:${formattedMinutes}`);
    } else {
      const tomorrow = new Date(now);
      tomorrow.setDate(now.getDate() + 1);
      const isTomorrow = inputDate.getDate() === tomorrow.getDate();

      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      if (isTomorrow) {
        const hours = inputDate.getHours();
        const minutes = inputDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        setFormattedTime(`Tomorrow / ${formattedHours}:${formattedMinutes}${ampm}`);
      } else {
        const hours = inputDate.getHours();
        const minutes = inputDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        setFormattedTime(`${formattedDay}/${formattedMonth} ${formattedHours}:${formattedMinutes}${ampm}`);
      }
    }
  }, [inputTime]);

  return (
    <span className={className}>
      {formattedTime}
    </span>
  );
};

export default TimeFormatter;
