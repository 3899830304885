import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import {addUrlToPath} from '../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';

    

const Sidebar = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const userData = useSelector(userSelect);

    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    return (
        <>
            <div className="sidebar">
                {/*<div className="brand-logo">
                    <Link to={"/"}>
                        <img src={addUrlToPath(site_settings?.site_settings?.logo)} alt="" />
                    </Link>
                </div>*/}
                <div className="menu">
                    <ul>
                        

                        {userData?.demoAccount == 0 && <li>
                            <Link className="each-dash-menu" to={"/fund"} title="Trade">
                                <span><i className="icofont-money"></i></span>
                                <span className="menu-text text-white block">Fund</span>
                            </Link>
                        </li>}

                        {userData?.demoAccount == 0 && <li>
                            <Link className="each-dash-menu" to={"/wallet"} title="Wallet">
                                <span><i className="icofont-wallet"></i></span>
                                <span className="menu-text text-white block">Wallet</span>
                            </Link>
                        </li>}

                        <li>
                            <Link className="each-dash-menu" to={"/trade"} title="Price">
                                <span><i className="icofont-price"></i></span>
                                <span className="menu-text text-white block">Trade</span>
                            </Link>
                        </li>


                        {userData?.demoAccount == 0 && site_settings.operational_settings?.use_loan == 1 && <li>
                            <Link className="each-dash-menu" to={"/loan"} title="Price">
                                <span><i className="icofont-chart-growth"></i></span>
                                <span className="menu-text text-white block">Loan</span>
                            </Link>
                        </li>}


                        <li style={{}}>
                            <Link className="each-dash-menu" to={"/referral"} title="Home">
                                <span><i className="icofont-users"></i></span>
                                <span className="menu-text text-white block">Referral</span>
                            </Link>
                        </li>

                        <li>
                            <Link className="each-dash-menu" to={"/settings-password"} title="Settings">
                                <span><i className="icofont-ui-settings"></i></span>
                                <span className="menu-text text-white block">Setting</span>
                            </Link>
                        </li>

                        {/*<li className="logout">
                            <a className="pointer"
                                onClick={doLogOutNowNow}
                                title="Signout">
                                <span><i className="icofont-power"></i></span>
                            </a>
                        </li>*/}

                    </ul>

                    {/*<p className="copyright">
                        <Link to={"/#"}>{site_settings?.site_settings?.sitename}</Link>
                    </p>*/}

                </div>
            </div>
        </>
    );
};

export default Sidebar;