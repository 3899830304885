import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { allRemoteSettingsSelect } from '../../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import SubMenu from '../../layout/SubMenu';


const BannerHeadMenu = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const promotions = site_settings?.promotional_settings

    const menu = [
            {
                title: 'Banner',
                url: '/banner',
                show: true,
            },
            {
                title: 'Promotional Tools',
                url: '/promotion',
                show: (promotions?.length ?? 0) > 0
            }
        ]


    return <SubMenu data={menu} />

};

export default BannerHeadMenu;