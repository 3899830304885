import React, {useState, useEffect} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from 'react-redux';
import { allRemoteSettingsSelect } from '../../redux/selectors/index';


function GoogleReCAPTCHA(props) {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    return (
        (Number(site_settings?.site_settings?.use_google_reCaptcha) == 1)?<ReCAPTCHA
            sitekey={site_settings?.site_settings?.google_reCaptcha_site_key}
            onChange={props?.onChange}
        />:<></>
    );

}

export default GoogleReCAPTCHA;