import * as actionTypes from "./actionTypes";

export const onLoadAll = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.GET_ALL_FIXTURE,
    callback,
  };
};



export const onLoadMatchResults = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.ALL_MATCH_RESULTS_ACTION,
    callback,
  };
};


export const onLoadLiveMatches = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.SAVE_ALL_LIVE_MATCH_TO_ACTION,
    callback,
  };
};

export const onLoadCancelledMatches = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.SAVE_ALL_CANCELLED_MATCH_TO_ACTION,
    callback,
  };
};


export const onSubmitBet = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.SUBMIT_BET_REQUEST,
    callback,
  };
};

export const onGetRecents = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.LOAD_RECENT_BETS,
    callback,
  };
};


export const onGetUserGames = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.LOAD_USER_BETS,
    callback,
  };
};


export const onToggleBetSlip = (data, callback = () => {}) => {
  return {
    data,
    type: actionTypes.TOGGLE_BET_SLIP_MODAL,
    callback,
  };
};






export const onAddFixtureToBetSlip = (data, callback = () => {}) => {
  return {
    data,
    type: actionTypes.ADD_FIXTURE_TO_BET_SLIP_ON_ACTION,
    callback,
  };
};

export const onManageAmountOnBetSlip = (data, callback = () => {}) => {
  return {
    data,
    type: actionTypes.MANAGE_AMOUNT_TO_BET_SLIP_ACTION,
    callback,
  };
};



export const onRemoveFixtureFromBetSlip = (data, callback = () => {}) => {
  return {
    data,
    type: actionTypes.REMOVE_FIXTURE_FROM_BET_SLIP_ON_ACTION,
    callback,
  };
};

export const onClearFixtureFromBetSlip = (callback = () => {}) => {
  return {
    type: actionTypes.CLEAR_ALL_FIXTURE_FROM_BET_SLIP_ON_ACTION,
    callback,
  };
};


