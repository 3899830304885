import React from 'react';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import WrapUpFootballArea from '../../layout/WrapUpFootballArea'

import SubMenu from '../../layout/SubMenu';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';

const ProfileWrapper = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const menu = [
            {
                title: 'Profile',
                url: '/settings-profile',
                show: true
            },
            {
                title: 'Payment Methods',
                url: '/settings-payment-method',
                show: true

            },
            {
                title: 'KYC',
                url: '/settings-kyc',
                show: Number(site_settings?.kyc_settings?.showKYCMenuOnUserEnd) == 1

            },
            {
                title: 'Salary Programme',
                url: '/settings-salary',
                show: true

            }
        ]

    return (
        

        <WrapUpFootballArea hideEventPlace={"true"} pageTitle={props?.pageTitle}>

            <SubMenu data={menu} />

            <div className="tb-content" style={{paddingLeft: 6, paddingRight: 6}}>

                {props.children}

            </div>
            

        </WrapUpFootballArea>


    );
};

export default ProfileWrapper;