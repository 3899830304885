import {Link, useHistory} from 'react-router-dom';
import { store } from "../redux/store";
import {authActions} from '../redux/actions';
import {guessAdminRoute} from './Helper1';


const getToken = () => store.getState().auth?.user?.token;

export const homepage = process.env.REACT_APP_HOMEPAGE;
export const contact_us = process.env.REACT_APP_CONTACT_PAGE;
export const WHOOWNS = 'oscar'
export const OSCAR = 'oscar'


export default class APIRequests{










  fetchUrl = () => {

    return guessAdminRoute() + '/web/v1/'

  }









  baseUrl = this.fetchUrl()

  
  
  runGet = async (pointEnd) => {

    const accessToken = getToken();

    let data      =   await fetch(this.baseUrl + pointEnd, {
                                headers: {
                                  'Authorization': accessToken,

                                }
                            });

    let response  =   await data.json();
    this.requestActionizer(response);
    return response;

  }
 
  runPost = async (pointEnd, postObject, contentType = 0) => {

    const accessToken = getToken();

    try{

      let ct = (contentType==0)?'application/json':contentType;
      
      const formData = new FormData();
      
      for ( let key in postObject) {
        formData.append(key, postObject[key]);
      }

      let data = await fetch(this.baseUrl + pointEnd, {
          method: 'POST',
          headers: {
              
            'Authorization' : accessToken,

          },
          body: formData,
          
      });



      let response = await data.json();
      this.requestActionizer(response);
      return response;
    
    }catch(err){
      // console.error(err);
      return {code: 0, msg: 'Network request failed. Please check internet connection.'};
    }

  }


  requestActionizer = (response) => {

      if(response.code === -1){
        store.dispatch(authActions.onLogout())
      }

  }


}