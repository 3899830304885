import React, {Component, useState} from "react";
import SiteWide from "../../../utility/customComponents/SiteWide";
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import WrapUpPolicies from './WrapUpPolicies'
import Faqo from "react-faq-component";

    

function Terms(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "#fff",
        rowTitleColor: "#fff",
        rowContentColor: '#fff',
        arrowColor: "#fff",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
    };

    const data = {    
        title: "",
        rows: site_settings?.faq?.map(function(item, index){ return {title: item.question, content: item.answer} })

    };


    return (
        <WrapUpPolicies pageTitle={'Privacy Policy'}>


            <div>
                {parse(site_settings?.site_settings?.privacy_policy ?? '')}
            </div>




             
        </WrapUpPolicies>
    );

}

export default Terms;