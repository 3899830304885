import React, {useState, useEffect} from 'react';


import DonutChart from '../../charts/donut'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import { AiOutlineRight, AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiFillInfoCircle, AiOutlineCloudUpload } from "react-icons/ai";
import NoData from "../../../utility/customComponents/NoData";


const Balance = () => {

    const finance = useSelector(homeScreenStatSelect);
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        { title: 'Main', key: 'balance' },
        { title: 'Comm.', key: 'commission' },
        { title: 'Rebate', key: 'rebateCommission' },
    ];






    let totalBalance = 0
    let totalCommission = 0
    let allLabels = []
    let allSeries = []

    finance?.forEach((item, index) => {
        totalBalance+=Number(userData?.demoAccount == 1?item.demoBalance:item.balance)
        totalCommission+=Number(item.commission)
        allLabels = [...allLabels, item.name]
        allSeries = [...allSeries, item.balance]
    })


    const manageCurrentWallet = () => {
        let newActive = currentSlide + 1
        if(newActive >= slides?.length){
            newActive = 0
        }

        setCurrentSlide(newActive)

    }

    return (<div style={{marginBottom: 6, padding: 12}} className="car ark_bg_1">
                    
                    {finance?.map((item, index) => <>
                        <div className="flexify" style={{alignItems: 'center',}}>
                            <div className="">
                                <h4 className="crd-body dark_text_4">Account Balance</h4>
                                <h6 style={{fontSize: 11, marginTop: 6}} className="cad-body white-color-1">{item?.name}</h6>
                            </div>

                            <div style={{paddingRight: 15}} className="ion-title flexify">

                                <div className="flexify">

                                    <img style={{width: 30, height: 30, paddingRight: 5}} src={item?.image} alt="img" />

                                    <div className="slider-container">
                                        <div className="slides">
                                            {slides.map((slide, index) => (<div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                                                <h5 className="white-color-1">{money(item[slide.key], 1)}</h5>
                                                <div className="white-color-1" style={{fontSize: 10}}>{slide.title}</div>
                                            </div>))}
                                        </div>
                                    </div>

                                </div>

                                <button onClick={manageCurrentWallet} style={{marginLeft: 5, backgroundColor: '#044246', fontSize: 15, color: '#09ff8d', border: 'none', width: 30, height: 30, borderRadius: 40, display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={`nav-link active`}>
                                    >
                                </button>

                            </div>

                            

                        </div>
                    </>)}

                </div>);
};

export default Balance;