import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import SubMenu from '../../layout/SubMenu';

const SettingsSubMenu = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);

    const menu = [
            {
                title: 'Change Password',
                url: '/settings-password',
                show: true
            },
            {
                title: 'Update Pin',
                url: '/settings-pin',
                show: site_settings?.operational_settings?.use_pin == 1 && ((userData?.enforce_pin_usage ?? 1) == 1)
            },
            {
                title: 'Google Auth',
                url: '/settings-google-auth',
                show: site_settings?.operational_settings?.use_google_auth == 1 && ((userData?.enforce_google_auth_usage ?? 1) == 1)
            }
        ]
    return (
        <SubMenu data={menu} />
    );
};

export default SettingsSubMenu;