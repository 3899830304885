export default {
  menu1: {
    deposit:        'जमा',
    wallet:         'वॉलेट',
    invitation:     'निमंत्रण',
    logout:         'लॉग आउट',
    profile:        'प्रोफ़ाइल',
    settings:       'सेटिंग्स',
    sign_in:        'साइन इन करें',
    sign_up:        'साइन अप करें',
    referral:       'रेफरल',
    earn_salary:    'वेतन कमाएं',
    top_up:         'टॉप अप',
    faq:            'अक्सर पूछे जाने वाले प्रश्न',
    terms:          'नियम एवं शर्तें',
    privacy:        'गोपनीयता नीति',
  },
  menu2: {
    home:           'होम',
    search:         'खोज',
    today:          'आज',
    tomorrow:       'कल',
    my_league:      'मेरी लीग',
    bonus_odd:      'बोनस ऑड',
    live:           'लाइव',
    cancelled:      'रद्द',
    results:        'परिणाम',
  },
  headlines:{
    match_highlights:   'मैच हाइलाइट्स',
    general_info:       'सामान्य जानकारी',
    trending_results:   'ट्रेंडिंग परिणाम',
    matches:            'मैच',
    live_account:       'लाइव अकाउंट',
    league:             'लीग',
    nations:            'राष्ट्र',
    betslip:            'बेटस्लिप',
    slip:               'स्लिप',
    my_bets:            'मेरे बेट्स',
    fund:               'फंड',
  },
  page: {
    sign_in:{
      page_title:               'अकाउंट में साइन इन करें',
      username:                 'उपयोगकर्ता नाम',
      password:                 'पासवर्ड',
      remember_me:              'मुझे याद रखें',
      forgot_password:              'पासवर्ड भूल गए',
      dont_have_account:              'कोई अकाउंट नहीं है',
    },
    sign_up:{
      page_title:               'एक अकाउंट बनाएं',
      username:                 'उपयोगकर्ता नाम',
      password:                 'पासवर्ड',
      email:              'ईमेल',
      country:              'देश',
      select_country:              'देश चुनें',
      phone:              'फ़ोन',
      account_security_pin:              'अकाउंट सुरक्षा पिन',
      agree_to_the_terms_and_conditions: 'मैं नियमों और शर्तों से सहमत हूं',
    },
    index:{
      page_title:              '',
      match_detail:       'मैच विवरण',
      trending_results:    'ट्रेंडिंग परिणाम',
    },
    search:{
      title:              'खोज परिणाम',
      match_detail:       'मैच विवरण',
      enter_team_name:    'टीम का नाम, बेट आईडी या लीग जानकारी दर्ज करें',
    },
  },
  random_text: {
    betslip:{
      empty_slip_note: 'आपका बेटस्लिप खाली है।<br />कृपया एक बेट लगाने के लिए एक या अधिक चयन करें।',
      multiple_bet_note: 'मल्टीपल बेट्स अलग-अलग लगाए जाते हैं और जीत/हार व्यक्तिगत रूप से संसाधित की जाती है',
      place_bet: 'बेट लगाएं',
      total_odds: 'कुल ऑड्स',
      total_bet_amount: 'कुल बेट राशि',
      possible_winnings: 'संभावित जीत',
    },
    empty_data: {
      no_data: 'कोई डेटा उपलब्ध नहीं है।',
    }
  }
};
