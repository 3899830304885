import React from 'react';
import { FallingLines } from  'react-loader-spinner'
import './style.css'




function Preloader(props) {

    return <div className="loader-overlay">
            <FallingLines
              color="#29b6f6"
              width="100"
              visible={true}
              ariaLabel='falling-lines-loading'
            />
        </div>

}

export default Preloader;

